import { DataBaseComponent, DataBaseComponentState } from "../../DataBaseComponent";
import { repository } from "../../../clientInstance";
import { ProjectResource } from "client/resources";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import * as React from "react";
import { ExistsOperator, TolerationDetails } from "./kubernetesDeployContainersAction";
import { VariableLookupText } from "../../form/VariableLookupText";
import Note from "../../form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink";

interface TolerationState extends DataBaseComponentState {
    toleration: TolerationDetails;
    project: ProjectResource | null;
}

interface TolerationProps {
    toleration: TolerationDetails;
    localNames: string[] | undefined;
    projectId: string | undefined;
    onAdd(toleration: TolerationDetails): boolean;
}

class TolerationDialog extends DataBaseComponent<TolerationProps, TolerationState> {
    constructor(props: TolerationProps) {
        super(props);
        this.state = {
            toleration: {
                Key: "",
                Operator: "",
                Value: "",
                Effect: "",
            },
            project: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;
            const toleration = { ...this.props.toleration };
            this.setState({
                toleration,
                project,
            });
        });
    }

    save = () => {
        return this.props.onAdd(this.state.toleration);
    };

    render() {
        return (
            <OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Define Toleration"}>
                {this.state.toleration && (
                    <div>
                        <Note>
                            See <ExternalLink href="KubernetesTolerations">Kubernetes taints and tolerations documentation</ExternalLink> for details of how to configure tolerations.
                        </Note>
                        <div>
                            <VariableLookupText label="Key" localNames={this.props.localNames} error={this.getFieldError("TolerationKey")} value={this.state.toleration.Key} onChange={(Key) => this.setTolerationState({ Key })} />
                            <Note>The key of the taint to tolerate</Note>
                        </div>
                        <div>
                            <VariableLookupText
                                label="Operator"
                                value={this.state.toleration.Operator}
                                onChange={(operator: string) => {
                                    this.onOperatorChange(operator);
                                }}
                                error={this.getFieldError("TolerationOperator")}
                                localNames={this.props.localNames}
                            />
                            <Note>
                                Operators are <code>Exists</code> or <code>Equal</code>
                            </Note>
                        </div>
                        <div>
                            <VariableLookupText label="Value" localNames={this.props.localNames} error={this.getFieldError("TolerationValue")} value={this.state.toleration.Value} onChange={(Value) => this.setTolerationState({ Value })} />
                            <Note>
                                Value should only be set if operator is <code>Equal</code>
                            </Note>
                        </div>
                        <div>
                            <VariableLookupText
                                label="Effect"
                                value={this.state.toleration.Effect}
                                onChange={(Effect: string) => this.setTolerationState({ Effect })}
                                error={this.getFieldError("TolerationEffect")}
                                localNames={this.props.localNames}
                            />
                            <Note>
                                Effects are
                                <ul>
                                    <li>
                                        <code>NoSchedule</code>
                                    </li>
                                    <li>
                                        <code>PreferNoSchedule</code>
                                    </li>
                                    <li>
                                        <code>NoExecute</code>
                                    </li>
                                </ul>
                            </Note>
                        </div>
                    </div>
                )}
            </OkDialogLayout>
        );
    }

    private onOperatorChange(operator: string) {
        const toleration = {
            ...this.state.toleration,
        };
        toleration.Operator = operator;
        if (operator === ExistsOperator) {
            toleration.Value = "";
        }
        this.setState({ toleration });
    }

    private setTolerationState<K extends keyof TolerationDetails>(state: Pick<TolerationDetails, K>, callback?: () => void) {
        this.setChildState1("toleration", state, callback);
    }
}

export default TolerationDialog;
