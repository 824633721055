import * as React from "react";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import { ActionButtonType } from "components/Button/ActionButton";
import InternalLink from "components/Navigation/InternalLink";
import { withTheme } from "components/Theme";

interface AddTriggerButtonProps {
    className?: string;
    internalPath: string;
}

export const AddTriggerButton: React.FC<AddTriggerButtonProps> = ({ className, internalPath }) => {
    const [open, setOpen] = React.useState(false);

    return withTheme((theme) => (
        <DropdownButton open={open} onClick={() => setOpen(true)} onClose={() => setOpen(false)} type={ActionButtonType.Primary} className={className} label="Add Trigger" caretStyle={{ color: theme.primaryButtonText }}>
            {(renderMenuProps) => (
                <DropdownButtonMenu {...renderMenuProps.getMenuProps()}>
                    <InternalLink className={renderMenuProps.getLinkClass()} key="add-new-trigger" to={`${internalPath}/create`}>
                        <DropdownButtonMenuItem {...renderMenuProps.getMenuItemProps()}>Deployment target trigger</DropdownButtonMenuItem>
                    </InternalLink>
                    <InternalLink className={renderMenuProps.getLinkClass()} key="add-new-scheduled-trigger" to={`${internalPath}/scheduled/create`}>
                        <DropdownButtonMenuItem {...renderMenuProps.getMenuItemProps()}>Scheduled trigger</DropdownButtonMenuItem>
                    </InternalLink>
                </DropdownButtonMenu>
            )}
        </DropdownButton>
    ));
};
