import * as React from "react";
import { EnvironmentResource } from "client/resources";
import { ChipIcon, EnvironmentChip, ContextualMissingChip } from "components/Chips";
import Callout from "components/Callout";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import { RadioButtonGroup, Note, Summary, RadioButton } from "components/form";
import { CalloutType } from "components/Callout";
import Permission from "client/resources/permission";
import { EnvironmentOption } from "../types";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import Lookup from "components/Lookup";
import { KeyedItemAccessConsumer } from "components/KeyAccessProvider/KeyedItemAccessProvider";

class EnvironmentOptionRadioButtonGroup extends RadioButtonGroup<EnvironmentOption> {}

interface EnvironmentProps {
    environmentOption: EnvironmentOption;
    hasHiddenEnvironments: boolean;
    environments: EnvironmentResource[];
    inclusiveEnvironments: string[];
    exclusiveEnvironments: string[];
    onEnvironmentOptionChanged(val: EnvironmentOption): void;
    onInclusiveEnvironmentsChanged(environments: string[]): void;
    onExclusiveEnvironmentsChanged(environments: string[]): void;
    onInclusiveEnvironmentRemoved?: (environment: string) => void;
    onExclusiveEnvironmentRemoved?: (environment: string) => void;
}

type Props = EnvironmentProps & WithProjectContextInjectedProps;

export class Environments extends React.Component<Props> {
    get isVersionControlled(): boolean {
        return this.props.projectContext.state.model.IsVersionControlled;
    }

    shouldComponentUpdate(nextProps: Props) {
        return nextProps.environmentOption !== this.props.environmentOption || nextProps.inclusiveEnvironments !== this.props.inclusiveEnvironments || nextProps.exclusiveEnvironments !== this.props.exclusiveEnvironments;
    }

    render() {
        return (
            <ExpandableFormSection title="Environments" help="Choose which environments this step applies to." summary={this.environmentSummary()} errorKey="environments">
                <EnvironmentOptionRadioButtonGroup value={this.props.environmentOption} onChange={this.props.onEnvironmentOptionChanged}>
                    <RadioButton value={EnvironmentOption.All} label="Run for any environment" disabled={!this.isVersionControlled && this.props.hasHiddenEnvironments} isDefault={true} />

                    <RadioButton value={EnvironmentOption.Include} label="Run only for specific environments" disabled={!this.isVersionControlled && this.props.hasHiddenEnvironments} />
                    {this.props.environmentOption === EnvironmentOption.Include && (
                        <div>
                            <EnvironmentMultiSelect items={this.props.environments} onChange={this.props.onInclusiveEnvironmentsChanged} value={this.props.inclusiveEnvironments} onRemove={this.props.onInclusiveEnvironmentRemoved} />

                            <Note>Choose the specific environments under which you want this step to run.</Note>
                            {!this.isVersionControlled && this.props.hasHiddenEnvironments && (
                                <Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                                    The {Permission.EnvironmentView} permission is preventing the environment condition type from being changed because you do not have access to an environment that is specifically marked for inclusion.
                                </Callout>
                            )}
                        </div>
                    )}

                    <RadioButton value={EnvironmentOption.Exclude} label="Skip specific environments" disabled={!this.isVersionControlled && this.props.hasHiddenEnvironments} />
                    {this.props.environmentOption === EnvironmentOption.Exclude && (
                        <div>
                            <EnvironmentMultiSelect items={this.props.environments} onChange={this.props.onExclusiveEnvironmentsChanged} value={this.props.exclusiveEnvironments} onRemove={this.props.onExclusiveEnvironmentRemoved} />

                            <Note>Choose the environments for which you want this step to be skipped.</Note>
                            {!this.isVersionControlled && this.props.hasHiddenEnvironments && (
                                <Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                                    The {Permission.EnvironmentView} permission is preventing the environment condition type to be changed because you do not have access to an environment that is specifically marked for inclusion.
                                </Callout>
                            )}
                        </div>
                    )}
                </EnvironmentOptionRadioButtonGroup>
            </ExpandableFormSection>
        );
    }

    private environmentSummary() {
        if (this.props.environmentOption === EnvironmentOption.Include) {
            return Summary.summary(<span>This step will only run in {this.getChipsForEnvironments(this.props.inclusiveEnvironments)}</span>);
        }
        if (this.props.environmentOption === EnvironmentOption.Exclude) {
            return Summary.summary(
                <span>
                    This step will be run in all applicable Lifecycle environments <em>except</em> {this.getChipsForEnvironments(this.props.exclusiveEnvironments)}
                </span>
            );
        }
        return Summary.default("This step will run for all applicable Lifecycle environments");
    }

    private getChipsForEnvironments(environmentKeys: string[]) {
        if (environmentKeys.length === 0) {
            return <em>please select environments</em>;
        }

        return (
            <KeyedItemAccessConsumer>
                {(keyAccess) =>
                    environmentKeys.map((key) => (
                        <Lookup
                            lookupCollection={this.props.environments}
                            lookupId={key}
                            getIdFromElement={(x) => x[keyAccess]}
                            render={(item) => <EnvironmentChip environmentName={item.Name} />}
                            renderFallback={<ContextualMissingChip key={key} lookupKey={key} type={ChipIcon.Environment} />}
                        />
                    ))
                }
            </KeyedItemAccessConsumer>
        );
    }
}

const EnhancedEnvironments = withProjectContext(Environments);

export default EnhancedEnvironments;
