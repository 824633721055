/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import FormFieldProps from "components/form/FormFieldProps";
import { CertificateResource } from "client/resources/certificateResource";
import CertificateSelector from "./CertificateSelector";
import Select, { Item } from "components/form/Select/Select";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import moment from "moment";
import OpenDialogIconButton from "components/Dialog/OpenDialogIconButton";
import { Icon, default as IconButton } from "components/IconButton/IconButton";
import InputWithActions from "components/InputWithActions/InputWithActions";
import BusyRefreshContainer from "components/BusyRefreshContainer";
import routeLinks from "../../../routeLinks";
import { OctopusTheme, withTheme } from "components/Theme";
const styles = require("./style.less");
import { resolveStringPathWithSpaceId } from "components/Navigation/resolvePathWithSpaceId";
import { client } from "clientInstance";
import BusyIndicator from "components/BusyIndicator";
import { KeyedItemAccessConsumer } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import { NameOrIdKey } from "../../KeyAccessProvider/types";

interface CertificateSelectProps extends FormFieldProps<string> {
    allowClear?: boolean;
    tenantId?: string;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string | null;
    warning?: string;
    items(): Promise<CertificateResource[]>;
    onRequestRefresh(): Promise<boolean>;
    validate?(value: string | null): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface CertificateSelectState {
    error?: string;
    items?: CertificateResource[];
    busy: boolean;
}

class CertificateSelect extends React.Component<CertificateSelectProps, CertificateSelectState> {
    constructor(props: CertificateSelectProps) {
        super(props);
        this.state = {
            busy: true,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const items = await this.props.items();
            this.setState({ items, busy: false });
        });
    }

    render() {
        const { onChange, onValidate, tenantId, doBusyTask, items, onRequestRefresh, ...otherProps } = this.props;

        if (!this.state.items) {
            return <BusyIndicator show={true} inline={true} />;
        }

        return withTheme((theme) => (
            <KeyedItemAccessConsumer>
                {(keyedBy) => (
                    <BusyRefreshContainer busy={this.state.busy}>
                        <InputWithActions
                            input={<Select label="Select certificate" {...otherProps} allowFilter={true} onChange={this.handleChange} items={this.getItems(theme, keyedBy)} selectionRenderer={this.selectionRenderer} />}
                            actions={
                                <div className={styles.buttons}>
                                    <OpenDialogIconButton toolTipContent="Search" wideDialog={true} icon={Icon.Search}>
                                        <CertificateSelector onSelected={(certificate) => this.handleChange(certificate[keyedBy])} selectedCertificateId={this.props.value!} tenantId={this.props.tenantId} />
                                    </OpenDialogIconButton>
                                    <IconButton disabled={this.state.busy} onClick={this.refreshData} toolTipContent="Refresh" icon={Icon.Refresh} />
                                    <IconButton toolTipContent="Add" onClick={this.goToCertificates} icon={Icon.Add} />
                                </div>
                            }
                        />
                    </BusyRefreshContainer>
                )}
            </KeyedItemAccessConsumer>
        ));
    }

    private refreshData = async () => {
        let items: CertificateResource[] = [];
        try {
            this.setState({ busy: true });
            await this.props.onRequestRefresh();
            items = await this.props.items();
        } finally {
            this.setState({ items, busy: false });
        }
    };

    private handleChange = (certificateNameOrId: string | undefined) => {
        const value = certificateNameOrId === "" ? null : certificateNameOrId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        if (this.props.onChange) {
            this.props.onChange(value!);
        }
    };

    private getItems(theme: OctopusTheme, keyedBy: NameOrIdKey): Item[] {
        return this.state.items!.map((certificate) => {
            const expired = this.isCertificateExpired(certificate);
            const icon = expired ? <ThirdPartyIcon iconType={ThirdPartyIconType.Https} color={theme.danger} /> : <ThirdPartyIcon iconType={ThirdPartyIconType.Https} />;
            return {
                value: certificate[keyedBy],
                text: certificate.Name + (expired ? " (expired)" : ""),
                icon,
                ...(expired ? { style: { color: theme.danger } } : {}),
            };
        });
    }

    private selectionRenderer = (certificateId: string, theme: OctopusTheme) => {
        const certificate = this.state.items!.find((cert) => cert.Id === certificateId);
        if (!certificate) {
            return certificateId;
        }

        if (this.isCertificateExpired(certificate)) {
            return (
                <div style={{ color: theme.danger }}>
                    <span className={styles.selectedIcon}>
                        <ThirdPartyIcon iconType={ThirdPartyIconType.Https} color={theme.danger} />
                    </span>
                    {certificate.Name} (expired)
                </div>
            );
        }

        return (
            <div>
                <span className={styles.selectedIcon}>
                    <ThirdPartyIcon iconType={ThirdPartyIconType.Https} />
                </span>
                {certificate.Name}
            </div>
        );
    };

    private goToCertificates = () => {
        window.open(`#${resolveStringPathWithSpaceId(routeLinks.library.certificates.root, client.spaceId!)}`, "_blank");
    };

    private isCertificateExpired(certificate: CertificateResource) {
        const now = moment();

        const certificateExpiry = moment(certificate.NotAfter);
        return certificateExpiry.isBefore(now);
    }
}

export default CertificateSelect;
