/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { SFC } from "react";
import moment from "moment";
import DateFormatter from "utils/DateFormatter";

const styles = require("./style.less");

interface TimeFromNowLabelProps {
    time: string;
    useShortDateFormat?: boolean;
}

const TimeFromNowLabel: SFC<TimeFromNowLabelProps> = (props) => {
    return (
        <span className={styles.when}>
            {props.useShortDateFormat ? <abbr title={DateFormatter.momentAgo(props.time)}>{DateFormatter.dateToShortFormat(props.time)}</abbr> : <abbr title={DateFormatter.dateToLongFormat(props.time)!}>{moment(props.time).fromNow()}</abbr>}
        </span>
    );
};

export default TimeFromNowLabel;
