/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { List as MaterialList } from "material-ui";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
const styles = require("./style.less");
import BorderedListItem from "components/BorderedListItem";
import cn from "classnames";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";

interface RemoveItemsListProps<R> {
    empty?: React.ReactNode;
    data: R[];
    clearButtonToolTip?: string;
    listActions?: React.ReactElement[];
    onRow(item: R, idx: number): React.ReactNode;
    onRowTouch?(item: R): void;
    onRemoveRow?(item: R): void;
}

export abstract class RemoveItemsList<R> extends BaseComponent<RemoveItemsListProps<R>, {}> {
    private bottomActionVisibilityThreshold = 3;

    constructor(props: RemoveItemsListProps<R>) {
        super(props);
    }

    render() {
        const actions = (this.props.listActions || []).map((action, index) => React.cloneElement(action, { key: index }));
        return (
            <div>
                <div className={styles.actionsMenu}>{actions}</div>
                {this.props.data.length === 0 && this.props.empty && <div className={styles.emptyList}>{this.props.empty}</div>}
                {this.props.data.length !== 0 && <MaterialList>{this.props.data.map((item, index) => this.buildItem(item, index))}</MaterialList>}
                <div className={cn(styles.actionsMenu, { [styles.hidden]: this.props.data.length < this.bottomActionVisibilityThreshold })}>{actions}</div>
            </div>
        );
    }

    private buildItem(item: R, index: number) {
        const clearButton = this.props.onRemoveRow ? (
            <IconButton
                toolTipContent={this.props.clearButtonToolTip}
                onClick={(e) => {
                    e.stopPropagation();
                    this.props.onRemoveRow!(item);
                }}
                icon={Icon.Cancel}
                style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
            />
        ) : null;

        return (
            <BorderedListItem key={this.getKey(item, index)} rightIconButton={clearButton} onClick={this.props.onRowTouch ? () => this.onTouch(item) : null} disabled={this.props.onRowTouch ? false : true}>
                {this.props.onRow(item, index)}
            </BorderedListItem>
        );
    }

    private getKey(item: R | { Id: number }, index: number): string | number {
        const converted = item as object;
        return converted && converted.hasOwnProperty("Id") ? (item as { Id: number }).Id : index;
    }

    private onTouch(item: R) {
        if (this.props.onRowTouch) {
            this.props.onRowTouch(item);
        }
    }
}
