/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";

export const useRequiredContext = <TContextProps extends unknown>(context: React.Context<TContextProps>, name?: string) => {
    //TODO: Investigate options for compile-time type-safety for consumers of this context. For now, this gives us run-time protection.
    const result = React.useContext(context);
    if (result === undefined) {
        throw Error(`The "${name || context.displayName || "unnamed"}" context has not been initialized. Please ensure the provider exists above the current consumer in the component hierarchy to address this.`);
    }
    return result as NonNullable<TContextProps>;
};

export default useRequiredContext;
