import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
import ExternalVideoLink from "components/Navigation/ExternalLink/ExternalVideoLink";

export const OnboardingTextForEnvironments = (
    <span>
        Environments group deployment targets so your software can be promoted through your deployment lifecycle.
        <br />
        For instance, from <i>Development</i> to <i>Test</i> and finally into <i>Production</i>.
    </span>
);

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Add environments to group the deployment targets you will be deploying to"
            intro={<span>{OnboardingTextForEnvironments}</span>}
            learnMore={
                <>
                    <ExternalLink href="OnboardingEnvironmentsLearnMore">Learn more</ExternalLink>
                    <ExternalVideoLink href="OnboardingEnvironmentsVideo" />
                </>
            }
        />
    );
};

export default Onboarding;
