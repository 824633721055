/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";

//TODO: We should consider removing renderWithLayout considering our general usage of it which results in multiple re-renders per area for a given Layout.
export const renderWithLayout = (Layout: React.ComponentType<any>) => {
    return <TComponentProps extends any>(Component: React.ComponentType<TComponentProps>) => {
        const RenderWithLayout: React.SFC<TComponentProps> = (props) => {
            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            );
        };
        return RenderWithLayout;
    };
};
