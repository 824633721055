/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";

export type LookupProps<T> = {
    lookupId: string;
    lookupCollection: T[];
    render: (item: T) => React.ReactElement;
    getIdFromElement: (item: T) => string;
    renderFallback?: React.ReactElement;
};

function Lookup<T>(props: LookupProps<T>) {
    const item = props.lookupCollection.find((element) => props.getIdFromElement(element) === props.lookupId);
    return <>{item ? props.render(item) : props.renderFallback}</>;
}

export default Lookup;
