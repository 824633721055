import { FormFieldProps } from "components/form";
import { BoundAccountSelect } from "components/form/AccountSelect/AccountSelect";
import { AccountType, AccountResource } from "client/resources";
import React from "react";

interface AmazonWebServicesAccountInputProps extends FormFieldProps<string> {
    resetValue?: string;
    items: AccountResource[];
    label: string;
    warning?: string;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    onRequestRefresh: () => Promise<void>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}

const AmazonWebServicesAccountInput: React.FC<AmazonWebServicesAccountInputProps> = (props) => {
    const { value, resetValue, items, label, warning, allowClear, defaultValueIndicator, onRequestRefresh, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <BoundAccountSelect value={value} resetValue={resetValue} items={items} type={AccountType.AmazonWebServicesAccount} warning={warning} allowClear={allowClear} onRequestRefresh={onRequestRefresh} {...formProps} />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default AmazonWebServicesAccountInput;
