/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import Note from "components/form/Note/Note";
import { VariableLookupText } from "components/form/VariableLookupText";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import Summary from "components/form/Sections/Summary";
import { AccountResource, AccountType, GetPrimaryPackageReference, InitialisePrimaryPackageReference, Permission, SetPrimaryPackageReference } from "client/resources";
import FeedResource from "client/resources/feedResource";
import isBound from "components/form/BoundField/isBound";
import AzureWebSiteSelector from "components/Actions/azureWebApp/AzureWebSiteSelector";
import AzureWebSlotSelector from "components/Actions/azureWebApp/AzureWebSlotSelector";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { BoundStringRadioButtonGroup, StringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { BoundAccountSelect } from "components/form/AccountSelect/AccountSelect";
import { DisplayFeedName } from "../DisplayFeedName";
import { CalloutType, default as Callout } from "components/Callout/Callout";
import PermissionCheck, { isAllowed } from "../../PermissionCheck/PermissionCheck";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { ActionWithFeeds, LoadFeedsFromOptionalContext } from "../commonActionHelpers";
import { withOptionalProcessContext, WithOptionalProcessContextInjectedProps } from "areas/projects/components/Process/Contexts/ProcessContext";
import { RunOnBuiltInWorker, TargetRoles } from "areas/projects/components/Process/types";
import { isRunOnServerOrWorkerPool, isRunOnBuiltInWorker, isRunOnWorkerPoolForRoles, isRunOnDeploymentTarget, isRunOnWorkerPool, doesRunOnSupportBundledTools } from "areas/projects/components/Process/Common/CommonProcessHelpers";
import InternalLink from "../../Navigation/InternalLink/InternalLink";
import routeLinks from "../../../routeLinks";

interface AzureWebAppActionSummarySummaryState {
    feedName: string;
}

class AzureWebAppActionSummary extends BaseComponent<ActionSummaryProps, AzureWebAppActionSummarySummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                {"Deploy an Azure Web App"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface AzureWebAppProperties {
    OctopusUseBundledTooling: string;
    "Octopus.Action.Azure.IsLegacyMode": string;
    "Octopus.Action.Azure.AccountId": string;
    "Octopus.Action.Azure.WebAppName": string;
    "Octopus.Action.Azure.ResourceGroupName": string;
    "Octopus.Action.Azure.DeploymentSlot": string;
    "Octopus.Action.Azure.PhysicalPath": string;
    "Octopus.Action.Azure.RemoveAdditionalFiles": string;
    "Octopus.Action.Azure.PreserveAppData": string;
    "Octopus.Action.Azure.AppOffline": string;
    "Octopus.Action.Azure.UseChecksum": string;
}

interface AzureWebAppEditState extends ActionWithFeeds {
    accounts: AccountResource[];
    accountIsBound: boolean;
    webAppIsBound: boolean;
}

type Props = ActionEditProps<AzureWebAppProperties> & WithOptionalProcessContextInjectedProps;

class AzureWebAppEditInternal extends BaseComponent<Props, AzureWebAppEditState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            feeds: [],
            accounts: [],
            accountIsBound: isBound(props.properties["Octopus.Action.Azure.AccountId"], false),
            webAppIsBound: isBound(props.properties["Octopus.Action.Azure.WebAppName"], false),
        };
    }

    async componentDidMount() {
        await LoadFeedsFromOptionalContext(this.props.doBusyTask, this.props.processContext, (feeds: FeedResource[]) => {
            this.setState({ feeds }, () => {
                this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds));
            });
        });

        await this.props.doBusyTask(async () => {
            if (!!this.props.properties["Octopus.Action.Azure.AccountId"] && isAllowed({ permission: Permission.AccountView, wildcard: true })) {
                this.setState({
                    accounts: await repository.Accounts.all(),
                });
            }

            const properties: any = {};
            if (this.props.properties["Octopus.Action.Azure.UseChecksum"] === undefined) {
                properties["Octopus.Action.Azure.UseChecksum"] = "False";
            }
            if (!!this.props.properties["Octopus.Action.Azure.AccountId"]) {
                properties["Octopus.Action.Azure.IsLegacyMode"] = "True";
            }

            this.convertAzureWebAppNameAndSlot();

            this.props.setProperties(properties, true);
        });
    }

    convertAzureWebAppNameAndSlot() {
        let webAppName = this.props.properties["Octopus.Action.Azure.WebAppName"];
        let slotName = this.props.properties["Octopus.Action.Azure.DeploymentSlot"];

        if (!webAppName) {
            return;
        }

        const slashPosition = webAppName.indexOf("/");
        const parenPosition = webAppName.indexOf("(");
        if (slashPosition > 0) {
            slotName = webAppName.substring(slashPosition + 1).trim();
            webAppName = webAppName.substring(0, slashPosition).trim();
        } else if (parenPosition > 0) {
            slotName = webAppName
                .substring(parenPosition + 1)
                .replace(")", "")
                .trim();
            webAppName = webAppName.substring(0, parenPosition).trim();
        }

        this.props.properties["Octopus.Action.Azure.WebAppName"] = webAppName;
        this.props.properties["Octopus.Action.Azure.DeploymentSlot"] = slotName;
    }

    accountSummary() {
        const accountId = this.props.properties["Octopus.Action.Azure.AccountId"];
        if (!accountId) {
            return Summary.placeholder("No account has been selected");
        }
        const account = this.state.accounts.find((a) => a.Id === accountId);
        if (!account) {
            if (isBound(accountId, false)) {
                return Summary.summary(
                    <span>
                        Account is bound to <strong>{accountId}</strong>
                    </span>
                );
            }
            return Summary.placeholder("No account has been selected");
        }

        const webAppName = this.props.properties["Octopus.Action.Azure.WebAppName"];
        const resourceGroup = this.props.properties["Octopus.Action.Azure.ResourceGroupName"];
        const webAppSlot = this.props.properties["Octopus.Action.Azure.DeploymentSlot"];
        if (!webAppName) {
            return Summary.placeholder("No Web App has been selected");
        }
        const summary = [];
        summary.push(
            <span>
                Account <strong>{account.Name}</strong> will be used to deploy to Web App <strong>{webAppName}</strong>
            </span>
        );
        if (webAppSlot) {
            summary.push(
                <span>
                    , deployment slot <strong>{webAppSlot}</strong>,
                </span>
            );
        }

        if (resourceGroup) {
            summary.push(
                <span>
                    {" "}
                    in resource group <strong>{resourceGroup}</strong>
                </span>
            );
        }
        return Summary.summary(React.Children.toArray(summary));
    }

    toolingSummary() {
        if (this.props.properties.OctopusUseBundledTooling === "False") {
            return Summary.summary("Use Azure tools pre-installed on the worker for custom scripts");
        }
        return Summary.summary("Use Azure tools bundled with Octopus for custom scripts");
    }

    getAccountId = () => {
        return isBound(this.props.properties["Octopus.Action.Azure.AccountId"]) ? undefined : this.props.properties["Octopus.Action.Azure.AccountId"];
    };

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const isLegacyActionType = this.isLegacyMode();

        return (
            <div>
                {this.renderBundledToolsSection()}

                <FormSectionHeading title="Package" />

                <ExpandableFormSection
                    errorKey="package"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.state.feeds)}
                    help={<span>Select the package containing your application.</span>}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                </ExpandableFormSection>

                {isLegacyActionType && (
                    <div>
                        <FormSectionHeading title="Azure" />
                        <PermissionCheck
                            permission={Permission.AccountView}
                            wildcard={true}
                            alternate={
                                <Callout type={CalloutType.Information} title={"Permission required"}>
                                    The {Permission.AccountView} permission is required to change the Azure settings.
                                </Callout>
                            }
                        >
                            <Callout type={CalloutType.Warning} title={"Legacy mode"}>
                                <strong>
                                    This step is referencing an Azure Account directly, instead of referencing an <ExternalLink href="AzureTargets">Azure Web Application Target</ExternalLink> through Roles. Please read{" "}
                                    <ExternalLink href="AzureTargets">our documentation</ExternalLink> to learn how to get started with Azure Targets.
                                </strong>
                            </Callout>
                            <ExpandableFormSection
                                errorKey="Octopus.Action.Azure.AccountId|Octopus.Action.Azure.WebAppName|Octopus.Action.Azure.ResourceGroupName"
                                isExpandedByDefault={this.props.expandedByDefault}
                                title="Account and Web App"
                                summary={this.accountSummary()}
                                help="Select the Azure account and Web App for the deployment."
                            >
                                <BoundAccountSelect
                                    variableLookup={{
                                        localNames: this.props.localNames,
                                    }}
                                    resetValue={properties["Octopus.Action.Azure.AccountId"]}
                                    label="Account"
                                    value={properties["Octopus.Action.Azure.AccountId"]}
                                    isBound={this.state.accountIsBound}
                                    onIsBoundChanged={(value: boolean) => this.setState({ accountIsBound: value })}
                                    type={[AccountType.AzureServicePrincipal]}
                                    allowClear={true}
                                    onChange={(x) => {
                                        this.props.setProperties({ ["Octopus.Action.Azure.AccountId"]: x });
                                        if (!x) {
                                            // This is a key field in determining legacy Azure steps, so if this is cleared, also
                                            // clear related data that is now contributed by the target.
                                            this.clearLegacyModeProps();
                                        }
                                    }}
                                    error={this.props.getFieldError("Octopus.Action.Azure.AccountId")}
                                    items={this.state.accounts}
                                    onRequestRefresh={this.refreshAccounts}
                                />
                                <AzureWebSiteSelector
                                    doBusyTask={this.props.doBusyTask}
                                    webAppName={properties["Octopus.Action.Azure.WebAppName"]}
                                    webAppNameError={this.props.getFieldError("Octopus.Action.Azure.WebAppName")}
                                    resourceGroupName={properties["Octopus.Action.Azure.ResourceGroupName"]}
                                    resourceGroupError={this.props.getFieldError("Octopus.Action.Azure.ResourceGroupName")}
                                    onWebAppNameChanged={(x) => this.props.setProperties({ ["Octopus.Action.Azure.WebAppName"]: x })}
                                    onResourceGroupChanged={(x) => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupName"]: x })}
                                    projectId={this.props.projectId!}
                                    accountId={this.getAccountId()!}
                                    isAccountBound={this.state.accountIsBound}
                                    onIsBoundChanged={(value: boolean) => this.setState({ webAppIsBound: value })}
                                    localNames={this.props.localNames!}
                                />
                                <AzureWebSlotSelector
                                    doBusyTask={this.props.doBusyTask}
                                    projectId={this.props.projectId!}
                                    accountId={this.getAccountId()!}
                                    webAppName={properties["Octopus.Action.Azure.WebAppName"]}
                                    resourceGroupName={properties["Octopus.Action.Azure.ResourceGroupName"]}
                                    webAppSlotName={properties["Octopus.Action.Azure.DeploymentSlot"]}
                                    webAppSlotNameError={this.props.getFieldError("Octopus.Action.Azure.DeploymentSlot")}
                                    onWebAppSlotNameChanged={(x) => this.props.setProperties({ ["Octopus.Action.Azure.DeploymentSlot"]: x })}
                                    isAccountBound={this.state.accountIsBound}
                                    isWebAppBound={this.state.webAppIsBound}
                                    localNames={this.props.localNames!}
                                />
                            </ExpandableFormSection>
                        </PermissionCheck>
                    </div>
                )}

                <FormSectionHeading title="Deployment" />
                {!isLegacyActionType && (
                    <ExpandableFormSection
                        errorKey="Octopus.Action.Azure.DeploymentSlot"
                        title="Deployment Slot"
                        summary={
                            properties["Octopus.Action.Azure.DeploymentSlot"]
                                ? Summary.summary(
                                      <span>
                                          The deployment slot is <strong>{properties["Octopus.Action.Azure.DeploymentSlot"]}</strong>
                                      </span>
                                  )
                                : Summary.placeholder("No deployment slot defined")
                        }
                        help={"Optionally, enter the deployment slot."}
                    >
                        <VariableLookupText
                            localNames={this.props.localNames}
                            value={properties["Octopus.Action.Azure.DeploymentSlot"]}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.DeploymentSlot"]: x })}
                            error={this.props.getFieldError("Octopus.Action.Azure.DeploymentSlot")}
                            label="Deployment Slot"
                        />
                        <Note>
                            Slots let you deploy different versions of your web app to different URLs. You can test a certain version and then swap content and configuration between slots.
                            <strong>The slot will only apply if you have selected roles that scope to Azure Web App targets that represent the base App Service URL (and not the deployment slot URLs).</strong>
                        </Note>
                    </ExpandableFormSection>
                )}
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.PhysicalPath"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Physical Path"
                    summary={
                        properties["Octopus.Action.Azure.PhysicalPath"]
                            ? Summary.summary(
                                  <span>
                                      The physical path is <strong>{properties["Octopus.Action.Azure.PhysicalPath"]}</strong>
                                  </span>
                              )
                            : Summary.placeholder("No physical path has been entered")
                    }
                    help={"Enter the physical path to the site root."}
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["Octopus.Action.Azure.PhysicalPath"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.PhysicalPath"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Azure.PhysicalPath")}
                        label="Physical path"
                    />
                    <Note>
                        Physical path relative to site root. e.g. 'foo' will deploy to 'site\wwwroot\foo'.
                        <br />
                        Leave blank to deploy to root.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.RemoveAdditionalFiles"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Remove Additional Files"
                    summary={
                        properties["Octopus.Action.Azure.RemoveAdditionalFiles"] && properties["Octopus.Action.Azure.RemoveAdditionalFiles"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      Files in the destination that are not part of the deployment <strong>will</strong> be removed
                                  </span>
                              )
                            : Summary.default("Files in the destination that are not part of the deployment will not be removed")
                    }
                    help={"Select to remove additional files on the destination that are not part of the deployment."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.RemoveAdditionalFiles"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.RemoveAdditionalFiles"]: x })}
                        label="Remove additional files"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.PreserveAppData"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Preserve App_Data"
                    summary={
                        properties["Octopus.Action.Azure.PreserveAppData"] && properties["Octopus.Action.Azure.PreserveAppData"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      Files in the App_Data folder <strong>will not</strong> be removed
                                  </span>
                              )
                            : Summary.default("Files in the App_Data folder will be removed")
                    }
                    help={"Select to preserve files in the App_Data folder before deployment."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.PreserveAppData"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.PreserveAppData"]: x })}
                        label="Preserve App_Data"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.AppOffline"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Enable AppOffline"
                    summary={
                        properties["Octopus.Action.Azure.AppOffline"] && properties["Octopus.Action.Azure.AppOffline"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      The app domain <strong>will</strong> be safely brought down using a blank app_offline.html
                                  </span>
                              )
                            : Summary.default("The app domain will not be safely brought down using a blank app_offline.html")
                    }
                    help={"Select to safely bring down the app domain with app_offline.html in root"}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.AppOffline"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.AppOffline"]: x })}
                        label="Enable AppOffline"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.UseChecksum"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="File Comparison Method"
                    summary={
                        properties["Octopus.Action.Azure.UseChecksum"] && properties["Octopus.Action.Azure.UseChecksum"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      File <strong>checksums</strong> will be used to compare files (may increase deployment time)
                                  </span>
                              )
                            : Summary.default("File timestamps will be used to compare files")
                    }
                    help={"Select which method will be used to determine which files will be updated during deployment."}
                >
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.UseChecksum"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.UseChecksum"]: x })}
                        label="File comparison method"
                    >
                        <RadioButton label="Timestamp" value="False" isDefault />
                        <Note>Use file timestamps to compare files</Note>
                        <RadioButton label="Checksum" value="True" />
                        <Note>
                            Use file checksums to compare files
                            <br /> <em>Warning:</em> the checksum method may cause deployment times to increase significantly
                        </Note>
                    </BoundStringRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.IsLegacyMode"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Enable Legacy Mode"
                    summary={
                        properties["Octopus.Action.Azure.IsLegacyMode"] === "True"
                            ? Summary.summary(
                                  <span>
                                      <strong>Enabled</strong>: Account-related properties are configured on this step
                                  </span>
                              )
                            : Summary.default(<span>Not enabled: Account-related properties are configured on the Deployment Target</span>)
                    }
                    help={"Select legacy mode if you wish to configure account-related properties on the step and not through Azure Targets."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.IsLegacyMode"]}
                        onChange={async (x) => {
                            if (x === "True") {
                                this.props.setProperties({
                                    ["Octopus.Action.Azure.IsLegacyMode"]: "True",
                                });
                                await this.refreshAccounts();
                            } else {
                                this.clearLegacyModeProps();
                            }
                        }}
                        label="Enable Legacy Mode"
                    />
                    <Callout type={CalloutType.Warning} title={"Not recommended"}>
                        Toggling this <strong>on</strong> will allow account-related properties on the step <strong>(not recommended)</strong>.<br />
                        Toggling this <strong>off</strong> will clear the account-related properties on this step and allow these to be configured from your Deployment Targets.
                    </Callout>
                </ExpandableFormSection>
            </div>
        );
    }

    private renderBundledToolsSection = () => {
        const useBundled = this.props.properties.OctopusUseBundledTooling || "True";

        const bundledToolsSection = (
            <ExpandableFormSection
                errorKey="OctopusUseBundledTooling"
                isExpandedByDefault={false}
                title="Azure Scripting Tools"
                summary={this.toolingSummary()}
                help="Select whether to use the bundled Azure tools or using tooling pre-installed on the worker when running custom scripts"
            >
                <Note>
                    If <ExternalLink href="CustomScriptsInPackage">custom deployment scripts</ExternalLink> are used by this step they will depend on Azure tooling to authenticate and interact with the Azure App Service.
                </Note>
                <Note>
                    Octopus server can supply the Azure CLI 2.0.50 (and the Azure Resource Manager (ARM) PowerShell modules 6.13.1). Alternatively, to use a different version these tools should be pre-installed on the workers in the worker pool.
                </Note>
                <Note>
                    For example, to use the Az PowerShell modules rather than the bundled RM modules, they Az modules should be pre-installed and <em>Use Azure tooling pre-installed on the worker</em> should be selected below.
                </Note>
                <StringRadioButtonGroup
                    value={useBundled}
                    onChange={(x: string) => {
                        this.props.setProperties({ OctopusUseBundledTooling: x });
                    }}
                >
                    <RadioButton value="True" label="Use Azure tools bundled with Octopus" />
                    <RadioButton value="False" label="Use Azure tools pre-installed on the worker" />
                </StringRadioButtonGroup>
            </ExpandableFormSection>
        );

        if (doesRunOnSupportBundledTools(this.props.runOn)) {
            return bundledToolsSection;
        }

        return null;
    };

    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };

    private isLegacyMode(): boolean {
        const properties = this.props.properties;
        const isLegacyActionType = !!properties && !!(properties["Octopus.Action.Azure.AccountId"] || properties["Octopus.Action.Azure.IsLegacyMode"] === "True");
        return isLegacyActionType;
    }

    private clearLegacyModeProps = () => {
        this.props.setProperties(
            {
                ["Octopus.Action.Azure.IsLegacyMode"]: null!,
                ["Octopus.Action.Azure.AccountId"]: null!,
                ["Octopus.Action.Azure.WebAppName"]: null!,
                ["Octopus.Action.Azure.ResourceGroupName"]: null!,
                ["Octopus.Action.Azure.DeploymentSlot"]: null!,
            },
            false,
            this.props.refreshRunOn
        );
    };
}

export const AzureWebAppEdit = withOptionalProcessContext(AzureWebAppEditInternal);

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureWebApp",
    summary: (properties, targetRolesAsCSV, packages) => <AzureWebAppActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: AzureWebAppEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => {
        // Azure steps (pre 2018.5) allowed you to select accounts directly on the step, making target selection optional.
        const isLegacyActionType = !!(action ? action.Properties["Octopus.Action.Azure.AccountId"] || action.Properties["Octopus.Action.Azure.IsLegacyMode"] === "True" : null);
        const result = isLegacyActionType ? TargetRoles.Optional : TargetRoles.Required;
        return result;
    },
    hasPackages: (action) => true,
    features: {
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
            "Octopus.Features.SelectPowerShellEditionForWindows",
        ],
    },
});
