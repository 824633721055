import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { DirtyStateDetail } from "./DirtyStateTracking";
import { DevToolbar } from "./DevToolbar";
import { useDevToolsState } from "./DevToolsContext";
import { DevToolsOverview } from "./OverviewTab";

interface DevDrawerProps {
    open: boolean;
    height?: string;
    onRequestClose: () => void;
    onToggleFullscreen: () => void;
    fullscreen: boolean;
}

const useDrawerStyles = makeStyles({
    paper: (props: DevDrawerProps) => ({
        height: props.open ? (props.fullscreen ? "100%" : props.height ?? "500px") : "0",
    }),
});

const useTabPanelStyles = makeStyles({
    root: {
        overflowY: "auto",
    },
});

const TabPanel: React.FC<{ selectedTab: string; value: string }> = (props) => {
    const styles = useTabPanelStyles();
    return <div className={styles.root}>{props.selectedTab === props.value && props.children}</div>;
};

type DragHandleProps = {
    onMouseDown: React.MouseEventHandler<HTMLDivElement>;
};

const useDragHandleStyles = makeStyles({
    root: {
        height: "5px",
        cursor: "ns-resize",
    },
});

const DragHandle: React.FC<DragHandleProps> = (props) => {
    const styles = useDragHandleStyles(props);
    return <div onMouseDown={props.onMouseDown} className={styles.root} />;
};

const minDrawerHeight = 50;
const maxDrawerHeight = 1000;

export const DevDrawer: React.FC<DevDrawerProps> = (props) => {
    const [height, setHeight] = React.useState(props.height ?? 500);
    const state = useDevToolsState();
    const drawerClasses = useDrawerStyles({ ...props, height: `${height}px` });
    const tabs: Array<{ label: string; value: string }> = [];
    tabs.push({
        value: "Dev Tools",
        label: "Dev Tools",
    });
    const [selectedTab, setTab] = React.useState("Dev Tools");

    const tabPanels: Array<React.ReactNode> = [];
    if (state) {
        for (const key of Array.from(Object.keys(state.dirty))) {
            tabs.push({
                value: key,
                label: `Dirty state: ${key}`,
            });
            tabPanels.push(
                <TabPanel value={key} selectedTab={selectedTab}>
                    <DirtyStateDetail name={key} />
                </TabPanel>
            );
        }

        for (const [key, value] of Array.from(state.devToolsTabs.tabs.entries())) {
            tabs.push({
                value: key,
                label: key,
            });
            tabPanels.push(
                <TabPanel value={key} selectedTab={selectedTab}>
                    <>{value}</>
                </TabPanel>
            );
        }
    }

    if (!tabs.some((p) => p.value === selectedTab)) {
        //prevent console warnings if the currently selected tab is not actually visible anymore
        setTab(tabs[0].value);
    }

    const handleResize = React.useCallback(
        (e) => {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            const newHeight = windowHeight - e.clientY;
            if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
                setHeight(newHeight);
            }
        },
        [setHeight]
    );

    const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleResize, true);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleResize, true);
    };

    return (
        <Drawer open={props.open} anchor={"bottom"} variant={"permanent"} classes={drawerClasses}>
            {props.open && (
                <React.Fragment>
                    <DragHandle onMouseDown={handleMouseDown} />
                    <DevToolbar tabs={tabs} onSelectTab={setTab} selectedTab={selectedTab} onRequestClose={props.onRequestClose} onToggleFullscreen={props.onToggleFullscreen} isFullScreen={props.fullscreen} />
                    <TabPanel value={"Dev Tools"} selectedTab={selectedTab}>
                        <DevToolsOverview />
                    </TabPanel>
                    {tabPanels}
                </React.Fragment>
            )}
        </Drawer>
    );
};
