import * as React from "react";
import { TenantResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { MissingChip, TenantChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface TenantMultiSelectProps extends FormFieldProps<string[]> {
    items: TenantResource[];
    label?: string | JSX.Element;
    error?: string;
}

const TenantTypedMultiSelect = MultiSelect<TenantResource>();

const TenantMultiSelect: React.FC<TenantMultiSelectProps> = (props) => {
    const chipRenderer = (r: TenantResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <TenantChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} tenantName={item.Name} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Tenant} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <TenantTypedMultiSelect fieldName="tenants" renderChip={chipRenderer} {...props} />;
};

export default TenantMultiSelect;
