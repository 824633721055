import React from "react";
import { useDispatch } from "react-redux";
import { ExpanderActions, expanderActions } from "components/form/Sections/reducers/expanders";
import { Dispatch } from "redux";
import { useContextualExpandableContainer } from "./Expandable";

const ExpandExpanders: React.FC<{ containerKey?: string; getKeysToExpand: () => string[] }> = ({ getKeysToExpand, containerKey, children }) => {
    const dispatch = useDispatch<Dispatch<ExpanderActions>>();
    const container = useContextualExpandableContainer();

    React.useEffect(() => {
        const keysToExpand = getKeysToExpand();
        if (keysToExpand.length > 0) {
            dispatch(expanderActions.onExpandExpanders({ containerKey: containerKey ?? container, expandersWithKeys: keysToExpand }));
        }
    }, [containerKey, getKeysToExpand, container, dispatch]);

    return <>{children}</>;
};

export default React.memo(ExpandExpanders);
