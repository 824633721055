import * as React from "react";
import { ProjectResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { MissingChip, RunbookChip, SpaceChip } from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";
import { SpaceResource } from "../../client/resources/spaceResource";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface SpaceMultiSelectProps extends FormFieldProps<string[]> {
    items: SpaceResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    placeholder?: string;
    error?: string;
}

const SpaceTypedMultiSelect = MultiSelect<SpaceResource>();

const SpaceMultiSelect: React.FC<SpaceMultiSelectProps> = (props) => {
    const chipRenderer = (r: ProjectResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <SpaceChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} space={item} />}
                renderFallback={<MissingChip type={ChipIcon.Space} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };
    return <SpaceTypedMultiSelect label={props.label} placeholder={props.placeholder} fieldName="spaces" renderChip={chipRenderer} {...props} />;
};

export default SpaceMultiSelect;
