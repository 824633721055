import * as React from "react";
import { Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import UserProfileLayout from "../UserProfileLayout";
import UserApiKeys from "../UserApiKeys/UserApiKeys";
import UserProfile from "../UserProfile/UserProfile";
import UserRecentActivity from "../UserRecentActivity/UserRecentActivity";
import UserLogins from "../UserLogins/UserLogins";
import { renderWithLayout } from "components/RenderWithLayout/RenderWithLayout";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";

const userProfile = renderWithLayout(UserProfileLayout)(UserProfile);
const userRecentActivity = renderWithLayout(UserProfileLayout)(UserRecentActivity);
const userApiKeys = renderWithLayout(UserProfileLayout)(UserApiKeys);
const userLogins = renderWithLayout(UserProfileLayout)(UserLogins);

const UserProfilePage = withPage({ page: pageIds.currentUser.details })(userProfile);
const UserRecentActivityPage = withPage({ page: pageIds.currentUser.activity })(userRecentActivity);
const UserApiKeysPage = withPage({ page: pageIds.currentUser.apiKeys })(userApiKeys);
const UserLoginsPage = withPage({ page: pageIds.currentUser.logins })(userLogins);

export class UserProfileRoutes extends React.Component<RouteComponentProps> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.currentUser.details} component={UserProfilePage} />
                <ReloadableRoute path={routeLinks.currentUser.activity} component={UserRecentActivityPage} />
                <ReloadableRoute path={routeLinks.currentUser.apiKeys} component={UserApiKeysPage} />
                <ReloadableRoute path={routeLinks.currentUser.logins} component={UserLoginsPage} />
                <InternalRedirect from={routeLinks.currentUser.me} exact to={routeLinks.currentUser.details} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

export default UserProfileRoutes;
