/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { ProjectResource, Permission, SensitiveValue, OctopusError } from "client/resources";
import { repository } from "clientInstance";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import Sensitive from "components/form/Sensitive/Sensitive";
import { Text, ExpandableFormSection, Summary, SummaryNode, UnstructuredFormSection, Note } from "components/form";
import { required } from "components/form/Validators";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import Callout, { CalloutType } from "components/Callout/Callout";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import * as _ from "lodash";
import { withProjectContext, WithProjectContextInjectedProps } from "../../../context";
import configurationSelectors from "areas/configuration/reducers/selectors";
import CommitDialog, { CommitDialogStep } from "./CommitDialog";
import TestConnectionButton from "./TestConnectionButton";
import ActionButton, { ActionButtonType } from "components/Button";
import { ActionButtonProps } from "components/Button/ActionButton";
import FormPaperLayout from "components/FormPaperLayout";
import { GitChip } from "./GitChip";
import { branchNameToShowByDefault } from "client/resources/versionControlledResource";
import { PrimaryActionProps } from "components/FormPaperLayout/FormPaperLayout";

export interface VersionControlSettingsModel {
    enabled: boolean;
    url: string;
    username: string;
    password: SensitiveValue;
    defaultBranch: string;
}

interface VersionControlSettingsState extends OptionalFormBaseComponentState<VersionControlSettingsModel> {
    project?: ProjectResource;
    commitDialogActiveStep?: CommitDialogStep;
    saveForm: () => Promise<boolean>;
}

interface GlobalConnectedProps {
    isConfigurationAsCodeEnabled: boolean;
}

type ProjectSettingsProps = RouteComponentProps<ProjectRouteParams>;
type Props = ProjectSettingsProps & GlobalConnectedProps & WithProjectContextInjectedProps;

class VersionControlSettingsInternal extends FormBaseComponent<Props, VersionControlSettingsState, VersionControlSettingsModel> {
    constructor(props: Props) {
        super(props);

        this.state = {
            saveForm: () => Promise.resolve(false),
        };
    }

    resetVersionTemplate = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState((state) => {
            const nextModel = {
                ...state.model,
            };
            return {
                model: nextModel,
            };
        });
    };

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;

            this.setState({
                project,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });
        });
    }

    buildModel(project: ProjectResource): VersionControlSettingsModel {
        const model: VersionControlSettingsModel = {
            enabled: project.IsVersionControlled,
            url: project.VersionControlSettings.Url,
            username: project.VersionControlSettings.Username,
            password: project.VersionControlSettings.Password,
            defaultBranch: project.VersionControlSettings.DefaultBranch || branchNameToShowByDefault,
        };
        return model;
    }

    handleSaveClick = async () => {
        if (!this.state.model || !this.state.project) {
            throw Error("Tried to save with an empty model");
        }

        const model = this.state.model;
        const project: ProjectResource = {
            ...this.state.project,
            IsVersionControlled: model.enabled,
            VersionControlSettings: {
                Url: model.url,
                Username: model.username,
                Password: model.password,
                DefaultBranch: model.defaultBranch,
            },
        };

        await this.doBusyTask(async () => {
            await this.saveProject(project);
        });
    };

    render() {
        const featureWarning = (
            <UnstructuredFormSection stretchContent={true}>
                {this.props.isConfigurationAsCodeEnabled ? (
                    this.state.project?.AutoCreateRelease ? (
                        <Callout type={CalloutType.Warning} title={"Feature not supported"}>
                            This project has automatic release creation turned on. Converting a project with automatic release creation to version control is not supported.
                        </Callout>
                    ) : (
                        <Callout type={CalloutType.Warning} title={"Early access feature"}>
                            Connect your Git Repository to start creating branches of this project's deployment process and variables.
                        </Callout>
                    )
                ) : (
                    <Callout type={CalloutType.Warning} title={"This feature is currently disabled"} />
                )}
            </UnstructuredFormSection>
        );

        const isExpanded = this.state.model && !this.state.model.url;
        const formFields = this.props.isConfigurationAsCodeEnabled && this.state.model && this.state.project && (
            <React.Fragment>
                <TransitionAnimation>
                    {this.state.project.IsDisabled && (
                        <UnstructuredFormSection stretchContent={true}>
                            <Callout type={CalloutType.Warning} title={"This project is currently disabled"} />
                        </UnstructuredFormSection>
                    )}
                    <ExpandableFormSection errorKey="url" title="Git Repository" summary={this.summary()} help="In which Git repository do you want to store this project's deployment process?" isExpandedByDefault={isExpanded}>
                        <Text key="url" value={this.state.model.url} onChange={(url) => this.setModelState({ url })} label="URL" error={this.getFieldError("url")} validate={required("Enter a Git repository URL.")} />
                        <Note>Enter an existing empty (and initialized) git repository URL. E.g. https://...git</Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="username" title="Authentication" summary={this.authSummary()} help="Choose a method to authenticate with the above Git repository" isExpandedByDefault={isExpanded}>
                        <Text key="username" value={this.state.model.username} onChange={(username) => this.setModelState({ username })} label="Username" error={this.getFieldError("username")} validate={required("Enter authentication details.")} />
                        <Sensitive key="password" value={this.state.model.password} onChange={(password) => this.setModelState({ password })} label="Password" error={this.getFieldError("password")} />
                        <Note>This may be a Personal Access Token, depending on which provider you're using.</Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="defaultBranch" title="Default Branch Name" summary={this.branchSummary()} help="Provide the name of the default branch" isExpandedByDefault={isExpanded}>
                        <Text
                            key="defaultBranch"
                            value={this.state.model.defaultBranch}
                            onChange={(defaultBranch) => this.setModelState({ defaultBranch })}
                            label="Default Branch"
                            error={this.getFieldError("defaultBranch")}
                            validate={required("Enter a default branch.")}
                        />
                    </ExpandableFormSection>
                </TransitionAnimation>
            </React.Fragment>
        );

        const configurationDialog = this.props.isConfigurationAsCodeEnabled && this.state.model && this.state.project && this.state.commitDialogActiveStep && (
            <CommitDialog
                open={!!this.state.commitDialogActiveStep}
                step={this.state.commitDialogActiveStep}
                errors={this.errors}
                project={this.state.project}
                onStepChange={(commitDialogActiveStep: CommitDialogStep) => this.setState({ commitDialogActiveStep })}
                onNext={(commitMessage: string) => {
                    // TODO: try and clone the repository, passing the commit message.
                    // if that succeeds, save the VCS settings
                    return this.state.saveForm();
                }}
                onClose={() => {
                    this.setState({ commitDialogActiveStep: undefined });
                }}
            />
        );

        return (
            <FormPaperLayout
                title={[<span key="title">Version Control Settings&nbsp;</span>, <GitChip key="chip" size="small" enabled={!!this.state.model && this.state.model?.enabled} />]}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                saveButtonLabel={!!this.state.model && this.state.model.enabled ? "Save" : "Configure..."}
                saveButtonBusyLabel={!!this.state.model && this.state.model.enabled ? "Saving" : "Configuring..."}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                customPrimaryAction={(props: PrimaryActionProps) => {
                    // For this page (unlike most form layouts) we only save after a successful clone.
                    // We do that by first popping up a dialog, doing the clone and then saving if it succeeds.
                    // In order to do that, here we're storing a reference to the default form save function
                    // to call later on from the dialog.
                    const onClick = !this.state.model?.enabled
                        ? (e: React.MouseEvent) => {
                              e.preventDefault();
                              this.setState({
                                  saveForm: props.onClick as () => Promise<boolean>,
                                  commitDialogActiveStep: CommitDialogStep.CommitMessage,
                              });
                          }
                        : props.onClick;

                    return <ActionButton type={ActionButtonType.Save} label={props.label} disabled={props.disabled} busyLabel={props.busyLabel} onClick={onClick} />;
                }}
                saveText="Project details updated"
                secondaryAction={this.state.model && this.state.project && <TestConnectionButton disabled={!this.state.model.url} project={this.state.project} model={this.state.model} />}
            >
                {featureWarning}
                {formFields}
                {configurationDialog}
            </FormPaperLayout>
        );
    }

    private summary(): SummaryNode {
        const url = this.state.model && this.state.model.url;
        return url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    }

    private authSummary(): SummaryNode {
        const username = this.state.model && this.state.model.username;
        const password = this.state.model && this.state.model.password;
        if (username && password && password.HasValue) {
            return Summary.summary(
                React.Children.toArray([
                    <span>Authenticated with username</span>,
                    <span>
                        {" "}
                        <strong>{username}</strong>
                    </span>,
                ])
            );
        }

        return Summary.placeholder("Enter authentication details");
    }

    private branchSummary(): SummaryNode {
        if (!this.state.model || this.state.model.defaultBranch === branchNameToShowByDefault) {
            return Summary.default(branchNameToShowByDefault);
        }

        if (!this.state.model.defaultBranch) {
            return Summary.placeholder("Enter a default branch");
        }

        return Summary.summary(this.state.model.defaultBranch);
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);
        await this.props.projectContext.actions.onVersionControlEnabled(result);

        this.setState({
            model: this.buildModel(result),
            cleanModel: this.buildModel(result),
            project: result,
        });
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isConfigurationAsCodeEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isConfigurationAsCodeEnabled)(state),
    };
};

const VersionControlSettings = connect(mapGlobalStateToProps, null)(withProjectContext(VersionControlSettingsInternal));

export default VersionControlSettings;
