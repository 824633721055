/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { RouteComponentProps } from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import ExternalFeedList from "./ExternalFeedList";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { ExternalFeedResource } from "../../../../client/resources/feedResource";
import { repository } from "../../../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import Onboarding from "./Onboarding";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { ResourceCollection } from "client/resources";

interface FeedsState extends DataBaseComponentState {
    feeds: ResourceCollection<ExternalFeedResource>;
}

class ExternalFeeds extends DataBaseComponent<RouteComponentProps<void>, FeedsState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            feeds: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(this.loadFeeds);
    }

    render() {
        const addFeedButton = (
            <PermissionCheck permission={[Permission.FeedEdit]}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add feed" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="External Feeds" sectionControl={addFeedButton} busy={this.state.busy} errors={this.errors}>
                    {this.state.feeds != null && (
                        <div>
                            {this.state.feeds.TotalResults === 0 && <Onboarding />}
                            {this.state.feeds.TotalResults > 0 && (
                                <React.Fragment>
                                    <ExternalFeedList feeds={this.state.feeds} onDelete={this.handleFeedDelete} />
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private handleFeedDelete = async () => {
        await this.loadFeeds();

        return true;
    };

    private loadFeeds = async () => {
        const feeds = await repository.Feeds.listExternal();
        this.setState({
            feeds,
        });
    };
}

export default ExternalFeeds;
