import React from "react";
import Provenance from "provenance";
import { repository } from "clientInstance";
const styles = require("./style.less");

export const DevToolsOverview: React.FC = () => {
    const serverInformation = repository.getServerInformation();
    const version = serverInformation.version;

    return (
        <table className={styles.versionTable}>
            <tr>
                <th>Server version:</th>
                <td>{version}</td>
            </tr>
            <tr>
                <th>Portal version:</th>
                <td>{Provenance.getBuildVersion()}</td>
            </tr>
            <tr>
                <th>Portal commit hash:</th>
                <td>{Provenance.getGitCommitHash()}</td>
            </tr>
            <tr>
                <th>Portal commit branch:</th>
                <td>{Provenance.getGitCommitBranch()}</td>
            </tr>
        </table>
    );
};

export default DevToolsOverview;
