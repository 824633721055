import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import Note from "../../form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { BoundStringCheckbox } from "../../form/Checkbox/StringCheckbox";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings": "",
};

type ConfigurationVariablesProperties = { [P in keyof typeof StringProperties]: string };

class ConfigurationVariablesEdit extends BaseComponent<ActionEditProps<ConfigurationVariablesProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<ConfigurationVariablesProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        if ((this.props.properties["Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings"] || "").toLowerCase() !== "false") {
            return Summary.summary(
                <span>
                    Entries in <em>.config</em> files will be updated with variable values
                </span>
            );
        }

        return Summary.placeholder(
            <span>
                Entries in <em>.config</em> files will not be updated with variable values
            </span>
        );
    }

    render() {
        const properties = this.props.properties;

        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings"
                isExpandedByDefault={this.props.expandedByDefault}
                title=".NET Configuration Variables"
                summary={this.summary()}
                help="If set, replace appSettings and connectionString entries in any .config files."
            >
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings"]: x })}
                    label="Replace entries in .config files"
                    note={
                        <span>
                            Tentacle will look inside your <em>.config</em> files and attempt to replace any `appSettings`, `applicationSettings` or `connectionStrings` entries that have a `key` or `name` that matches the name of a variable defined
                            in Octopus. For more information, please <ExternalLink href="ConfigurationVariables">see the documentation</ExternalLink>.
                        </span>
                    }
                />
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeatureForAllScopes({
    featureName: "Octopus.Features.ConfigurationVariables",
    title: ".NET Configuration Variables",
    description: "Replace settings in any _.config_ files with variables defined in Octopus.",
    edit: ConfigurationVariablesEdit,
    priority: 20,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings"] = "True";
    },
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.Package.AutomaticallyUpdateAppSettingsAndConnectionStrings"];
    },
});
