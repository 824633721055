/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import WorkerPoolSearch from "components/form/WorkerPoolSearch/WorkerPoolSearch";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { WorkerPoolResource } from "client/resources";

interface WorkerPoolSelectorProps {
    selectedWorkerPoolId: string;

    onSelected(pool: WorkerPoolResource): void;
}

interface WorkerPoolSelectorState extends DataBaseComponentState {
    pool: WorkerPoolResource;
}

export default class WorkerPoolSelector extends DataBaseComponent<WorkerPoolSelectorProps, WorkerPoolSelectorState> {
    constructor(props: WorkerPoolSelectorProps) {
        super(props);
        this.state = {
            pool: null!,
        };
    }

    onOk() {
        this.props.onSelected(this.state.pool);
        return true;
    }

    render() {
        return (
            <OkDialogLayout title="Select WorkerPool" busy={this.state.busy} errors={this.errors} onOkClick={() => this.onOk()}>
                <WorkerPoolSearch selectedWorkerPoolId={this.props.selectedWorkerPoolId} onSelected={(pool) => this.setState({ pool })} doBusyTask={this.doBusyTask} />
            </OkDialogLayout>
        );
    }
}
