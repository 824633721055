import { SensitiveValue } from "client/resources/propertyValueResource";
import * as React from "react";
import { ExpandableFormSection, Summary, Text, Sensitive } from "components/form";

interface BasicFeedCredentialsProps {
    model: { username: string; password: SensitiveValue };
    onChanged(model: { username: string; password: SensitiveValue }): void;
}
export default class BasicFeedCredentialsFormSection extends React.Component<BasicFeedCredentialsProps, never> {
    render() {
        const model = this.props.model;
        return (
            <ExpandableFormSection
                errorKey="Credentials"
                title="Credentials"
                summary={model.username ? Summary.summary(`Credentials have been entered, username is ${model.username}`) : Summary.placeholder("Add authentication details if the feed requires authentication")}
                help="Leave the following blank if your feed does not require authentication."
            >
                <Text value={model.username} onChange={(username) => this.props.onChanged({ ...model, username })} label="Feed username" />
                <Sensitive value={model.password} onChange={(password) => this.props.onChanged({ ...model, password })} label="Feed password" />
            </ExpandableFormSection>
        );
    }
}
