/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import InputWithActions from "components/InputWithActions/InputWithActions";
import Text from "components/form/Text/Text";
import IconButtonList from "components/IconButtonList/IconButtonList";
import IconButton, { Icon } from "components/IconButton/IconButton";
import { noOp } from "utils/noOp";
import { compact } from "lodash";

interface FileUploadProps {
    placeholder?: string;
    label?: string;
    value: File | null | undefined;
    onChange(newValue: File | null | undefined): void;
}

class FileUpload extends React.Component<FileUploadProps> {
    input: any;

    render() {
        const name = this.props.value ? this.props.value.name : "";
        return (
            <div>
                <InputWithActions input={<Text placeholder={this.props.placeholder} value={name} onChange={noOp} label={this.props.label} onClick={this.openSelector} />} actions={<IconButtonList buttons={this.buttons()} />} />
                <input type="file" style={{ display: "none" }} onChange={this.handleFileChange} ref={(input) => (this.input = input)} />
            </div>
        );
    }

    private buttons() {
        return compact([<IconButton toolTipContent="Choose file" onClick={this.openSelector} icon={Icon.Upload} />, this.props.value ? <IconButton toolTipContent="Remove file" onClick={this.handleClearSelected} icon={Icon.Remove} /> : null]);
    }

    private handleFileChange = (e: any) => {
        if (e.target.files.length < 1) {
            return;
        }

        const file = e.target.files[0];
        this.props.onChange(file);
    };

    private handleClearSelected = () => {
        this.input.value = null;
        this.props.onChange(null);
    };

    private openSelector = () => {
        this.input.click();
    };
}

export default FileUpload;
