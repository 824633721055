import * as React from "react";

const loremText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.";

export const LongLoremText: React.FC = () => (
    <React.Fragment>
        {Array.from(Array(80).keys()).map((x) => (
            <p>{loremText}</p>
        ))}
    </React.Fragment>
);

export const ShortLoremText: React.FC = () => <p>{loremText}</p>;
