import * as React from "react";
import { EventResource, AccountType } from "client/resources";
import { memoize, forIn, omit } from "lodash";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../routeLinks";
import { EventReference } from "../../client/resources/eventResource";
import { TrackJS } from "trackjs";
import { ShouldTrackErrors } from "utils/TrackJS";

interface EventFormatterProps {
    event: EventResource;
}

interface ResourceLinkItem {
    prefix: string;
    redirect(id: string): string;
}

interface Index {
    reference?: EventReference | null;
    index: number;
}

interface Part {
    reference?: EventReference | null;
    message: string;
}

const documentTypeToLinkLookups = memoize((spaceId?: string) => linksFor(spaceId));

function linkTo(documentId: string | null, documentType: string | null, spaceId?: string) {
    if (!documentId || !documentType) {
        return "";
    }

    const documentTypeSpecificLink = documentTypeToLinkLookups(spaceId)[documentType];
    if (!documentTypeSpecificLink) {
        if (ShouldTrackErrors()) {
            //These will end up in trackjs, and also in slack channel #trackjs.
            //This error implies that the 'documentType' from the message is missing from the linksFor function below.
            TrackJS.track(`Unable to find document type to link mapping for '${documentType}' in event formatter`);
        }
        return "/not-found";
    }

    return documentTypeSpecificLink(documentId);
}

export const EventFormatter: React.StatelessComponent<EventFormatterProps> = (props) => {
    const message = props.event.Message;
    const references = props.event.MessageReferences;

    const indexes: Index[] =
        !references || references.length === 0
            ? [
                  { reference: null, index: 0 },
                  { reference: null, index: message.length },
              ]
            : toIndexes(references, message);

    let last: Index | null = null;
    const parts: Part[] = [];
    indexes.map((index) => {
        if (last) {
            parts.push({ reference: last.reference, message: message.substring(last.index, index.index) });
        }
        last = index;
    });

    const encodedParts = parts.map((part) => {
        const encodedMessage = <span>{part.message}</span>;
        if (part.reference) {
            const documentId = part.reference.ReferencedDocumentId;
            const documentType = retrieveDocumentTypeFromId(documentId);
            return <InternalLink to={linkTo(documentId, documentType, props.event.SpaceId)}>{encodedMessage}</InternalLink>;
        } else {
            return encodedMessage;
        }
    });

    return <span>{React.Children.toArray(encodedParts)}</span>;
};

function retrieveDocumentTypeFromId(documentId: string): string | null {
    const index = documentId.indexOf("-");
    return index === -1 ? null : documentId.substr(0, index).toLowerCase();
}

function toIndexes(references: EventReference[], message: string) {
    const indexes: Index[] = [];
    const refStart = references[0];

    if (refStart.StartIndex !== 0) {
        indexes.push({ reference: null, index: 0 });
    }

    let lastRef: EventReference | null = null;
    references.forEach((reference) => {
        if (!lastRef || lastRef.StartIndex !== reference.StartIndex) {
            indexes.push({ reference, index: reference.StartIndex });
        }
        indexes.push({ reference: null, index: reference.StartIndex + reference.Length });
        lastRef = reference;
    });

    const refEnd = references[references.length - 1];
    const endIndex = refEnd.StartIndex + refEnd.Length;
    if (endIndex !== message.length) {
        indexes.push({ reference: null, index: message.length });
    }

    return indexes;
}

type DocumentTypeToLinkLookup = { [prefix: string]: (id: string) => string };

function linksFor(spaceId?: string): DocumentTypeToLinkLookup {
    const lookup: DocumentTypeToLinkLookup = {};

    const spaceRouteLinks = routeLinks.forSpace(spaceId);

    lookup["actiontemplates"] = (id) => spaceRouteLinks.library.stepTemplate(id).root;
    lookup["deployments"] = (id) => spaceRouteLinks.deployment(id).root;
    lookup["environments"] = (id) => spaceRouteLinks.infrastructure.environment(id);
    lookup["feeds"] = (id) => routeLinks.library.feed(id).redirect;
    lookup["libraryvariablesets"] = (id) => spaceRouteLinks.library.variableSet(id);
    lookup["lifecycles"] = (id) => spaceRouteLinks.library.lifecycle(id);
    lookup["machinepolicies"] = (id) => spaceRouteLinks.infrastructure.machinePolicy(id);
    lookup["machines"] = (id) => spaceRouteLinks.infrastructure.machine(id).root;
    lookup["workers"] = (id) => spaceRouteLinks.infrastructure.workerMachine(id).root;
    lookup["projects"] = (id) => spaceRouteLinks.project(id).root;
    lookup["projectgroups"] = (id) => spaceRouteLinks.projectGroup(id).root;
    lookup["proxys"] = (id) => spaceRouteLinks.infrastructure.proxy(id);
    lookup["proxies"] = (id) => spaceRouteLinks.infrastructure.proxy(id);
    lookup["releases"] = (id) => spaceRouteLinks.release(id);
    lookup["servertasks"] = (id) => spaceRouteLinks.task(id).root;
    lookup["tagsets"] = (id) => spaceRouteLinks.library.tagSet(id);
    lookup["teams"] = (id) => spaceRouteLinks.configuration.team(id /*TODO: work out how to check against a list of teams, here keeping this stateless*/);
    lookup["tenants"] = (id) => spaceRouteLinks.tenant(id).root;
    lookup["users"] = (id) => spaceRouteLinks.configuration.user(id);
    lookup["userroles"] = (id) => spaceRouteLinks.configuration.role(id);
    forIn(omit(AccountType, AccountType.None), (value, key) => (lookup[value.toLowerCase()] = (id) => spaceRouteLinks.infrastructure.account(id)));
    lookup["subscriptions"] = (id) => spaceRouteLinks.configuration.subscription(id);
    lookup["certificates"] = (id) => spaceRouteLinks.library.certificate(id);
    lookup["projecttriggers"] = (id) => spaceRouteLinks.trigger(id);
    lookup["channels"] = (id) => spaceRouteLinks.channel(id);
    lookup["spaces"] = (id) => spaceRouteLinks.configuration.space(id);
    lookup["scopeduserroles"] = (id) => spaceRouteLinks.configuration.teams.redirect(id);
    lookup["octopusservernodes"] = (id) => routeLinks.configuration.nodes.root;
    lookup["runbookruns"] = (id) => spaceRouteLinks.runbookRun(id).root;
    lookup["runbooksnapshots"] = (id) => spaceRouteLinks.runbookSnapshot(id);
    lookup["runbooks"] = (id) => spaceRouteLinks.runbook(id);
    lookup["interruptions"] = (id) => spaceRouteLinks.tasks.interruption(id);
    return lookup;
}

export default EventFormatter;
