import React from "react";
import DisplayDiff from "components/DisplayDiff";
import { useDevToolsState } from "../DevToolsContext";

interface DirtyStateDetailProps {
    name: string;
}

export const DirtyStateDetail: React.FC<DirtyStateDetailProps> = React.memo((props) => {
    const state = useDevToolsState();
    const lookup = state?.dirty ?? {};
    const oldValue = JSON.stringify(lookup[props.name]?.cleanModel, null, 4);
    const newValue = JSON.stringify(lookup[props.name]?.model, null, 4);

    return <DisplayDiff oldValue={oldValue} newValue={newValue} splitView={true} />;
});

DirtyStateDetail.displayName = "DirtyStateDetail";

export default DirtyStateDetail;
