/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource";
import { VariableType } from "client/resources/variableResource";
import { AllVariableMessages, VariableMessages, ValueMessages } from "areas/variables/VariableMessages/VariableMessages";
import { IQuery } from "components/QueryStringFilters/QueryStringFilters";

export interface VariableFilter {
    name: string;
    value: string;
    description: string;
    scope: ScopeSpecification;
    filterEmptyValues: boolean;
    filterVariableSubstitutionSyntax: boolean;
    filterDuplicateNames: boolean;
    filterNonPrintableCharacters: boolean;
}

export interface VariableQuery extends IQuery {
    name?: string;
    value?: string;
    description?: string;
    filterEmptyValues?: string;
    filterDuplicateNames?: string;
    filterNonPrintableCharacters?: string;
    environment?: string[];
    machine?: string[];
    role?: string[];
    action?: string[];
    channel?: string[];
    tenantTag?: string[];
    process?: string[];
}

export interface FilterableValue {
    description?: string;
    type: VariableType;
    scope: ScopeSpecification;
    value?: string;
    isPrompted: boolean;
}

export function filterVariableGroups<TGroup>(groups: ReadonlyArray<TGroup>, messages: AllVariableMessages, filter: VariableFilter, getGroupName: (group: TGroup) => string): ReadonlyArray<FilteredGroup<TGroup>> {
    return groups.map((group, index) => {
        const variableMessages = messages.variableMessages[index];
        const groupName = getGroupName(group);
        const matchesEmptyValuesFilter = !filter.filterEmptyValues || variableMessages.valuesMessages.some((vw) => vw.hasEmptyValue);
        const matchesVariableSubstitutionSyntaxFilter = !filter.filterVariableSubstitutionSyntax || variableMessages.valuesMessages.some((vw) => vw.hasVariableSubstitutionSyntax);
        const matchesDuplicateNamesFilter = !filter.filterDuplicateNames || variableMessages.hasDuplicateName;
        const matchesNameFilter = containsFilter(groupName, filter.name);

        return {
            group,
            matchesFilter: matchesEmptyValuesFilter && matchesDuplicateNamesFilter && matchesNameFilter && matchesVariableSubstitutionSyntaxFilter,
        };
    });
}

export interface FilteredGroup<TGroup> {
    group: TGroup;
    matchesFilter: boolean;
}

export function matchesFilter(variable: FilterableValue, variableMessages: VariableMessages, valueMessages: ValueMessages, filter: VariableFilter) {
    const matchesTextFilter = containsFilter(variable.description || "", filter.description) && containsFilter(variable.value || "", filter.value);
    const matchesEmptyValuesFilter = !filter.filterEmptyValues || isEmpty(variable.value!);
    const matchesVariableSubstitutionSyntaxFilter = !filter.filterVariableSubstitutionSyntax || variableMessages.valuesMessages.some((vw) => vw.hasVariableSubstitutionSyntax);
    // If the name has non printable characters, show all the values. This is because we need some value shown in the grid for the group to actually appear
    // This may change in the future if we, for example, display the group header in a separate row
    const matchesNonPrintableCharactersFilter = !filter.filterNonPrintableCharacters || !!valueMessages.valueNonPrintableCharacter || variableMessages.nameNonPrintableCharacter;
    const matchesScopeFilter = appliesToFilterScope(variable.scope, filter.scope);

    return matchesTextFilter && matchesEmptyValuesFilter && matchesNonPrintableCharactersFilter && matchesScopeFilter && matchesVariableSubstitutionSyntaxFilter;

    function isEmpty(value: string) {
        if (variable.value) {
            return false;
        }

        return true;
    }

    function appliesToFilterScope(variableScope: ScopeSpecification, filterScope: ScopeSpecification) {
        const scopePartGetters: Array<(s: ScopeSpecification) => ReadonlyArray<string | undefined> | undefined> = [(s) => s.Environment, (s) => s.Machine, (s) => s.Role, (s) => s.Action, (s) => s.Channel, (s) => s.TenantTag, (s) => s.ProcessOwner];
        return scopePartGetters.every((p) => appliesToScopePart(p));

        function appliesToScopePart(scopePartGetter: (scope: ScopeSpecification) => ReadonlyArray<string | undefined> | undefined) {
            const scopeParts: ReadonlyArray<string | undefined> = scopePartGetter(variableScope) || [];
            const filterScopeParts: ReadonlyArray<string | undefined> = scopePartGetter(filterScope) || [];
            return scopeParts.length === 0 || filterScopeParts.every((s) => scopeParts.some((p) => p === s));
        }
    }
}

export function containsFilter(variableValue: string, filterValue: string) {
    if (!filterValue) {
        return true;
    }
    return variableValue.toLowerCase().includes(filterValue.toLowerCase());
}

export function createEmptyFilter(): VariableFilter {
    return {
        name: "",
        value: "",
        description: "",
        filterEmptyValues: false,
        filterDuplicateNames: false,
        filterNonPrintableCharacters: false,
        filterVariableSubstitutionSyntax: false,
        scope: {
            Environment: [],
            Machine: [],
            Role: [],
            Action: [],
            Channel: [],
            TenantTag: [],
            ProcessOwner: [],
        },
    };
}
