import * as React from "react";
import { PagingDataTable, PagingDataTableProps } from "../../../../components/PagingDataTable/PagingDataTable";
import { useProjectContext } from "areas/projects/context";
import { ReleaseResource } from "client/resources";
import { GitRefChip } from "./GitRefChip/GitRefChip";
const styles = require("./style.less");

class PagingReleasesTableInternal extends PagingDataTable<ReleaseResource> {}

interface Cells {
    ReleaseCell: React.FC<{ release: ReleaseResource }>;
    ChannelCell: React.FC<{ release: ReleaseResource }>;
    ReleaseNotesCell: React.FC<{ release: ReleaseResource }>;
}

type PagingReleasesTableProps = Cells & Omit<PagingDataTableProps<ReleaseResource>, "headerColumns" | "headerColumnClassNames" | "onRow">;

const GitRefCell: React.FC<{ release: ReleaseResource }> = ({ release }) => {
    const vcsRef = release.VersionControlReference;
    return <div className={styles.marginTopBottom}>{<GitRefChip vcsRef={vcsRef} showCommit={false} className={styles.gitRefChip} />}</div>;
};

const VcsPagingReleasesTable: React.FC<PagingReleasesTableProps> = ({ ReleaseCell, ChannelCell, ReleaseNotesCell, ...props }) => {
    const headerColumns = ["Release", "Channel", "Git Ref", "Release Notes"];
    const headerColumnClassNames = ["", "", "", styles.notesColumn];
    const vcsRows = (release: ReleaseResource): React.ReactNode => {
        return release && [<ReleaseCell release={release} />, <ChannelCell release={release} />, <GitRefCell release={release} />, <ReleaseNotesCell release={release} />];
    };
    return <PagingReleasesTableInternal headerColumns={headerColumns} headerColumnClassNames={headerColumnClassNames} onRow={vcsRows} {...props} />;
};

const DatabasePagingReleasesTable: React.FC<PagingReleasesTableProps> = ({ ReleaseCell, ChannelCell, ReleaseNotesCell, ...props }) => {
    const headerColumns = ["Release", "Channel", "Release Notes"];
    const headerColumnClassNames = ["", "", styles.notesColumn];
    const databaseRows = (release: ReleaseResource): React.ReactNode => {
        return release && [<ReleaseCell release={release} />, <ChannelCell release={release} />, <ReleaseNotesCell release={release} />];
    };

    return <PagingReleasesTableInternal headerColumns={headerColumns} headerColumnClassNames={headerColumnClassNames} onRow={databaseRows} {...props} />;
};

export const PagingReleasesTable: React.FC<PagingReleasesTableProps> = (props) => {
    const projectContext = useProjectContext();
    return projectContext?.state.model?.IsVersionControlled ? <VcsPagingReleasesTable {...props} /> : <DatabasePagingReleasesTable {...props} />;
};
