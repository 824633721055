import * as React from "react";
import cn from "classnames";
import Portal from "@material-ui/core/Portal";
import { useCallback, useRef, useEffect } from "react";
import { DialogLayoutDispatchProps } from "../DialogLayout/DialogLayout";
import Fade from "@material-ui/core/Fade";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import Modal from "@material-ui/core/Modal";
import { FocusOn } from "react-focus-on";
import { FocusContextProvider } from "components/FocusContext";
import ErrorContextProvider from "../ErrorContext/ErrorContext";

const styles = require("./CustomDialog.less");

interface CustomDialogStateProps {
    open: boolean;
}

export interface RenderProps extends DialogLayoutDispatchProps, CustomDialogStateProps {}

interface CustomDialogRenderProps extends RenderProps {
    render: (props: RenderProps) => React.ReactNode;
}

export interface CustomDialogProps extends CustomDialogRenderProps, DialogLayoutDispatchProps, CustomDialogStateProps {
    TransitionComponent?: React.ComponentType<TransitionProps>;
}

export const Overlay: React.SFC<{ className?: string }> = ({ children, className }) => {
    return (
        <Portal>
            <div className={cn(styles.overlay, className)}>{children}</div>
        </Portal>
    );
};

type RefCallback<T> = (value: T) => void;
function setRef<T>(ref: React.MutableRefObject<T> | RefCallback<T> | null | undefined, value: T) {
    if (!ref) {
        return;
    }

    if (typeof ref === "function") {
        ref(value);
    } else if (typeof ref === "object" && ref.hasOwnProperty("current")) {
        ref.current = value;
    }
}

interface ModalRootProps extends React.HTMLAttributes<HTMLDivElement> {
    innerRef?: React.RefObject<HTMLDivElement>;
}

const ModalFocusArea = React.forwardRef<HTMLDivElement, ModalRootProps>(({ children, className, innerRef }, ref) => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setRef(innerRef, divRef.current);
        setRef(ref, divRef.current);
    }, [innerRef, ref]);

    return (
        <div className={cn(styles.focusArea, className)} ref={divRef}>
            <FocusContextProvider value={{ current: divRef.current }}>
                <FocusOn onEscapeKey={close} shards={[divRef]}>
                    {children}
                </FocusOn>
            </FocusContextProvider>
        </div>
    );
});

ModalFocusArea.displayName = "ModalRoot";

export const CustomDialog: React.SFC<CustomDialogProps> = ({ open, TransitionComponent = Fade, render, close, ...rest }) => {
    const handleClose = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);
    const renderProps = { open, close: handleClose };

    //We need to disable autofocus and autotrap behavior included by material since we are using focus-on
    return (
        <ErrorContextProvider>
            <Modal open={open} disableEnforceFocus disableAutoFocus disableRestoreFocus>
                <TransitionComponent in={open}>
                    <ModalFocusArea>{open && render(renderProps)}</ModalFocusArea>
                </TransitionComponent>
            </Modal>
        </ErrorContextProvider>
    );
};

export default React.memo(CustomDialog);
