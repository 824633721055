import * as React from "react";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Start adding environments and deployment targets so you can deploy your software"
            intro={
                <span>
                    Infrastructure includes the environments, targets, workers and accounts that you will be deploying to. Deployment targets are the machines and services you deploy your software to, workers are machines that help with the
                    deployment process, and environments are organized groups of deployment targets that give you control over the deployment pipeline.
                </span>
            }
            learnMore={
                <>
                    <ExternalLink href="OnboardingInfrastructureLearnMore">Learn more</ExternalLink>
                </>
            }
        />
    );
};

export default Onboarding;
