import * as React from "react";
import { Provider } from "react-redux";
import store from "./store";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Theme from "components/Theme";
import { FocusContextProvider } from "components/FocusContext";
import { ThemeApplier } from "components/Theme/ThemeApplier";
import { ErrorContextProvider } from "components/ErrorContext/ErrorContext";

export const AppContext: React.FC = ({ children }) => (
    <ErrorBoundary>
        <ErrorContextProvider>
            <Provider store={store}>
                <ThemeApplier />
                <Theme>
                    <FocusContextProvider value={{ current: null }}>{children}</FocusContextProvider>
                </Theme>
            </Provider>
        </ErrorContextProvider>
    </ErrorBoundary>
);

export default AppContext;
