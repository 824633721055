/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import FeedResource, { FeedType } from "client/resources/feedResource";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import { WildflyStateProperties } from "./wildflyFeatureTemplate";
import routeLinks from "../../../routeLinks";
import { GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "../../../client/resources";
import { DataBaseComponent, DataBaseComponentState } from "../../DataBaseComponent";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { PackageSelectionMode } from "client/resources/packageReference";
import { ActionWithFeeds, LoadFeedsFromOptionalContext } from "../commonActionHelpers";
import { withOptionalProcessContext, WithOptionalProcessContextInjectedProps } from "areas/projects/components/Process/Contexts/ProcessContext";
import { TargetRoles } from "areas/projects/components/Process/types";

class DeployToWildflyActionSummary extends BaseComponent<ActionSummaryProps> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>Deploy a Java application to Wildfly 10+ and Red Hat JBoss EAP 6+</div>;
    }
}

interface DeployToWildflyProperties extends WildflyStateProperties {
    "Octopus.Action.Package.TransferPath": string;
}

interface DeployToWildflyActionEditState extends DataBaseComponentState, ActionWithFeeds {}

type Props = ActionEditProps<DeployToWildflyProperties> & WithOptionalProcessContextInjectedProps;

class DeployToWildflyActionEditInternal extends DataBaseComponent<Props, DeployToWildflyActionEditState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            feeds: [],
        };
    }

    async componentDidMount() {
        await LoadFeedsFromOptionalContext(this.props.doBusyTask, this.props.processContext, (feeds: FeedResource[]) => {
            this.setState({ feeds }, () => {
                this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds));
            });
        });
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);
        const feed = _.find(this.state.feeds, (f) => f.Id === pkg.FeedId);

        const help =
            this.state.feeds.length > 0 ? (
                <span>
                    This step is used to deploy a package to one or more machines which may be sourced from an external feed or the Octopus built-in feed. You can configure the remote machines to deploy to in the{" "}
                    <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>
                        Infrastructure
                    </InternalLink>{" "}
                    tab.
                </span>
            ) : (
                <span>Choose the package you wish to deploy.</span>
            );

        return (
            <div>
                <ExpanderSectionHeading title="Package Details" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.state.feeds)}
                    help={help}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId!}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames!}
                        feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven]}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters!}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <PackageDownloadOptions
                            packageAcquisitionLocation={pkg.AcquisitionLocation}
                            onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                            feed={feed!}
                            projectId={this.props.projectId!}
                            localNames={this.props.localNames!}
                        />
                    )}
                </ExpandableFormSection>
            </div>
        );
    }

    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };
}

export const DeployToWildflyActionEdit = withOptionalProcessContext(DeployToWildflyActionEditInternal);

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.WildFlyDeploy",
    summary: (properties, targetRolesAsCSV, packages) => <DeployToWildflyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: DeployToWildflyActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles"],
        permanent: ["Octopus.Features.WildflyDeployCLI"],
    },
});
