import * as React from "react";
import DividerMaterialUI from "material-ui/Divider";
import { withTheme } from "components/Theme";

interface DividerProps {
    fullHeight?: boolean;
}

export const Divider: React.StatelessComponent<DividerProps> = (props) =>
    withTheme((theme) => {
        const margin = props.fullHeight ? { marginTop: 0 } : {};
        return <DividerMaterialUI style={{ backgroundColor: theme.divider, ...margin }} />;
    });

export default Divider;
