import * as React from "react";
import { ProjectedTeamReferenceDataItem, NamedReferenceItem, TeamMembership } from "client/resources";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import ListTitle from "components/ListTitle/ListTitle";
import { SimpleList } from "components/List";
import { ExternalSecurityGroupChip } from "components/Chips";

const styles = require("./ProjectedTeamsList.less");

type TeamMembershipTypes = ProjectedTeamReferenceDataItem | TeamMembership;

class ProjectedTeamsList<TMembership extends TeamMembershipTypes> extends SimpleList<TMembership> {}

interface ProjectedTeamsListItemProps<TMembership extends TeamMembershipTypes> {
    projectedTeam: TMembership;
}

const ExternalGroupChips: React.SFC<{ externalGroups?: NamedReferenceItem[] }> = ({ externalGroups }) => {
    if (!externalGroups) {
        return null;
    }
    return (
        <>
            {externalGroups.map((group) => (
                <ExternalSecurityGroupChip key={group.Id} group={group} />
            ))}
        </>
    );
};

interface ProjectedTeamDescriptionProps {
    isDirectAssignment: boolean;
    externalGroups?: NamedReferenceItem[];
}

const ProjectedTeamDescription: React.SFC<ProjectedTeamDescriptionProps> = ({ isDirectAssignment, externalGroups }) => {
    if (!externalGroups) {
        return null;
    }

    return (
        <>
            {externalGroups && externalGroups.length > 0 && "Mapped from external source "}
            <ExternalGroupChips externalGroups={externalGroups} />
            {isDirectAssignment && externalGroups && externalGroups.length > 0 && (
                <span>
                    {" "}
                    and also <strong>directly assigned to team.</strong>
                </span>
            )}
        </>
    );
};

const ProjectedTeamListItem: React.SFC<ProjectedTeamsListItemProps<TeamMembershipTypes>> = ({ projectedTeam }) => (
    <ListTitle>
        <div className={styles.root}>
            <ThirdPartyIcon iconType={ThirdPartyIconType.Group} className={styles.icon} />
            <div className={styles.detail}>
                <div className={styles.description}>{"TeamName" in projectedTeam ? projectedTeam.TeamName : projectedTeam.Name}</div>
                <div className={styles.externalGroups}>
                    <ProjectedTeamDescription isDirectAssignment={projectedTeam.IsDirectlyAssigned} externalGroups={projectedTeam.ExternalSecurityGroups} />
                </div>
            </div>
        </div>
    </ListTitle>
);

export { ProjectedTeamsList, ProjectedTeamListItem, ProjectedTeamsListItemProps, ProjectedTeamDescription, ProjectedTeamDescriptionProps, ExternalGroupChips };

export default ProjectedTeamsList;
