/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import ActionProperties from "client/resources/actionProperties";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import { cloneDeep, isEqual } from "lodash";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout";
import { ExpandableFormSection, ExpansionButtons, required, Summary, Text } from "components/form";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import ActionEditor from "components/ActionEditor/ActionEditor";
import { ActionPlugin, default as pluginRegistry } from "components/Actions/pluginRegistry";
import FeatureEditor from "components/FeatureEditor/FeatureEditor";
import { UrlNavigationTabsContainer } from "components/Tabs";
import TabItem from "components/Tabs/TabItem";
import { default as LogoEditor, LogoEditorSettings } from "components/form/LogoEditor/LogoEditor";
import OpenFeatureDialog from "components/OpenFeatureDialog/OpenFeatureDialog";
import { Section } from "components/Section/Section";
import { ActionTemplateResource } from "client/resources/actionTemplateResource";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import Markdown from "components/Markdown";
import ActionTemplateParameterList from "components/ActionTemplateParametersList";
import { SummaryNode } from "components/form/Sections/ExpandableFormSection";
import Logo from "components/Logo/Logo";
import { saveLogo } from "client/repositories/logoUpload";
import ExportActionTemplateDialog from "../ExportActionTemplateDialog";
import DialogOpener from "components/Dialog/DialogOpener";
import SaveAsDialog from "../SaveAsDialog";
import { actionTemplateFetch } from "../../../reducers/libraryArea";
import { connect } from "react-redux";
import { ControlType, Permission } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import routeLinks from "routeLinks";
import { ActionTemplateParameterResource } from "client/resources/actionTemplateParameterResource";
import { Callout, CalloutType } from "components/Callout";
import { CommunityActionTemplateResource } from "client/resources/communityActionTemplateResource";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { PackageReference } from "client/resources/packageReference";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ScriptActionContext } from "components/Actions/script/ScriptActionContext";

interface ActionTemplateParams {
    templateId?: string;
    actionType?: string;
}

interface ActionTemplateModel {
    template: Partial<ActionTemplateResource>;
    logo: LogoEditorSettings;
}

export interface ActionTemplateState extends OptionalFormBaseComponentState<ActionTemplateModel> {
    redirectTo: string;
    plugin: ActionPlugin;
    isLoaded: boolean;
    isNew: boolean;
    showSaveAs: boolean;
    localNames: string[];
    communityTemplate?: CommunityActionTemplateResource;
}

interface GlobalDispatchProps {
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
}

type Props = WithActionScopeInjectedProps & RouteComponentProps<ActionTemplateParams> & GlobalDispatchProps;

class ActionTemplateInternal extends FormBaseComponent<Props, ActionTemplateState, ActionTemplateModel> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null!,
            plugin: null!,
            isLoaded: false,
            isNew: true,
            showSaveAs: false,
            localNames: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const model = this.props.match.params.templateId ? await this.getExistingTemplate() : this.getNewTemplate();
            const plugin = pluginRegistry.getDeploymentAction(model.template.ActionType!);
            const communityTemplate = model.template.CommunityActionTemplateId ? repository.CommunityActionTemplates.get(model.template.CommunityActionTemplateId) : Promise.resolve(null!);

            this.setState({
                model,
                cleanModel: cloneDeep(model),
                communityTemplate: await communityTemplate,
                plugin,
                isLoaded: true,
                isNew: !!!this.props.match.params.templateId,
                localNames: this.toLocalNames(model.template.Parameters!),
            });
        });
    }

    loadVariables = () => {
        return repository.Variables.names("", undefined);
    };

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        return (
            <FormPaperLayout
                title="Details"
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: this.state.isNew ? Permission.ActionTemplateCreate : Permission.ActionTemplateEdit }}
                onSaveClick={this.state.isLoaded && this.isBasedOnCommunityTemplate() ? this.handleSaveAsCopyClick : this.handleSaveClick}
                hideExpandAll={true}
                disableDirtyFormChecking={this.state.isLoaded && this.isBasedOnCommunityTemplate() && !this.dataChanged()}
                saveButtonLabel={this.state.isLoaded ? this.saveButtonLabel() : undefined}
                saveText={this.state.isLoaded ? this.saveText() : undefined}
                overFlowActions={this.state.isLoaded ? this.overflowActions() : undefined}
                secondaryAction={this.state.isLoaded ? this.addFeaturesElement() : undefined}
            >
                {this.state.isLoaded && (
                    <ScriptActionContext.Provider value={{ loadVariables: this.loadVariables }}>
                        <div>
                            <DialogOpener open={this.state.showSaveAs} onClose={() => this.setState({ showSaveAs: false })}>
                                <SaveAsDialog originalName={this.state.model!.template.Name!} onSave={this.onSaveAsCopy} />
                            </DialogOpener>
                            {this.isUpdateAvailable() && (
                                <Callout type={CalloutType.Warning} title={"New version available"}>
                                    View the new version of the <InternalLink to={routeLinks.library.stepTemplates.communityTemplate(this.state.communityTemplate!.Id).root}>{this.state.model!.template.Name}</InternalLink> step template.
                                </Callout>
                            )}
                            <UrlNavigationTabsContainer defaultValue={this.state.isNew ? "settings" : "step"}>
                                <TabItem label="Step" value="step">
                                    <ExpansionButtons errors={this.errors} expandAllOnMount={this.state.isNew} />
                                    <ActionEditor
                                        scope={this.props.scope}
                                        properties={this.state.model!.template.Properties!}
                                        packages={this.state.model!.template.Packages!}
                                        isNew={this.state.isNew}
                                        doBusyTask={this.doBusyTask}
                                        busy={this.state.busy!}
                                        plugin={this.state.plugin}
                                        setProperties={this.setProperties}
                                        setPackages={this.setPackages}
                                        getFieldError={this.getFieldError}
                                        localNames={this.state.localNames}
                                        errors={this.errors}
                                        expandedByDefault={this.state.isNew}
                                        parameters={this.state.model!.template.Parameters}
                                    />

                                    {this.hasFeatures() && (
                                        <FeatureEditor
                                            scope={this.props.scope}
                                            plugin={this.state.plugin}
                                            isNew={this.state.isNew}
                                            doBusyTask={this.doBusyTask}
                                            busy={this.state.busy!}
                                            properties={this.state.model!.template.Properties!}
                                            packages={this.state.model!.template.Packages!}
                                            setProperties={this.setProperties}
                                            setPackages={this.setPackages}
                                            enabledFeatures={(this.state.model!.template.Properties!["Octopus.Action.EnabledFeatures"] as string) || ""}
                                            getFieldError={this.getFieldError}
                                            localNames={this.state.localNames}
                                            errors={this.errors}
                                            expandedByDefault={this.state.isNew}
                                            openFeaturesElement={this.state.isLoaded && this.addFeaturesElement()}
                                        />
                                    )}
                                </TabItem>
                                <TabItem label="Parameters" value="parameters">
                                    <Section>Parameters define variables that are set when the step template is included in a project's deployment process.</Section>
                                    <Section>
                                        <ActionTemplateParameterList
                                            parameters={this.state.model!.template.Parameters}
                                            name="parameter"
                                            excludedControlTypes={[ControlType.WorkerPool]}
                                            editPermission={{
                                                permission: Permission.ActionTemplateEdit,
                                                project: "*",
                                                environment: "*",
                                            }}
                                            onParametersChanged={this.handleParametersChanged}
                                        />
                                    </Section>
                                </TabItem>
                                <TabItem label="Settings" value="settings">
                                    <ExpansionButtons containerKey="settings" errors={this.errors} expandAllOnMount={this.state.isNew} />
                                    <ExpandableFormSection
                                        containerKey="settings"
                                        errorKey="Name"
                                        title="Name"
                                        focusOnExpandAll
                                        summary={this.state.model!.template.Name ? Summary.summary(this.state.model!.template.Name) : Summary.placeholder("Please enter a name for your step template")}
                                        help="A short, memorable, unique name for this step template. Example: Success Notification."
                                    >
                                        <Text value={this.state.model!.template.Name!} onChange={(name) => this.setChildState2("model", "template", { Name: name })} label="Name" validate={required("Please enter a step template name")} />
                                    </ExpandableFormSection>
                                    <PermissionCheck permission={Permission.ActionTemplateEdit}>
                                        <ExpandableFormSection
                                            containerKey="settings"
                                            errorKey="logo"
                                            title="Logo"
                                            summary={this.logoSummary()}
                                            help={this.isBasedOnCommunityTemplate() ? "The logo of a community step template is read only." : "Choose an image to use as a logo"}
                                        >
                                            {this.isBasedOnCommunityTemplate() ? (
                                                <div>
                                                    <Logo url={this.state.model!.template.Links!.Logo} />
                                                    If you want to update it then you have to copy the template first.
                                                </div>
                                            ) : (
                                                <LogoEditor value={this.state.model!.logo} onChange={(logo) => this.setModelState({ logo })} />
                                            )}
                                        </ExpandableFormSection>
                                    </PermissionCheck>
                                    <ExpandableFormSection
                                        containerKey="settings"
                                        errorKey="Description"
                                        title="Description"
                                        summary={this.descriptionSummary()}
                                        help="This summary will be presented to users when selecting the step template for inclusion in a project."
                                    >
                                        <MarkdownEditor value={this.state.model!.template.Description} onChange={(description) => this.setChildState2("model", "template", { Description: description })} label="Description" />
                                    </ExpandableFormSection>
                                </TabItem>
                            </UrlNavigationTabsContainer>
                        </div>
                    </ScriptActionContext.Provider>
                )}
            </FormPaperLayout>
        );
    }

    private handleParametersChanged = (parameters: ActionTemplateParameterResource[]) => {
        this.setState((state) => ({
            model: {
                ...state.model,
                template: {
                    ...state.model!.template,
                    Parameters: parameters,
                },
            },
            localNames: this.toLocalNames(parameters),
        }));
    };

    private setProperties = (properties: ActionProperties, initialise?: boolean) => {
        this.setState(
            (prev) => ({
                model: {
                    ...prev.model,
                    template: {
                        ...prev.model!.template,
                        Properties: {
                            ...prev.model!.template.Properties,
                            ...properties,
                        },
                    },
                },
            }),
            () => {
                if (initialise) {
                    this.initialiseModel();
                }
            }
        );
    };

    private setPackages = (packages: PackageReference[], initialise?: boolean) => {
        this.setState(
            (prev) => ({
                model: {
                    ...prev.model,
                    template: {
                        ...prev.model!.template,
                        Packages: [...packages],
                    },
                },
            }),
            () => {
                if (initialise) {
                    this.initialiseModel();
                }
            }
        );
    };

    private initialiseModel = () => {
        this.setState((prev) => ({
            cleanModel: cloneDeep(prev.model),
        }));
    };

    private logoSummary(): SummaryNode {
        if (!this.state.model!.template.Links || this.state.model!.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model!.logo.file) {
            return Summary.summary(this.state.model!.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.model!.template.Links.Logo} />);
    }

    private descriptionSummary() {
        return this.state.model!.template.Description ? Summary.summary(<Markdown markup={this.state.model!.template.Description} />) : Summary.placeholder("No step template description provided yet");
    }

    private getNewTemplate(): ActionTemplateModel {
        return {
            template: {
                ActionType: this.props.match.params.actionType,
                Properties: {},
                Parameters: [],
                Packages: [],
            },
            logo: {
                file: null!,
                reset: false,
            },
        };
    }

    private async getExistingTemplate(): Promise<ActionTemplateModel> {
        const actionTemplate = await repository.ActionTemplates.get(this.props.match.params.templateId!);
        return {
            template: actionTemplate,
            logo: {
                file: null!,
                reset: false,
            },
        };
    }

    private saveText() {
        return this.state.isNew ? "Step template created" : "Step template updated";
    }

    private overflowActions() {
        const exportAction = OverflowMenuItems.dialogItem("Export", <ExportActionTemplateDialog template={this.state.model!.template} />);
        return !this.state.isNew && !!this.state.model && !!this.state.model.template
            ? [
                  OverflowMenuItems.navItem("Run", routeLinks.library.stepTemplate(this.state.model.template.Id!).run),
                  exportAction,
                  OverflowMenuItems.deleteItemDefault("step template", this.handleDeleteConfirm, { permission: Permission.ActionTemplateDelete }),
                  [
                      OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.template.Id!]), undefined, {
                          permission: Permission.EventView,
                          wildcard: true,
                      }),
                  ],
              ]
            : [exportAction];
    }

    private hasFeatures() {
        return pluginRegistry.hasFeatureForDeploymentAction(this.state.plugin.actionType);
    }

    private addFeaturesElement(): React.ReactElement {
        return (
            <React.Fragment>
                {this.hasFeatures() && (
                    <OpenFeatureDialog
                        scope={this.props.scope!}
                        actionType={this.state.model!.template.ActionType!}
                        properties={this.state.model!.template.Properties!}
                        saveDone={(features) => this.setProperties({ ["Octopus.Action.EnabledFeatures"]: features })}
                    />
                )}
            </React.Fragment>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const resource = await this.saveTemplate(this.state.model!.template as ActionTemplateResource, this.state.model!.logo);
            this.props.onFetchActionTemplate(resource); //refreshes the usage count
            if (this.state.isNew) {
                this.setState({ redirectTo: routeLinks.library.stepTemplate(resource).root });
            }
        });
    };

    private async saveTemplate(template: ActionTemplateResource, logo: LogoEditorSettings) {
        const savedTemplate = await repository.ActionTemplates.save(template);

        await saveLogo(savedTemplate, logo.file!, logo.reset);

        // Important: We need to do a GET here so that the new logo url is retrieved
        const actionTemplate = await repository.ActionTemplates.get(savedTemplate.Id);

        const model: ActionTemplateModel = { template: actionTemplate, logo: { file: null!, reset: false } };

        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });

        return actionTemplate;
    }

    private handleSaveAsCopyClick = async () => {
        this.setState({ showSaveAs: true });
    };

    private onSaveAsCopy = async (newName: string) => {
        this.setState({ showSaveAs: false });
        await this.doBusyTask(async () => {
            const clone = cloneDeep(this.state.model!.template);
            clone.Id = null!;
            clone.Name = newName;
            const newTemplate = await this.saveTemplate(clone as ActionTemplateResource, this.state.model!.logo);
            this.setState({ redirectTo: routeLinks.library.stepTemplate(newTemplate).root });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.ActionTemplates.del(this.state.model!.template as ActionTemplateResource);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null,
                redirectTo: routeLinks.library.stepTemplates.root,
            };
        });
        return true;
    };

    private isBasedOnCommunityTemplate = () => {
        return !!this.state.model!.template.CommunityActionTemplateId;
    };

    private saveButtonLabel = () => {
        return this.isBasedOnCommunityTemplate() ? "Save as copy" : "Save";
    };

    private dataChanged() {
        return !isEqual(this.state.model, this.state.cleanModel);
    }

    private toLocalNames(parameters: ActionTemplateParameterResource[]) {
        return [...parameters].sort((a, b) => a.Name.localeCompare(b.Name)).map((p) => p.Name);
    }

    private isUpdateAvailable() {
        return (this.state.communityTemplate && this.state.model!.template.Version && this.state.model!.template.Version < this.state.communityTemplate.Version)!;
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: ThunkDispatch<GlobalState, void, AnyAction>) => {
    return {
        onFetchActionTemplate: (actionTemplate: ActionTemplateResource) => {
            return dispatch(actionTemplateFetch(actionTemplate));
        },
    };
};

const ActionTemplate = connect<void, GlobalDispatchProps, RouteComponentProps<ActionTemplateParams>>(null, mapGlobalActionDispatchersToProps)(ActionTemplateInternal);

export default ActionTemplate;
