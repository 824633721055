import * as React from "react";
import cn from "classnames";
const styles = require("./style.less");

export enum SectionHeadingType {
    Heading1,
    Heading2,
    Heading3,
    Heading4,
}

interface SectionProps {
    className?: string;
    sectionHeader?: string;
    headingType?: SectionHeadingType;
    bodyClassName?: string;
}

export class Section extends React.Component<SectionProps> {
    render() {
        return (
            <section className={cn(this.props.className, this.props.sectionHeader ? styles.section : styles.sectionNoHeader)}>
                {this.renderSectionHeading()}
                <div className={cn(styles.sectionBody, this.props.bodyClassName)}>{this.props.children}</div>
            </section>
        );
    }

    private renderSectionHeading() {
        if (!this.props.sectionHeader) {
            return null;
        }
        switch (this.props.headingType) {
            case SectionHeadingType.Heading1:
                return <h1 className={styles.sectionHeader}>{this.props.sectionHeader}</h1>;
            case SectionHeadingType.Heading2:
                return <h2 className={styles.sectionHeader}>{this.props.sectionHeader}</h2>;
            case SectionHeadingType.Heading3:
                return <h3 className={styles.sectionHeader}>{this.props.sectionHeader}</h3>;
            default:
                return <h4 className={styles.sectionHeader}>{this.props.sectionHeader}</h4>;
        }
    }
}
