import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab, { TabProps } from "@material-ui/core/Tab";
import routeLinks from "routeLinks";
import { makeStyles } from "@material-ui/core/styles";
import { useRunbookContext } from "../Runbooks/RunbookContext";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { IsNonVcsRunbook, Permission } from "client/resources";
import { useProjectContext } from "areas/projects/context";
import { useOctopusTheme, OctopusTheme } from "components/Theme";
import { RunbookRouteLinks } from "../OperationsRoute";
import { resolveStringPathWithSpaceId } from "../../../../components/Navigation/resolvePathWithSpaceId";

type NavTabProps = TabProps;

interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}

const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({ color: props.octopusTheme.primaryText }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: props.octopusTheme.primary }),
}));

const useTabStyles = makeStyles(() => ({
    tabsContainer: (props: PropsWithOctopusTheme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: props.octopusTheme.paper0,
        borderTopStyle: "solid",
        borderTopWidth: "0.0625rem",
        borderTopColor: props.octopusTheme.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: "0.0625rem",
        borderBottomColor: props.octopusTheme.divider,
    }),
    rootTab: {
        minWidth: "7.5rem", // Needed for DrawerWrapperLayout.
        textTransform: "none",
        fontWeight: "normal",
        letterSpacing: "0",
        fontSize: "0.9125rem",
    },
}));

const useNavTabStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({
        "&:hover": {
            opacity: 1,
            color: props.octopusTheme.primaryDark,
            transition: "backgroundColor 10s",
        },
    }),
}));

const NavTab: React.FC<NavTabProps> = (props) => {
    const octopusTheme = useOctopusTheme();
    const tabStyles = useNavTabStyles({ octopusTheme });
    return <Tab classes={tabStyles} {...props} />;
};

const projectHasTaskViewPermission = (projectId: string) => {
    return isAllowed({
        permission: Permission.TaskView,
        project: projectId,
        wildcard: true,
    });
};

const RunbooksNavigationTabsInternal: React.FC<RouteComponentProps<{ projectSlug: string; spaceId: string; runbookId: string }>> = (props) => {
    const octopusTheme = useOctopusTheme();
    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles({ octopusTheme });

    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();

    const projectLinks = routeLinks.project(props.match.params.projectSlug);
    const tabs: { label: string; link: string; partialLink: string; disabled: boolean }[] = [];

    function pushTab(label: string, generateLink: (runbookLinks: RunbookRouteLinks) => string, disabled: boolean = false) {
        const link = generateLink(projectLinks.operations.runbook(props.match.params.runbookId));
        const partialLink = link.replace(projectLinks.root, "");
        tabs.push({ label, link, partialLink, disabled });
    }

    pushTab("Overview", (links) => links.overview);
    if (projectHasTaskViewPermission(projectContext.state.model.Id)) pushTab("Runs", (links) => links.runslist);
    const runbook = runbookContext.state.runbook;
    if (runbook && IsNonVcsRunbook(runbook)) {
        pushTab("Process", (links) => links.runbookProcess.runbookProcess(runbook.RunbookProcessId).root);
    } else {
        // FIXME: cac-runbook: We don't support linking to a VCS runbook's process yet
        pushTab("Process", (links) => "VCSRunbookProcessNotSupported", true);
    }
    pushTab("Settings", (links) => links.settings);

    const tabThatMatchesUrl = tabs.find((x) => props.location.pathname.includes(x.partialLink));
    const urlOfSelectedTab = tabThatMatchesUrl?.link;

    return (
        <div className={tabStyles.tabsContainer}>
            <Tabs
                value={urlOfSelectedTab}
                classes={classes}
                onChange={(_, val) => {
                    props.history.push(resolveStringPathWithSpaceId(val, props.match.params.spaceId));
                }}
                variant="scrollable"
                scrollButtons="off"
            >
                {tabs.map((x) => (
                    <NavTab key={x.label} label={x.label} value={x.link} disabled={x.disabled} className={tabStyles.rootTab} />
                ))}
            </Tabs>
        </div>
    );
};

const RunbooksNavigationTabs = withRouter(RunbooksNavigationTabsInternal);
export default RunbooksNavigationTabs;
