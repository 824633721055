/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import AreaTitle from "components/AreaTitle/index";
import { repository } from "clientInstance";
import { BuiltInTask } from "../Task/Task";
import routeLinks from "routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect";

interface InterruptionToProjectRedirectState extends DataBaseComponentState {
    redirectTo: string;
}

type InterruptionToProjectRedirectProps = RouteComponentProps<{ interruptionId: string }>;

export default class InterruptionToProjectRedirect extends DataBaseComponent<InterruptionToProjectRedirectProps, InterruptionToProjectRedirectState> {
    constructor(props: InterruptionToProjectRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const interruptionId = this.props.match.params.interruptionId;
            const interruption = await repository.Interruptions.get(interruptionId);
            const task = await repository.Tasks.get(interruption.TaskId);

            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: routeLinks.deployment(task.Arguments.DeploymentId).root });
                return;
            }

            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: routeLinks.runbookRun(task.Arguments.RunbookRunId).root });
                return;
            }
        });
    }

    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={{ pathname: redirectTo }} />;
    }
}
