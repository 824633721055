/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { WorkerPoolResource, WorkerPoolsSummaryResource, TenantResource, Permission } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton } from "components/Button";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import InfrastructureLayout from "../../InfrastructureLayout";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { ActionButtonType } from "components/Button";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import WorkerPoolsSorter from "./WorkerPoolsSorter";
import WorkerPoolSummarySection from "./WorkerPoolSummarySection";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import AddWorkerPoolsDialog from "./AddWorkerPoolsDialog";
import AdvancedFilterLayout, { FilterSection, AdvancedFilterCheckbox } from "components/AdvancedFilterLayout";
import { isEqual } from "lodash";
import { RoleMultiSelect, WorkerPoolMultiSelect, MachineModelHealthStatusMultiSelect, EndpointCommunicationStyleMultiSelect, ShellNameMultiSelect } from "components/MultiSelect";
import { connect } from "react-redux";
import { machineActions } from "../../../reducers/machines";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import { Section } from "components/Section/Section";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import { AdvancedFilterTextInput } from "components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import { QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { WorkerPoolSummaryMachineQuery } from "./WorkerPoolSummaryMachineQuery";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { NoResults } from "components/NoResults/NoResults";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import ConfirmTentacleUpgradePanel from "../../MachinesLayout/ConfirmTentacleUpgradePanel";
import { Dispatch, Action } from "redux";
import FormPage from "components/FormPage/FormPage";
import { defaultWorkerPoolsSummaryFilter, WorkerPoolsSummaryFilter, createWorkerPoolsSummaryArgs, workerPoolsFilterToQuery, workerPoolsQueryToFilter } from "./WorkerPoolsSummaryFilter";
import NumberedPagingBar from "components/PagingBaseComponent/NumberedPagingBar";
import { InfrastructureLayoutBusy } from "../../InfrastructureLayout/InfrastructureLayout";
import { DynamicWorkerType } from "client/resources/workerPoolsSupportedTypesResouce";

export interface WorkerPoolsRouteParams {
    ids: string;
}

interface WorkerPoolLayoutProps extends RouteComponentProps<WorkerPoolsRouteParams> {
    onClearMachine?(): void;
}

class FilterLayout extends AdvancedFilterLayout<WorkerPoolsSummaryFilter> {}
type SimplifiedRouteProps<T> = Omit<RouteComponentProps<T>, "match">;

const WorkerPoolQueryStringFilters = QueryStringFilters.For<WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery>();

const PageSize = 20;

interface WorkerPoolsLayoutInnerProps extends SimplifiedRouteProps<WorkerPoolsRouteParams> {
    initialData: InitialData;
    onClearMachine(): void;
}

interface WorkerPoolLayoutState extends DataBaseComponentState {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    filter: WorkerPoolsSummaryFilter;
    queryFilter?: WorkerPoolsSummaryFilter;
    isSearching?: boolean;
    workerPools: WorkerPoolResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    hasDeploymentTargets: boolean;
    redirectToTaskId?: string;
    redirectToWorkerPoolId?: string;
    currentPageIndex: number;
    workerShellNames: string[];
    workerTypes: DynamicWorkerType[];
}

interface InitialData {
    workerPools: WorkerPoolResource[];
    machineRoles: string[];
    tagIndex: TagIndex;
    hasDeploymentTargets: boolean;
    workerShellNames: string[];
    workerPoolsSummary: WorkerPoolsSummaryResource;
    tenants: TenantResource[];
    workerTypes: DynamicWorkerType[];
}

const Title = "Worker pools";
const WorkerPoolsLayoutFormPage = FormPage<InitialData>();
const WorkerPoolsLayout: React.FC<RouteComponentProps<WorkerPoolsRouteParams>> = (props: RouteComponentProps<WorkerPoolsRouteParams>) => {
    const history = useHistory();
    const location = useLocation();

    return (
        <WorkerPoolsLayoutFormPage
            title={Title}
            load={async () => {
                const defaultWorkerPoolSummaryArgs = createWorkerPoolsSummaryArgs(defaultWorkerPoolsSummaryFilter);
                const workerPools = repository.WorkerPools.all();
                const machineRoles = repository.MachineRoles.all();
                const workerShellNames = repository.WorkerShells.all();
                const workerPoolsSummary = repository.WorkerPools.summary(defaultWorkerPoolSummaryArgs);
                const tenants = repository.Tenants.all();
                const tagIndex = tenantTagsets.getTagIndex();
                const workerTypes = repository.WorkerPools.getDynamicWorkerTypes();
                const machineCount = repository.Machines.list({ take: 0 });

                return {
                    workerPools: await workerPools,
                    machineRoles: await machineRoles,
                    tagIndex: await tagIndex,
                    hasDeploymentTargets: (await machineCount).TotalResults > 0,
                    workerShellNames: await workerShellNames,
                    workerPoolsSummary: await workerPoolsSummary,
                    tenants: await tenants,
                    workerTypes: await workerTypes,
                };
            }}
            renderWhenLoaded={(data) => <ConnectedWorkerPoolsLayout initialData={data} location={location} history={history} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class WorkerPoolsLayoutInternal extends DataBaseComponent<WorkerPoolsLayoutInnerProps, WorkerPoolLayoutState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: WorkerPoolsLayoutInnerProps) {
        super(props);

        this.state = {
            workerPoolsSummary: props.initialData.workerPoolsSummary,
            filter: defaultWorkerPoolsSummaryFilter,
            isSearching: false,
            machineRoles: props.initialData.machineRoles,
            workerPools: props.initialData.workerPools,
            tenants: props.initialData.tenants,
            tagIndex: props.initialData.tagIndex,
            hasDeploymentTargets: false,
            currentPageIndex: 0,
            workerShellNames: props.initialData.workerShellNames,
            workerTypes: props.initialData.workerTypes,
        };
    }

    async UNSAFE_componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        if (this.state.redirectToWorkerPoolId) {
            return <InternalRedirect to={routeLinks.infrastructure.workerPool(this.state.redirectToWorkerPoolId)} push={true} />;
        }

        const actions = [
            this.state.workerPools && this.state.workerPools.length > 0 && (
                <PermissionCheck permission={Permission.WorkerEdit}>
                    <NavigationButton href={routeLinks.infrastructure.workerMachines.new()} label="Add worker" />
                </PermissionCheck>
            ),
            <PermissionCheck permission={Permission.WorkerEdit}>
                <OpenDialogButton label="Add Worker Pool" type={ActionButtonType.Primary}>
                    <AddWorkerPoolsDialog
                        saveDone={async (workerPool) => {
                            this.setState({ redirectToWorkerPoolId: workerPool.Id });
                        }}
                    />
                </OpenDialogButton>
            </PermissionCheck>,
            this.state.workerPools && this.state.workerPools.length > 0 && (
                <PermissionCheck permission={Permission.WorkerView}>
                    <OverflowMenu menuItems={[OverflowMenuItems.dialogItem("Reorder", <WorkerPoolsSorter saveDone={this.refreshWorkerPoolSummaryData} />)]} />
                </PermissionCheck>
            ),
        ];

        const actionSection = <ActionList actions={actions} />;

        let workerPoolExpanders: React.ReactNode = null;
        const workerPoolSummaries = this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries;
        if (workerPoolSummaries) {
            const start = this.state.currentPageIndex === 0 ? 0 : this.state.currentPageIndex * PageSize;
            const end = start + PageSize;
            workerPoolExpanders = workerPoolSummaries.slice(start, end).map((workerPoolsSummary) => {
                return (
                    <WorkerPoolSummarySection
                        key={workerPoolsSummary.WorkerPool.Id}
                        workerPoolSummary={workerPoolsSummary}
                        workerTypes={this.state.workerTypes}
                        filter={this.state.filter}
                        isFiltering={this.isFiltering()}
                        tenants={this.props.initialData.tenants}
                        tagIndex={this.state.tagIndex}
                    />
                );
            });
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            workerPoolExpanders = (
                <Section>
                    <NoResults />
                </Section>
            );
        }

        const filterSections: FilterSection[] = [
            {
                render: (
                    <div>
                        <AdvancedFilterCheckbox
                            label="Disabled only"
                            value={this.state.filter.isDisabled}
                            onChange={(x) => {
                                this.setFilterState({ isDisabled: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <AdvancedFilterCheckbox
                            label="Hide empty worker pools"
                            value={this.state.filter.hideEmptyWorkerPools}
                            onChange={(x) => {
                                this.setFilterState({ hideEmptyWorkerPools: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <AdvancedFilterTextInput
                            fieldName={"worker"}
                            value={this.state.filter.machinePartialName || ""}
                            onChange={(x) => {
                                this.setFilterState({ machinePartialName: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <WorkerPoolMultiSelect
                            items={this.state.workerPools ? this.state.workerPools : []}
                            value={this.state.filter.workerPoolIds}
                            onChange={(x) => {
                                this.setFilterState({ workerPoolIds: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <RoleMultiSelect
                            items={this.state.machineRoles ? this.state.machineRoles : []}
                            value={this.state.filter.roles}
                            onChange={(x) => {
                                this.setFilterState({ roles: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <MachineModelHealthStatusMultiSelect
                            items={this.machineHealthStatuses}
                            value={this.state.filter.healthStatuses}
                            onChange={(x) => {
                                this.setFilterState({ healthStatuses: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <EndpointCommunicationStyleMultiSelect
                            items={this.communicationStyles}
                            value={this.state.filter.commStyles}
                            onChange={(x) => {
                                this.setFilterState({ commStyles: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <ShellNameMultiSelect
                            items={this.state.workerShellNames ? this.state.workerShellNames : []}
                            value={this.state.filter.shellNames}
                            onChange={(x) => {
                                this.setFilterState({ shellNames: x }, this.onFilterChange);
                            }}
                        />
                    </div>
                ),
            },
        ];

        const tentacleUpgradesRequiredWarning = workerPoolSummaries && workerPoolSummaries.length > 0 && workerPoolSummaries.find((x) => x.TentacleUpgradesRequired === true) && (
            <PermissionCheck permission={Permission.WorkerEdit}>
                <ConfirmTentacleUpgradePanel
                    doBusyTask={this.doBusyTask}
                    calloutDescriptionElement={<p>One or more workers are running old versions of the Tentacle agent and can be upgraded.</p>}
                    onTentacleUpgradeComplete={(taskId) => {
                        this.setState({ redirectToTaskId: taskId });
                    }}
                />
            </PermissionCheck>
        );

        return (
            <InfrastructureLayout {...this.props}>
                <WorkerPoolQueryStringFilters
                    filter={this.state.filter}
                    getQuery={workerPoolsFilterToQuery}
                    getFilter={workerPoolsQueryToFilter}
                    onFilterChange={(filter) => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())}
                />
                <PaperLayout busy={this.state.busy} errors={this.errors} title={Title} sectionControl={actionSection}>
                    {workerPoolSummaries && (
                        <div>
                            {tentacleUpgradesRequiredWarning}
                            <FilterLayout
                                filterSections={filterSections}
                                filter={this.state.filter}
                                queryFilter={this.state.queryFilter}
                                defaultFilter={defaultWorkerPoolsSummaryFilter}
                                initiallyShowFilter={this.isFiltering()}
                                additionalHeaderFilters={[
                                    <FilterSearchBox
                                        placeholder={"Search worker pools..."}
                                        value={this.state.filter.partialName}
                                        onChange={(x) => {
                                            this.setFilterState({ partialName: x }, () => {
                                                this.onFilterChange();
                                            });
                                        }}
                                        autoFocus={true}
                                    />,
                                ]}
                                onFilterReset={(filter: WorkerPoolsSummaryFilter) => {
                                    this.setState({ filter }, () => {
                                        this.onFilterChange();
                                        const location = { ...this.props.history, search: "" };
                                        this.props.history.replace(location);
                                    });
                                }}
                                renderContent={() => (
                                    <div>
                                        <ExpansionButtons />
                                        {workerPoolExpanders}
                                        {this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length > 0 && (
                                            <NumberedPagingBar
                                                currentPageIndex={this.state.currentPageIndex}
                                                totalItems={this.state.workerPoolsSummary.WorkerPoolSummaries.length}
                                                pageSize={PageSize}
                                                onPageSelected={(_, currentPageIndex) => this.setState({ currentPageIndex })}
                                            />
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    )}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    private setFilterState<K extends keyof WorkerPoolsSummaryFilter>(state: Pick<WorkerPoolsSummaryFilter, K>, callback?: () => void) {
        this.setState(
            (prev) => ({
                filter: { ...(prev.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private isFiltering() {
        return !isEqual(this.state.filter, defaultWorkerPoolsSummaryFilter);
    }

    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshWorkerPoolSummaryData();
            this.setState({ isSearching: false });
        });
    }

    private refreshWorkerPoolSummaryData = async () => {
        await this.doBusyTask(() => this.loadWorkerPoolSummaries());
    };

    private async loadWorkerPoolSummaries() {
        const workerPoolSummariesArgs = createWorkerPoolsSummaryArgs(this.state.filter);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.WorkerPools.summary(workerPoolSummariesArgs), (response) => {
            this.setState({
                workerPoolsSummary: response as WorkerPoolsSummaryResource,
            });
        });
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineActions.machineCleared());
        },
    };
};

const ConnectedWorkerPoolsLayout = connect(null, mapGlobalActionDispatchersToProps)(WorkerPoolsLayoutInternal);

export default WorkerPoolsLayout;
