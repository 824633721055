import * as React from "react";
import { SFC } from "react";
import StringHelper from "../../utils/StringHelper/index";
import { PagingControlButton } from "components/Button/index";
const styles = require("../List/style.less");
import BusyIndicator from "../BusyIndicator/BusyIndicator";

interface NumberedPagingBarProps {
    totalItems: number;
    currentPageIndex: number;
    pageSize: number;
    loadingMore?: boolean;

    onPageSelected(skip: number, p: number): Promise<void> | void;
}

interface Page {
    index: number;
    isActive: boolean;
    skip: number;
    number: string;
}

const NumberedPagingBar: SFC<NumberedPagingBarProps> = (props) => {
    if (props.totalItems <= props.pageSize) {
        return null;
    }

    const currentPageIndex = props.currentPageIndex;
    const totalPages = props.totalItems === 0 ? 1 : Math.ceil(props.totalItems / props.pageSize);

    const pages: Page[] = [];

    function addPage(i: number, text: string) {
        const page = {
            index: i,
            isActive: currentPageIndex === i,
            skip: i * props.pageSize,
            number: text,
        };
        pages.push(page);
    }

    const pagesToShow = 16;
    const halfPagesToShow = 8;

    let isStartCollapsed = false;
    let isEndCollapsed = false;

    if (totalPages > 0) {
        addPage(0, "<<");
    }
    for (let i = 0; i < totalPages; i++) {
        const skip = !(totalPages < pagesToShow || (i - currentPageIndex < halfPagesToShow && i - currentPageIndex > -halfPagesToShow));
        if (skip && i < currentPageIndex && !isStartCollapsed) {
            addPage(Math.max(currentPageIndex - pagesToShow, 0), StringHelper.ellipsis);
            isStartCollapsed = true;
        }
        if (!skip) {
            addPage(i, String(i + 1));
        }
        if (skip && i > currentPageIndex && !isEndCollapsed) {
            addPage(Math.min(currentPageIndex + pagesToShow, totalPages - 1), StringHelper.ellipsis);
            isEndCollapsed = true;
        }
    }
    if (totalPages > 0) {
        addPage(totalPages - 1, ">>");
    }

    const pagingComponents = pages.map((page, i) => <PagingControlButton key={i} label={page.number} isActive={page.isActive} onPageSelected={() => props.onPageSelected(page.skip, page.index)} />);

    const classes = props.loadingMore ? styles.pagingControlContainer + " " + styles.disablePagingEvents : styles.pagingControlContainer;
    return (
        <React.Fragment>
            {totalPages > 1 && (
                <div className={classes}>
                    <div>{pagingComponents} </div>
                    {props.loadingMore && <BusyIndicator show={true} />}
                </div>
            )}
        </React.Fragment>
    );
};

NumberedPagingBar.displayName = "NumberedPagingBar";

export default NumberedPagingBar;
