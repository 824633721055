/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { required } from "components/form";
import Text from "components/form/Text/Text";
import { repository } from "clientInstance";
import { NewTaskResource, TaskName, TaskResource } from "client/resources/taskResource";
import { TaskState } from "client/resources/taskState";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
import StringHelper from "utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";

interface SendTestEmailState extends DataBaseComponentState {
    testEmailAddress?: string;
    redirectToTaskId?: string;
}

export default class SendTestEmail extends DataBaseComponent<{}, SendTestEmailState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={{ pathname: routeLinks.task(this.state.redirectToTaskId).root }} push={true} />;
        }

        const looksLikeAnEmail = (email: string | null) => !StringHelper.isNullOrWhiteSpace(email) && email!.indexOf("@") !== -1;

        return (
            <OkDialogLayout title="Test Email" busy={this.state.busy} errors={this.errors} okButtonDisabled={!looksLikeAnEmail(this.state.testEmailAddress!)} onOkClick={() => this.sendTestEmail()}>
                <p>Who would you like to send the test email to?</p>
                <div>
                    <Text
                        value={this.state.testEmailAddress!}
                        onChange={(testEmailAddress) => this.setState({ testEmailAddress })}
                        label="Test email"
                        placeholder="test@example.com"
                        validate={required("Please enter an email address")}
                        autoFocus={true}
                    />
                </div>
            </OkDialogLayout>
        );
    }

    private async sendTestEmail() {
        const task = await repository.Tasks.createSendTestEmailTask(this.state.testEmailAddress!);
        this.setState({
            redirectToTaskId: task.Id,
        });
        return true;
    }
}
