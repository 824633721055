/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { SFC } from "react";
import { DeploymentMachineInfo, DeploymentStepsDetails } from "../Preview";
import { EnvironmentResource } from "client/resources/environmentResource";
import { TenantResource } from "client/resources/tenantResource";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableHeader } from "components/DataTable/DataTableHeader";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableHeaderColumn } from "components/DataTable/DataTableHeaderColumn";
import DeploymentResultItem from "./DeploymentResultItem";
import * as _ from "lodash";
import { DeploymentRequestModel } from "../deploymentRequestModel";
import Form from "client/resources/form";
import { IProcessResource } from "client/resources/deploymentProcessResource";
import { DeploymentPreviewBaseResource, isDeploymentPreviewResource, ReleaseChanges } from "client/resources";
import { DeploymentModelType } from "areas/projects/components/Runbooks/RunbookRunNowLayout";

interface DeploymentResultListProps {
    deployments: DeploymentRequestModel[];
    stepActionIdsToSkip: string[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    promptVariableForm: Form;
    tenantsWithMissingVariables: string[];
    process: IProcessResource;
    deploymentsAndSteps: DeploymentStepsDetails[];
    modelType: DeploymentModelType;
    isExpandedByDefault?: boolean;
    getDeploymentPreview: (environmentId: string, tenantId: string) => DeploymentPreviewBaseResource | undefined;
    onIncludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onExcludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onAllTargetsSelected(deployment: DeploymentMachineInfo): void;
}

const DeploymentResults: SFC<DeploymentResultListProps> = (props) => {
    const isTenanted = _.some(props.deployments, (deployment) => deployment.tenantId);

    const renderHeaderRow = () => {
        return (
            <DataTableRow>
                <DataTableHeaderColumn>{isTenanted ? "Tenant" : "Environment"}</DataTableHeaderColumn>
                <DataTableHeaderColumn>Current Version</DataTableHeaderColumn>
                <DataTableHeaderColumn>{props.modelType === DeploymentModelType.Deployment ? "Deployment Process" : "Runbook Process"}</DataTableHeaderColumn>
                <DataTableHeaderColumn>Targets</DataTableHeaderColumn>
                <DataTableHeaderColumn>Target Status</DataTableHeaderColumn>
            </DataTableRow>
        );
    };

    const renderDeploymentRows = () => {
        if (props.deploymentsAndSteps) {
            return props.deploymentsAndSteps.map((d) => {
                const preview = props.getDeploymentPreview(d.deployment.environmentId, d.deployment.tenantId!);
                let releaseChanges: ReleaseChanges[] = [];
                let changesMarkdown = null;
                if (preview && isDeploymentPreviewResource(preview)) {
                    releaseChanges = preview.Changes;
                    changesMarkdown = preview ? preview.ChangesMarkdown : null;
                }
                return (
                    <DeploymentResultItem
                        isMissingVariable={props.tenantsWithMissingVariables.indexOf(d.deployment.tenantId!) !== -1}
                        key={d.deployment.tenantId ? d.deployment.tenantId : d.deployment.environmentId}
                        deployment={d.deployment}
                        tenant={d.deployment.tenantId ? props.tenants.find((t) => t.Id === d.deployment.tenantId) : null!}
                        stepsForSelectedDeployment={d.stepsForSelectedDeployment}
                        actions={d.actions}
                        environment={props.environments.find((e) => e.Id === d.deployment.environmentId)!}
                        releaseChanges={releaseChanges}
                        changesMarkdown={changesMarkdown!}
                        isExpandedByDefault={props.isExpandedByDefault}
                        {...props}
                    />
                );
            });
        }
    };
    return (
        <DataTable>
            <DataTableHeader>{renderHeaderRow()}</DataTableHeader>
            {renderDeploymentRows()}
        </DataTable>
    );
};
DeploymentResults.displayName = "DeploymentResults";

export default DeploymentResults;
