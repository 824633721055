import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonLibraryScriptModuleOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ScriptModules">Script modules</ExternalLink> allow you to create collections of language specific functions that can be used in deployment processes across multiple projects.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonLibraryScriptModuleTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ScriptModules">Script Modules</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
