/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources/permission";
import Text from "components/form/Text/Text";
import { ReleaseResource } from "client/resources/releaseResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DialogLayout, DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import { required } from "components/form/Validators";

interface PreventProgressionProps {
    defects: any;
    release: ReleaseResource;

    onPreventProgressionClicked(newDefectDescription: string): void;
}

interface PreventProgressionState extends DataBaseComponentState {
    newDefectDescription: string;
    isBusy: boolean;
}

class PreventProgressionInternal extends DataBaseComponent<PreventProgressionProps & DialogLayoutDispatchProps, PreventProgressionState> {
    constructor(props: PreventProgressionProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {
            newDefectDescription: "",
            isBusy: false,
        };
    }

    render() {
        const actions = [];
        actions.push(<ActionButton label="Cancel" key="CancelBlock" onClick={() => this.props.close()} />);
        actions.push(
            <ActionButton
                label="Prevent progression"
                key="Block"
                type={ActionButtonType.Primary}
                disabled={this.state.isBusy || this.state.newDefectDescription === ""}
                onClick={() =>
                    this.doBusyTask(async () => {
                        if (this.state.newDefectDescription === "") {
                            return;
                        }
                        this.setState({ isBusy: true });
                        this.props.onPreventProgressionClicked(this.state.newDefectDescription);
                        this.setState({ isBusy: false });
                        this.props.close();
                    })
                }
            />
        );
        return (
            <DialogLayout title="Prevent progression" busy={this.state.busy} errors={this.errors} key="PreventProgression" closeDialog={this.props.close} actions={actions}>
                {this.props.defects && !this.props.defects.releaseHasDefect && (
                    <PermissionCheck permission={Permission.DefectReport} project={this.props.release.ProjectId}>
                        <Text
                            placeholder="Describe the issue with this deployment"
                            multiline={true}
                            validate={required("Please describe the issue with this deployment")}
                            value={this.state.newDefectDescription}
                            onChange={(val) => this.setState({ newDefectDescription: val })}
                        />
                    </PermissionCheck>
                )}
            </DialogLayout>
        );
    }
}

const PreventProgression = DialogLayoutConnect.to(PreventProgressionInternal);
export default PreventProgression;
