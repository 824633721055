import { Reducer, combineReducers } from "redux";
import { createAction, getType, ActionType } from "typesafe-actions";

export const machineActions = {
    machineFetched: createAction("MACHINE_FETCHED", (resolve) => (machine: CurrentMachineState) => resolve(machine)),
    machineSaved: createAction("MACHINE_SAVED", (resolve) => (machine: CurrentMachineState) => resolve(machine)),
    machineCleared: createAction("MACHINE_CLEARED"),
};

type MachineAction = ActionType<typeof machineActions>;

export interface MachinesState {
    current: CurrentMachineState | null;
}

export interface CurrentMachineState {
    id: string;
    name: string;
    machineIconFilename: string;
    machineType: string;
    isDisabled: boolean;
}

const currentMachineReducer: Reducer<CurrentMachineState | null> = (state = null, action: MachineAction) => {
    switch (action.type) {
        case getType(machineActions.machineFetched):
        case getType(machineActions.machineSaved):
            return action.payload;
        case getType(machineActions.machineCleared):
            return null;
        default:
            return state;
    }
};

export default combineReducers<MachinesState>({ current: currentMachineReducer });
