/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { VariableLookupText } from "../../form/VariableLookupText";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ProjectResource } from "client/resources";
import { repository } from "clientInstance";
import { DoesNotExistOperator, ExistsOperator, GreaterThanOperator, InOperator, LessThanOperator, NodeAffinityDetails, NotInOperator, PreferredAffinity, RequiredAffinity } from "./kubernetesDeployContainersAction";
import Note from "../../form/Note/Note";
import { KubernetesLabelKeyRegex } from "components/Actions/kubernetes/kubernetesValidation";
import { ExtendedKeyValueEditList } from "components/EditList/ExtendedKeyValueEditList";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import isBound from "components/form/BoundField/isBound";
import * as _ from "lodash";

interface NodeAffinityState extends DataBaseComponentState {
    nodeAffinityDetails: NodeAffinityDetails;
    project?: ProjectResource;
}

interface NodeAffinityProps {
    nodeAffinityDetails: NodeAffinityDetails;
    localNames: string[];
    projectId: string;
    onAdd(Binding: NodeAffinityDetails): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class NodeAffinityDialog extends DataBaseComponent<NodeAffinityProps, NodeAffinityState> {
    constructor(props: NodeAffinityProps) {
        super(props);
        this.state = {
            nodeAffinityDetails: null!,
            project: undefined,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : undefined;
            const nodeAffinityDetails = { ...this.props.nodeAffinityDetails };

            this.setState({
                nodeAffinityDetails,
                project,
            });
        });
    }

    save = () => {
        let valid = true;
        const binding = this.state.nodeAffinityDetails;

        if (!binding.Type || !binding.Type.trim()) {
            this.setValidationErrors("The node affinity rule type must be defined.", { NodeAffinityType: "The node affinity rule type must be defined." });
            valid = false;
        }

        if (binding.Type === PreferredAffinity && (!binding.Weight || (!isBound(binding.Weight) && (isNaN(parseInt(binding.Weight, 10)) || parseInt(binding.Weight, 10) < 1 || parseInt(binding.Weight, 10) > 100)))) {
            this.setValidationErrors("The node affinity rule weight must be defined as a number between 1 and 100.", { NodeAffinityWeight: "The node affinity rule weight must be defined as a number between 1 and 100." });
            valid = false;
        }

        if (binding.InMatch && !binding.InMatch.every((i) => !!i.key && !!i.key.trim() && !!i.value && !!i.value.trim() && !!i.option && !!i.option.trim())) {
            this.setValidationErrors('All "In", "Not in", "Greater than" and "Less than" rules must define the label key, operator and label values.', {
                NodeAffinityInRules: 'All "In", "Not in", "Greater than" and "Less than" rules must define the label key, operator and label values.',
            });
            valid = false;
        }

        if (binding.ExistMatch && !binding.ExistMatch.every((i) => !!i.key && !!i.key.trim() && !!i.value && !!i.value.trim())) {
            this.setValidationErrors('All "Exists" and "Does not exist" rules must define the label key and operator.', { NodeAffinityInRules: 'All "Exists" and "Does not exist" rules must define the label key and operator.' });
            valid = false;
        }

        if (
            valid &&
            binding.InMatch &&
            !binding.InMatch.every(
                (i) =>
                    isBound(i.key) ||
                    i.key
                        .trim()
                        .split("/")
                        .every((l) => !!KubernetesLabelKeyRegex.exec(l))
            )
        ) {
            this.setValidationErrors('All "In", "Not in", "Greater than" and "Less than" rule label keys must consist of alphanumeric characters, \'-\', \'_\' or \'.\', and must start and end with an alphanumeric character.', {
                NodeAffinityInRules: 'All "In", "Not in", "Greater than" and "Less than" rule label keys must consist of alphanumeric characters, \'-\', \'_\' or \'.\', and must start and end with an alphanumeric character.',
            });
            valid = false;
        }

        if (
            valid &&
            binding.ExistMatch &&
            !binding.ExistMatch.every(
                (i) =>
                    isBound(i.key) ||
                    i.key
                        .trim()
                        .split("/")
                        .every((l) => !!KubernetesLabelKeyRegex.exec(l))
            )
        ) {
            this.setValidationErrors("All \"Exists\" and \"Does not exist\" rule label keys must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character.", {
                NodeAffinityInRules: "All \"Exists\" and \"Does not exist\" rule label keys must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character.",
            });
            valid = false;
        }

        if (!((binding.ExistMatch && binding.ExistMatch.length !== 0) || (binding.InMatch && binding.InMatch.length !== 0))) {
            this.setValidationErrors("At least 1 rule must be defined.", { NodeAffinityInRules: "At least 1 rule must be defined." });
            valid = false;
        }

        if (valid) {
            return this.props.onAdd(binding);
        }

        return valid;
    };

    render() {
        return (
            <OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Define Node Affinity Rule"}>
                {this.state.nodeAffinityDetails && (
                    <div>
                        <RadioButtonGroup
                            value={this.state.nodeAffinityDetails.Type}
                            onChange={(Type: string) => {
                                this.setNodeAffinityState({ Type });
                                this.repositionDialog();
                            }}
                            error={this.getFieldError("NodeAffinityType")}
                        >
                            <RadioButton value={RequiredAffinity} label={RequiredAffinity} />
                            <RadioButton value={PreferredAffinity} label={PreferredAffinity} />
                        </RadioButtonGroup>
                        <Note>All required affinity rules must be satisfied for the node to be deployed. Preferred affinity rules will attempt to be satisfied, but if not the pod will still be deployed.</Note>
                        {this.state.nodeAffinityDetails.Type === PreferredAffinity && (
                            <div>
                                <VariableLookupText
                                    label={"Weight"}
                                    localNames={this.props.localNames}
                                    error={this.getFieldError("NodeAffinityWeight")}
                                    value={this.state.nodeAffinityDetails.Weight}
                                    onChange={(Weight) => this.setNodeAffinityState({ Weight })}
                                />
                                <Note>An integer value between 1 and 100 defining the weight of the affinity rule.</Note>
                            </div>
                        )}
                        <ExtendedKeyValueEditList
                            items={() => (_.isArray(this.state.nodeAffinityDetails.InMatch) ? this.state.nodeAffinityDetails.InMatch : [])}
                            name={"In or Comparision Rule"}
                            onChange={(InMatch) => this.setNodeAffinityState({ InMatch })}
                            keyLabel="Label key"
                            valueLabel="Operation"
                            valueValues={[
                                { text: "In", value: InOperator },
                                { text: "Not in", value: NotInOperator },
                                { text: "Greater than", value: GreaterThanOperator },
                                { text: "Less than", value: LessThanOperator },
                            ]}
                            optionLabel="Label values"
                            optionHintText="Comma separated label values"
                            hideBindOnKey={false}
                            projectId={this.props.projectId}
                            addToTop={true}
                            onAdd={this.repositionDialog}
                        />
                        <ExtendedKeyValueEditList
                            items={() => (_.isArray(this.state.nodeAffinityDetails.ExistMatch) ? this.state.nodeAffinityDetails.ExistMatch : [])}
                            name={"Exists Rule"}
                            onChange={(ExistMatch) => this.setNodeAffinityState({ ExistMatch })}
                            keyLabel="Label key"
                            valueLabel="Operation"
                            valueValues={[
                                { text: "Exists", value: ExistsOperator },
                                { text: "Does not exist", value: DoesNotExistOperator },
                            ]}
                            hideBindOnKey={false}
                            projectId={this.props.projectId}
                            addToTop={true}
                            onAdd={this.repositionDialog}
                        />
                    </div>
                )}
            </OkDialogLayout>
        );
    }

    protected getFieldError = (fieldName: string) => {
        if (this.errors && this.errors.fieldErrors) {
            const found = Object.keys(this.errors.fieldErrors).find((k) => k.toLowerCase() === fieldName.toLowerCase());
            if (found) {
                return this.errors.fieldErrors[found];
            }
            const foundPartialMatch = Object.keys(this.errors.fieldErrors).find((k) => k.endsWith("." + fieldName));
            if (foundPartialMatch) {
                return this.errors.fieldErrors[foundPartialMatch];
            }
        }
        return "";
    };

    private setNodeAffinityState<K extends keyof NodeAffinityDetails>(state: Pick<NodeAffinityDetails, K>, callback?: () => void) {
        this.setChildState1("nodeAffinityDetails", state, callback);
    }

    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
    }
}

export default NodeAffinityDialog;
