import * as React from "react";
import PageTitleHelper from "utils/PageTitleHelper";
import AreaTitle from "components/AreaTitle";
import routeLinks from "../../routeLinks";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import Card from "components/Card";
import { StarFish } from "components/Images/Images/SignIn/StarFish";
import Section from "components/Section";
import InternalLink from "components/Navigation/InternalLink";
import { AzureCloudRegion } from "components/Images/Images/MachineSettings/AzureCloudRegion";
import { Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./UxGuide.less");

//TODO: design - replace these with representative icons.
export const UxGuidelinesLogo = <StarFish width="70px" />;
export const UxPatternsLogo = <AzureCloudRegion width="70px" />;

export interface UxGuideCardProps {
    logo: React.ReactElement<{}>;
    header: React.ReactNode;
    description: React.ReactNode;
    buttonLabel?: string;
}

export const UxGuideCard: React.SFC<UxGuideCardProps> = (props) => <Card logo={<div className={styles.logo}>{props.logo}</div>} className={styles.card} header={props.header} content={<div className={styles.description}>{props.description}</div>} />;

const UxGuide: React.FC = () => {
    PageTitleHelper.setRootPageTitle();
    return (
        <div>
            <AreaTitle link={routeLinks.uxGuide.root} title="UX Guide" />
            <DrawerWrapperLayout>
                <Section>
                    <p>Welcome to UX at Octopus Deploy :)</p>
                    <Note>
                        Here you can get some guidelines and motivation to help you get into the headspace of great UX. For more information and discussion, head over to{" "}
                        <ExternalLink href={"https://octopusdeploy.slack.com/archives/C80H394RK"}>#topic-ux</ExternalLink>.
                    </Note>
                    <div className={styles.cardGroup}>
                        <InternalLink to={routeLinks.uxGuide.guidelines.overview}>
                            <UxGuideCard logo={UxGuidelinesLogo} header={"Guidelines"} description="Learn the guidelines used to maintain a high standard of UX" />
                        </InternalLink>
                        {/* TODO: UX - Introduce patterns as they become available */}
                        {/* <InternalLink to={routeLinks.uxGuide.patterns.overview}>
                            <UxGuideCard logo={UxPatternsLogo} header={"Patterns"} description="Patterns for great UX and why they matter to customers" />
                        </InternalLink> */}
                    </div>
                </Section>
            </DrawerWrapperLayout>
        </div>
    );
};

export default UxGuide;
