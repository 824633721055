import * as React from "react";
import { NonVcsRunbookResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { MissingChip, RunbookChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface RunbookMultiSelectProps extends FormFieldProps<string[]> {
    items: NonVcsRunbookResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const RunbookTypedMultiSelect = MultiSelect<NonVcsRunbookResource>();

const RunbookMultiSelect: React.FC<RunbookMultiSelectProps> = (props) => {
    const chipRenderer = (r: NonVcsRunbookResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <RunbookChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} runbook={item} />}
                renderFallback={<MissingChip type={ChipIcon.Runbook} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };
    return <RunbookTypedMultiSelect fieldName="runbooks" renderChip={chipRenderer} {...props} />;
};

export default RunbookMultiSelect;
