/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { repository, client } from "clientInstance";
import { required, Text, RadioButtonGroup, RadioButton, MarkdownEditor } from "components/form";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { SpaceResource } from "../../../../client/resources";
import { Note } from "components/form";
import { SpaceChip, spaceChipList } from "components/Chips";

interface AddTeamProps {
    currentSpace: SpaceResource;
    onTeamCreated(id: string): void;
}

interface AddTeamState extends DataBaseComponentState {
    name: string;
    spaceId: string | null;
    description: string;
}

export default class AddTeam extends DataBaseComponent<AddTeamProps, AddTeamState> {
    constructor(props: AddTeamProps) {
        super(props);
        this.state = {
            name: "",
            spaceId: props.currentSpace ? props.currentSpace.Id : null,
            description: "",
        };
    }

    save() {
        return this.doBusyTask(async () => {
            const result = await repository.Teams.create(
                {
                    Id: null!,
                    Name: this.state.name,
                    SpaceId: this.state.spaceId,
                    ExternalSecurityGroups: [],
                    MemberUserIds: [],
                    CanBeDeleted: true,
                    CanBeRenamed: true,
                    CanChangeMembers: true,
                    CanChangeRoles: true,
                    Links: null!,
                    Description: this.state.description,
                },
                {}
            );
            this.props.onTeamCreated(result.Id);
        });
    }

    render() {
        const canSeeSpace = !!client.spaceId;
        return (
            <SaveDialogLayout title={canSeeSpace ? "Add New Team" : "Add New System Team"} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                <Text label="New team name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a team name")} autoFocus={true} />
                <MarkdownEditor label="Team description" value={this.state.description} onChange={this.handleDescriptionChanged} />
                {canSeeSpace ? (
                    <div>
                        <h4>Make this team accessible in this space or all spaces.</h4>
                        <RadioButtonGroup value={this.state.spaceId as string} onChange={(x: string) => this.setState({ spaceId: x })}>
                            <RadioButton value={this.props.currentSpace.Id} label={<span>Accessible in the {<SpaceChip space={this.props.currentSpace} />} space only</span>} />
                            <RadioButton value={null} label="Accessible in all spaces (system team)" />
                        </RadioButtonGroup>
                    </div>
                ) : (
                    <Note>This team will be accessible in all Spaces</Note>
                )}
            </SaveDialogLayout>
        );
    }

    private handleDescriptionChanged = async (description: string) => {
        // in dobusy so the dialog resizes if the markdowneditor grows
        await this.doBusyTask(async () => {
            this.setState({ description });
        });
    };
}
