import * as React from "react";
import { RenderRegistrationCardProps } from "./endpointRegistry";
import { CustomDialog } from "components/Dialog/CustomDialog";
import { CustomInfoDialogLayout, SmallDialogFrame } from "components/DialogLayout/Custom";
import { InformationalEndpointCard, EndpointCardDetailProps } from "./EndpointCard";

export class InfoRegistrationCards {
    static basic(renderView: (props: RenderRegistrationCardProps) => React.ReactElement, getCardProps: (props: RenderRegistrationCardProps) => EndpointCardDetailProps, additionalActions?: (props: RenderRegistrationCardProps) => React.ReactElement) {
        return (renderProps: RenderRegistrationCardProps) => {
            const registration = renderProps.registration;

            return (
                <InformationalEndpointCard
                    {...getCardProps(renderProps)}
                    renderDialog={({ closeDialog, open }) => (
                        <CustomDialog
                            open={open}
                            close={closeDialog}
                            render={(dialogProps) => (
                                <CustomInfoDialogLayout {...dialogProps} title={registration && registration.name} frame={SmallDialogFrame} additionalActions={additionalActions ? additionalActions(renderProps) : null}>
                                    {renderView(renderProps)}
                                </CustomInfoDialogLayout>
                            )}
                        />
                    )}
                />
            );
        };
    }
}
