/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { sortBy } from "lodash";
import FilterSearchBox from "components/FilterSearchBox";
import styles = require("./spaceSwitcher.less");
import { Section } from "components/Section/Section";
import routeLinks from "../../routeLinks";
import { SpaceResource, LicenseStatusResource, Permission } from "client/resources";
import VirtualListWithKeyboard from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import Logo from "components/Logo/Logo";
import Popover from "components/Popover/Popover";
import BaseComponent from "../BaseComponent";
import SwitcherLink from "./SwitcherLink";
import ExternalLink from "../Navigation/ExternalLink";
import StringHelper from "utils/StringHelper/StringHelper";
import { Note } from "components/form";
import ActionButton, { ActionButtonType } from "components/Button";
import InternalRedirect from "../Navigation/InternalRedirect";
import { FocusableComponent } from "../VirtualListWithKeyboard/FocusableComponent";
import isWithinLicenceLimit from "areas/configuration/components/License/isWithinLicenceLimit";
import cn from "classnames";
import { isSpaceNotFound, SpaceContext } from "../StandardLayout/SpaceLoader";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";

interface SpaceSwitcherProps {
    spaces: SpaceResource[];
    licenseStatus?: LicenseStatusResource;
    open: boolean;
    onRequestClose: () => void;
    onUpgradeSpacesDialogRequested: () => void;
    anchorEl: HTMLElement;
    selected: SpaceResource | undefined;
    spaceContext: SpaceContext;
}

interface SpaceSwitcherState {
    filter: string;
    redirect: boolean;
}

const VirtualList = VirtualListWithKeyboard<SpaceResource>();
const MaxDescriptionCharactersCount = 70;

const SpaceRow: React.SFC<{ space: SpaceResource; isSelected: boolean }> = (props) => (
    <div className={cn(styles.rowContainer, props.isSelected ? styles.selectedIndicator : null)}>
        <div className={styles.rowLogo}>
            <Logo size="2rem" url={props.space.Links.Logo} />
        </div>
        <div className={styles.rowTextContainer}>
            <div className={styles.rowTextTitle}>{props.space.Name}</div>
            {props.space.Description && (
                <div className={styles.rowTextDescription}>{props.space.Description.length > MaxDescriptionCharactersCount ? props.space.Description.substr(0, MaxDescriptionCharactersCount) + StringHelper.ellipsis : props.space.Description}</div>
            )}
        </div>
    </div>
);

export class SpaceSwitcher extends BaseComponent<SpaceSwitcherProps, SpaceSwitcherState> {
    private searchRef: HTMLElement = undefined!;
    private virtualList: FocusableComponent | null = undefined!;

    constructor(props: SpaceSwitcherProps) {
        super(props);

        this.state = {
            filter: "",
            redirect: false,
        };
    }

    componentWillReceiveProps(nextProps: SpaceSwitcherProps) {
        if (nextProps.open) {
            this.setState({
                filter: "",
                redirect: false,
            });
        }
    }

    renderUpgradeJourneyForOnboarding() {
        const isWithinSpaceLimit = isWithinLicenceLimit(this.props.licenseStatus, "Spaces");
        return isWithinSpaceLimit ? (
            <div>
                <div className={styles.emptyButton}>
                    <ActionButton type={ActionButtonType.Primary} label="ADD SPACE" onClick={this.addSpace} />
                </div>
                <Note>
                    Learn more about <ExternalLink href="spaces">Spaces</ExternalLink>
                </Note>
            </div>
        ) : (
            <div>
                <div className={styles.emptyButton}>
                    <ActionButton label="UNLOCK MORE SPACES" type={ActionButtonType.Primary} onClick={this.props.onUpgradeSpacesDialogRequested} />
                </div>
                <Note>You've hit the maximum number of Spaces.</Note>
            </div>
        );
    }

    renderFooterButton() {
        if (!this.hasAccessToMultipleSpaces() || isSpaceNotFound(this.props.spaceContext) || !isAllowed({ permission: Permission.SpaceCreate })) {
            return;
        }
        const isWithinSpaceLimit = isWithinLicenceLimit(this.props.licenseStatus, "Spaces");
        const actionButton = isWithinSpaceLimit ? (
            <ActionButton type={ActionButtonType.Ternary} label="ADD SPACE" onClick={this.addSpace} />
        ) : (
            <ActionButton type={ActionButtonType.Primary} label="UNLOCK MORE SPACES" onClick={this.props.onUpgradeSpacesDialogRequested} />
        );
        return <div className={styles.footer}>{actionButton}</div>;
    }

    render() {
        if (this.state.redirect) {
            const routeLinksRoot = isSpaceNotFound(this.props.spaceContext) ? routeLinks.withoutSpace() : routeLinks;
            return <InternalRedirect to={routeLinksRoot.configuration.spaces.root} push={true} />;
        }

        const orderedFilteredList = sortBy(this.filterList(this.state.filter), (s) => s.Name.toLowerCase());

        const showSpaceSelector = this.hasAccessToMultipleSpaces() || (isSpaceNotFound(this.props.spaceContext) && !this.props.spaceContext.isAlsoInSystemContext);

        return (
            <Popover
                style={{ overflowY: "hidden" }}
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                onClose={this.props.onRequestClose}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
            >
                <div className={styles.container} onKeyDown={this.onKeyEsc}>
                    {this.props.selected && <div className={styles.selected}>{this.props.selected.Name}</div>}
                    {!showSpaceSelector && (
                        <div className={styles.empty}>
                            <h1>Create Spaces for your teams</h1>
                            <p className={styles.description}>Spaces allow teams to group their projects, infrastructure, tenants and library assets.</p>
                            {this.renderUpgradeJourneyForOnboarding()}
                            <img src={require("./onboarding-spaces.svg")} alt="spaces" width={250} height={250} />
                        </div>
                    )}
                    {showSpaceSelector && (
                        <Section>
                            <FilterSearchBox
                                innerRef={this.setSearchRef}
                                autoFocus={true}
                                value={this.state.filter}
                                placeholder="Jump to space..."
                                onChange={this.handleFilterChanged}
                                fullWidth={true}
                                onKeyDown={this.onArrowDown}
                                containerClassName={styles.filterFieldContainer}
                            />
                        </Section>
                    )}
                    {showSpaceSelector && (
                        <div className={styles.menuContainer}>
                            <VirtualList
                                multiSelectRef={(el) => (this.virtualList = el)}
                                items={orderedFilteredList}
                                renderItem={(space) => ({
                                    primaryText: <SpaceRow space={space} isSelected={(this.props.selected && space.Id === this.props.selected.Id) || false} />,
                                })}
                                containerElement={(item) => <SwitcherLink to={routeLinks.space(item.Id)} />}
                                onSelected={this.onRequestClose}
                                onResized={() => {
                                    // When the content's size changes, we re-render so that the
                                    // popover can re-position itself based on the new `VirtualList` size
                                    this.forceUpdate();
                                }}
                                onBlur={() => this.searchRef.focus()}
                            />
                        </div>
                    )}
                </div>
                {this.renderFooterButton()}
            </Popover>
        );
    }

    private hasAccessToMultipleSpaces = () => {
        return this.props.spaces.length > 1;
    };

    private addSpace = () => {
        this.setState({ redirect: true });
        this.onRequestClose();
    };

    private setSearchRef = (el: HTMLElement) => {
        this.searchRef = el;
    };

    private onRequestClose = () => {
        this.props.onRequestClose();
    };

    private filterList(filter: string): SpaceResource[] {
        const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());

        return filter.length > 0 ? this.props.spaces.filter((p) => matchesFilter(p.Name) || matchesFilter(p.Description || "")) : this.props.spaces;
    }

    private onKeyEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Escape") {
            this.onRequestClose();
        }
    };

    private onArrowDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowDown" || event.key === "Tab") {
            if (this.filterList(this.state.filter).length === 0) {
                return;
            }

            this.virtualList?.focus();
            event.preventDefault();
        }
    };

    private handleFilterChanged = (filter: string) => {
        this.setState({ filter });
    };
}

export default SpaceSwitcher;
