import React from "react";
import { GitIcon } from "components/Icon";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import { withTheme } from "components/Theme";

export const GitChip: React.FC<{ enabled: boolean } & ChipProps> = ({ enabled, ...chipProps }) => {
    return withTheme((theme) => {
        if (enabled) {
            return <Chip icon={<GitIcon />} label="Enabled" color="primary" style={{ backgroundColor: theme.successText }} {...chipProps} />;
        }
    });
};
