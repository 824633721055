/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { repository } from "../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import routeLinks from "../../routeLinks";
import PaperLayout from "components/PaperLayout";
import AreaTitle from "components/AreaTitle/index";
import InternalRedirect from "../../components/Navigation/InternalRedirect/InternalRedirect";

interface DeploymentToProjectTaskRedirectState extends DataBaseComponentState {
    redirectTo: string;
}

export default class DeploymentToProjectTaskRedirect extends DataBaseComponent<RouteComponentProps<{ deploymentId: string }>, DeploymentToProjectTaskRedirectState> {
    constructor(props: RouteComponentProps<{ deploymentId: string }>) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const deploymentId = this.props.match.params.deploymentId;
            const deployment = await repository.Deployments.get(deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);

            const path = routeLinks.project(release.ProjectId).release(release).deployments.specific(deployment);
            this.setState({
                redirectTo: path,
            });
        });
    }

    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={{ pathname: redirectTo }} />;
    }
}
