import * as React from "react";
import { RouteComponentProps, Switch } from "react-router-dom";
import TasksLayout from "../Tasks/TasksLayout";
import { RawLog } from "areas/tasks/components/RawLog/RawLog";
import ScriptConsole from "areas/tasks/components/ScriptConsole/ScriptConsole";
import { TaskScreen } from "../Task/TaskScreen";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import routeLinks from "routeLinks";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import InterruptionToProjectRedirect from "./InterruptionToTaskRedirect";

const ScriptConsolePage = withPage({ page: pageIds.tasks.console })(ScriptConsole);
const RawLogPage = withPage({ page: pageIds.task().raw })(RawLog);
const TaskPage = withPage({ page: pageIds.task().root })(TaskScreen);
const TasksPage = withPage({ page: pageIds.tasks.root })(TasksLayout);

export default class TaskRoutes extends React.Component<RouteComponentProps<void>> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.tasks.interruption(":interruptionId")} component={InterruptionToProjectRedirect} exact={true} />
                <ReloadableRoute path={routeLinks.tasks.console} component={ScriptConsolePage} />
                <ReloadableRoute path={routeLinks.task(":taskId").raw} component={RawLogPage} />
                <ReloadableRoute path={routeLinks.task(":taskId").root} component={TaskPage} />
                <ReloadableRoute path={routeLinks.tasks.root} component={TasksPage} />
            </Switch>
        );
    }
}
