import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "components/GettingStarted/OnboardingPage";
import ExternalVideoLink from "components/Navigation/ExternalLink/ExternalVideoLink";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Add steps to your deployment process"
            intro={`The deployment process is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project.
        Each step contains a specific action (or set of actions) that is executed as part of the deployment process each time your software is deployed.`}
            learnMore={
                <>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Learn more</ExternalLink>
                    <ExternalVideoLink href="OnboardingDeploymentProcessVideo" />
                </>
            }
        />
    );
};

export default Onboarding;
