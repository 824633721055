import * as React from "react";
import { RetentionPeriod, RetentionUnit } from "client/resources";
import RetentionPolicyControl from "areas/library/components/Lifecycle/RetentionPolicy/RetentionPolicyControl";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";

interface SelectRetentionPolicyState extends DataBaseComponentState {
    releaseRetentionPolicy: RetentionPeriod;
    tentacleRetentionPolicy: RetentionPeriod;
}

interface SelectRetentionPolicyProps {
    releaseRetentionPolicy: RetentionPeriod;
    tentacleRetentionPolicy: RetentionPeriod;
    onOk(releaseRetentionPolicy: RetentionPeriod, tentacleRetentionPolicy: RetentionPeriod): void;
}

export default class SelectRetentionPolicy extends DataBaseComponent<SelectRetentionPolicyProps, SelectRetentionPolicyState> {
    constructor(props: SelectRetentionPolicyProps) {
        super(props);

        const defaultReleaseRetentionPolicy = {
            ShouldKeepForever: true,
            QuantityToKeep: 0,
            Unit: RetentionUnit.Items,
        };

        const defaultTentacleRetentionPolicy = {
            ShouldKeepForever: true,
            QuantityToKeep: 0,
            Unit: RetentionUnit.Items,
        };

        this.state = {
            releaseRetentionPolicy: this.props.releaseRetentionPolicy || defaultReleaseRetentionPolicy,
            tentacleRetentionPolicy: this.props.tentacleRetentionPolicy || defaultTentacleRetentionPolicy,
        };
    }

    onOk() {
        this.props.onOk(this.state.releaseRetentionPolicy, this.state.tentacleRetentionPolicy);
        return true;
    }

    render() {
        return (
            <RetentionPolicyControl
                releaseRetentionPolicy={this.state.releaseRetentionPolicy}
                tentacleRetentionPolicy={this.state.tentacleRetentionPolicy}
                onReleaseRetentionPolicyChange={(pol) =>
                    this.setState({ releaseRetentionPolicy: pol }, () => {
                        this.onOk();
                    })
                }
                onTentacleRetentionPolicyChange={(pol) =>
                    this.setState({ tentacleRetentionPolicy: pol }, () => {
                        this.onOk();
                    })
                }
            />
        );
    }
}
