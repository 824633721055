import * as React from "react";
import ControlledTabsContainer from "./ControlledTabsContainer";
import UrlQueryState from "components/UrlQueryState";
import { RenderTabCallback } from "./useTabConversion";

export interface UrlNavigationTabsContainerProps {
    defaultValue: string;
    urlParameterName?: string;
    onChange?: (value: string) => void;
    renderTab?: RenderTabCallback;
}

export const UrlNavigationTabsContainer: React.FC<UrlNavigationTabsContainerProps> = ({ defaultValue, urlParameterName = "activeTab", onChange, renderTab, children }) => {
    return (
        <UrlQueryState
            onChange={onChange}
            name={urlParameterName}
            defaultValue={defaultValue}
            render={(renderProps) => (
                <ControlledTabsContainer renderTab={renderTab} value={renderProps.value} onChange={renderProps.onChange}>
                    {children}
                </ControlledTabsContainer>
            )}
        />
    );
};

export default UrlNavigationTabsContainer;
