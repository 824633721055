/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import IconButtonList from "components/IconButtonList/IconButtonList";
import InputWithActions from "components/InputWithActions";
import FormFieldProps from "components/form/FormFieldProps";
import Select from "components/form/Select/Select";
import BusyIndicator from "components/BusyIndicator/index";
import IconButton, { Icon } from "components/IconButton/IconButton";
import routeLinks from "routeLinks";
import { resolveStringPathWithSpaceId } from "components/Navigation/resolvePathWithSpaceId";
import { client } from "clientInstance";

interface VariableSelectRequiredProps extends FormFieldProps<string> {
    variables: string[];
    projectId: string;
    isRefreshing: boolean;
    onRequestRefresh: () => Promise<any>;
    validation: { error?: string };
}

interface VariableSelectOptionalProps {
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
}
type VariableSelectProps = VariableSelectRequiredProps & VariableSelectOptionalProps;

export const Busy: React.SFC<{ isBusy: boolean }> = ({ isBusy, children }) => {
    return isBusy ? <BusyIndicator show={true} inline={true} /> : <>{children}</>;
};

const gotoProjectVariables = (projectId: string) => () => window.open(`#${resolveStringPathWithSpaceId(routeLinks.project(projectId).variables.root, client.spaceId!)}`, "_blank");

const VariableSelect: React.SFC<VariableSelectProps> = ({ onChange, onRequestRefresh, error, isRefreshing, validation, variables, projectId, ...otherProps }) => {
    return (
        <Busy isBusy={isRefreshing}>
            <InputWithActions
                input={<Select label="Select Account variable" {...otherProps} allowFilter={true} error={error || validation.error} onChange={(value) => onChange(value!)} items={variables.map((v) => ({ value: v, text: v }))} />}
                actions={
                    <IconButtonList
                        buttons={[
                            <Busy isBusy={isRefreshing}>
                                <IconButton toolTipContent="Refresh" icon={Icon.Refresh} onClick={onRequestRefresh} />
                            </Busy>,
                            <IconButton toolTipContent="Add" icon={Icon.Add} onClick={gotoProjectVariables(projectId)} />,
                        ]}
                    />
                }
            />
        </Busy>
    );
};

export default VariableSelect;
export { VariableSelect, VariableSelectProps, VariableSelectOptionalProps, VariableSelectRequiredProps };
