import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonProjectRunbookOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbooks</ExternalLink> can be used to automate routine or emergency operations-centric processes, for instance, disaster recovery and database backups.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectRunbookTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbooks</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
