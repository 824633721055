import * as React from "react";
import { OverflowMenu } from "components/Menu";
import { MenuItem } from "components/Menu/OverflowMenu";
const styles = require("./style.less");

interface ListItemProps {
    secondaryAction?: React.ReactElement;
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
}

const ListItem: React.StatelessComponent<React.PropsWithChildren<ListItemProps>> = (props) => {
    return (
        <span className={styles.menuItem}>
            <span className={styles.content}>{props.children}</span>
            {props.secondaryAction && <span>{props.secondaryAction}</span>}
            {props.overflowMenuItems && (
                <span>
                    <OverflowMenu menuItems={props.overflowMenuItems} />
                </span>
            )}
        </span>
    );
};

ListItem.displayName = "ListItem";

export default ListItem;
