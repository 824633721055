import React from "react";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import { GitCommit, VcsRef } from "client/resources/versionControlledResource";
import { GitBranchIcon, GitCommitIcon } from "components/Icon";
import { useProjectContext } from "areas/projects/context";
import { withTheme } from "components/Theme";

function shortGitCommit(commit?: GitCommit, length = 7): GitCommit {
    return (commit && commit.substring(0, length)) || "";
}

export const GitRefChip: React.FC<{ vcsRef: VcsRef; showCommit?: boolean } & ChipProps> = ({ vcsRef, showCommit = true, ...chipProps }) => {
    const projectContext = useProjectContext();
    const isDefaultBranch = vcsRef.GitRef == projectContext?.state.branch?.Name;
    // Material Chips don't really expect an icon in the label, so we need to bend the styling to our will
    const iconInLabelStyles = {
        verticalAlign: "sub",
        marginRight: "4px",
    };

    const gitRefIcon = <GitBranchIcon />;
    const gitRefText = vcsRef.GitRef;
    const commitIcon = <GitCommitIcon className="MuiChip-icon MuiChip-iconSmall" style={iconInLabelStyles} />;
    const commitText = shortGitCommit(vcsRef.GitCommit);

    const label = showCommit ? [gitRefText, commitIcon, commitText] : gitRefText;

    return withTheme((theme) => {
        const chipStyles = {
            backgroundColor: theme.subtleButtonBackground,
        };
        // TODO: add background colours for default branch once we know what the dark theme colours are
        const defaultBranchChipStyles = chipStyles; // e.g. { backgroundColour: theme.whatAreWeCallingThisAndWhereElseDoWeUseIt }
        const styles = isDefaultBranch ? defaultBranchChipStyles : chipStyles;

        return (vcsRef && vcsRef.GitRef && <Chip icon={gitRefIcon} label={label} style={styles} {...chipProps} size="small"></Chip>) || null;
    });
};
