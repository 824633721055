import * as React from "react";
import SortableList from "components/SortableList/SortableList";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "../../../../components/DialogLayout/SaveDialogLayout";
import { TagSetResource } from "../../../../client/resources/tagSetResource";
import { repository } from "../../../../clientInstance";

interface TagSetsSorterProps {
    onSaved(): void;
}

interface TagSetsSorterState extends DataBaseComponentState {
    sortedTagSets: TagSetResource[];
}

export default class TagSetsSorter extends DataBaseComponent<TagSetsSorterProps, TagSetsSorterState> {
    constructor(props: TagSetsSorterProps) {
        super(props);
        this.state = { sortedTagSets: [] };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            this.setState({ sortedTagSets: await repository.TagSets.all() });
        });
    }

    render() {
        return (
            <SaveDialogLayout title="Reorder Tag Sets" errors={this.errors} busy={this.state.busy} onSaveClick={this.saveNewOrder}>
                <SortableList items={this.state.sortedTagSets} onOrderChanged={(sortedTagSets: TagSetResource[]) => this.setState({ sortedTagSets })} />
            </SaveDialogLayout>
        );
    }

    private saveNewOrder = async () => {
        return this.doBusyTask(async () => {
            await repository.TagSets.sort(this.state.sortedTagSets.map((i) => i.Id));
            this.props.onSaved();
        });
    };
}
