/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import FormPaperLayout, { FormPaperLayoutProps } from "components/FormPaperLayout/FormPaperLayout";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
import { ExpandableFormSection, Summary, Note } from "components/form";
import Text from "components/form/Text/Text";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { repository } from "clientInstance";
import { AccountResource, AccountType, AzureEnvironment, AccountUsageResource } from "client/resources";
import { required } from "components/form/Validators";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import { EnvironmentResource } from "client/resources/environmentResource";
import { environmentChipList } from "components/Chips/index";
import { TenantedDeploymentMode } from "client/resources";
import { TenantResource } from "../../../../client/resources/tenantResource";
import ExpanderSectionHeading from "../../../../components/form/Sections/FormSectionHeading";
import MarkdownEditor from "../../../../components/form/MarkdownEditor/MarkdownEditor";
import Markdown from "../../../../components/Markdown/index";
import TenantedDeploymentParticipationSelector from "components/TenantedDeploymentParticipationSelector";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
const styles = require("./style.less");
import { AdvancedTenantsAndTenantTagsSelector } from "components/AdvancedTenantSelector";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { cloneDeep } from "lodash";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { UrlNavigationTabsContainer, TabItem } from "components/Tabs";
import AccountUsage from "./AccountUsage";
import { FormBaseComponent, FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";

export type AccountLayoutOverFlowProps = FormPaperLayoutProps["overFlowActions"];

interface AccountDisplayProps {
    name: string;
    description: string;
}

interface AccountScopingProps {
    environmentIds: string[];
    tenantIds: string[];
    tenantTags: string[];
    tenantMode: TenantedDeploymentMode;
}

type AccountEditModel = AccountDisplayProps & AccountScopingProps;

interface AccountEditState<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends FormBaseComponentState<TModel> {
    deleted: boolean;
    showTestDialog: boolean;
    accountData?: AccountData<TAccountResource> | undefined;
}

interface AccountData<TAccountResource extends AccountResource> {
    account: TAccountResource;
    accountUsage?: AccountUsageResource | undefined;
}

const IsNew = "IsNew";

interface AccountEditProps<TAccountResource extends AccountResource> {
    account: TAccountResource | typeof IsNew;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    azureEnvironments: AzureEnvironment[];
}

const defaultModel: AccountEditModel = {
    name: "",
    description: "",
    environmentIds: [],
    tenantIds: [],
    tenantTags: [],
    tenantMode: TenantedDeploymentMode.Untenanted,
};

// eslint-disable-next-line: max-line-length
abstract class AccountEdit<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends FormBaseComponent<AccountEditProps<TAccountResource>, AccountEditState<TAccountResource, TModel>, TModel> {
    constructor(props: AccountEditProps<TAccountResource>) {
        super(props);

        if (this.props.account === IsNew) {
            this.state = {
                model: this.buildModel() as TModel,
                cleanModel: cloneDeep(this.buildModel()) as TModel,
                deleted: false,
                showTestDialog: false,
            };
        } else {
            this.state = {
                model: this.buildModel(this.props.account) as TModel,
                cleanModel: cloneDeep(this.buildModel(this.props.account)) as TModel,
                accountData: {
                    account: this.props.account,
                },
                deleted: false,
                showTestDialog: false,
            };
        }
    }

    abstract customSecondaryAction(): React.ReactElement | null;

    abstract customExpandableFormSections(): React.ReactElement[];

    abstract getPartialResource(): (Partial<TAccountResource> & { AccountType: AccountType }) | undefined;

    abstract getPartialModel(account?: TAccountResource): Partial<TModel> | undefined;

    getAccountSummary(): React.ReactElement | null {
        return null;
    }

    buildModel(account?: TAccountResource | undefined): AccountEditModel {
        const partial = this.getPartialModel(account);
        if (!account) {
            return {
                ...partial,
                ...defaultModel,
            };
        }

        return {
            ...partial,
            name: account.Name,
            description: account.Description,
            environmentIds: account.EnvironmentIds,
            tenantIds: account.TenantIds,
            tenantTags: account.TenantTags,
            tenantMode: account.TenantedDeploymentParticipation,
        };
    }

    handleDeleteConfirm = async (account: AccountResource) => {
        await repository.Accounts.del(account);
        this.setState(() => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    handleSaveClick = async (performTest: boolean) => {
        await this.doBusyTask(async () => {
            let model = this.state.model;
            let existingAccount;
            if (this.state.accountData !== undefined) {
                existingAccount = this.state.accountData.account;
            }

            const account: AccountResource = {
                ...(existingAccount as AccountResource),
                ...(this.getPartialResource() as { AccountType: AccountType }),
                Name: model.name,
                Description: model.description,
                TenantedDeploymentParticipation: model.tenantMode || TenantedDeploymentMode.Untenanted,
                TenantTags: model.tenantTags,
                TenantIds: model.tenantIds,
                EnvironmentIds: model.environmentIds,
            };

            const result = await repository.Accounts.save(account);
            model = this.buildModel(account as TAccountResource) as TModel;

            this.setState({
                ...this.state,
                accountData: {
                    account: result as TAccountResource,
                },
                model,
                cleanModel: cloneDeep(model),
                deleted: false,
                showTestDialog: performTest,
            });
        });
    };

    nameSummary() {
        return this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your account");
    }

    descriptionSummary() {
        return this.state.model.description ? Summary.summary(<Markdown markup={this.state.model.description} />) : Summary.placeholder("No account description provided");
    }

    environmentsSummary() {
        return this.state.model.environmentIds.length >= 1
            ? Summary.summary(<span>Only available for deployments to {environmentChipList(this.props.environments, this.state.model.environmentIds)}</span>)
            : Summary.default("Available for deployments to any environment");
    }

    tenantDeploymentModeSummary() {
        return CommonSummaryHelper.tenantDeploymentModeSummary(this.state.model.tenantMode, this.state.model.tenantIds, this.state.model.tenantTags);
    }

    tenantSummary() {
        return CommonSummaryHelper.tenantSummary(this.state.model.tenantIds, this.state.model.tenantTags, this.props.tenants);
    }

    testDone() {
        this.setState({
            showTestDialog: false,
        });
    }

    getOverFlowActions(account: AccountResource): AccountLayoutOverFlowProps {
        return [
            OverflowMenuItems.deleteItemDefault("account", () => this.handleDeleteConfirm(account), {
                permission: Permission.AccountDelete,
                environment: "*",
                tenant: "*",
            }),
            [
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([account.Id]), undefined, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ],
        ];
    }

    render() {
        if (this.state.deleted) {
            return <InternalRedirect to={routeLinks.infrastructure.accounts.root} />;
        }

        const isNewAccount = this.state.accountData === undefined;
        const savePermission = { permission: isNewAccount ? Permission.AccountCreate : Permission.AccountEdit, environment: "*", tenant: "*" };
        let title = "Create Account";

        const accountData = this.state.accountData;
        let usageTab;
        let testDialogRedirect;
        let overflowActions;

        if (accountData !== undefined) {
            title = accountData.account.Name;
            usageTab = (
                <TabItem label="Usage" value="usage" onActive={this.onUsageTabActive}>
                    {accountData.accountUsage && <AccountUsage account={accountData.account} key={"accountUsage"} accountUsages={accountData.accountUsage} />}
                </TabItem>
            );
            testDialogRedirect = !this.state.showTestDialog && <InternalRedirect to={routeLinks.infrastructure.account(accountData.account.Id)} />;
            overflowActions = this.getOverFlowActions(accountData.account);
        }

        return (
            <InfrastructureLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Accounts"}
                    breadcrumbPath={routeLinks.infrastructure.accounts.root}
                    saveText="Account details changed"
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={savePermission}
                    onSaveClick={() => this.handleSaveClick(false)}
                    secondaryAction={isAllowed(savePermission) && this.customSecondaryAction()}
                    expandAllOnMount={isNewAccount}
                    overFlowActions={overflowActions}
                >
                    {this.getAccountSummary()}
                    {testDialogRedirect}
                    {this.state.model && (
                        <div className={styles.expanderContainer}>
                            <UrlNavigationTabsContainer defaultValue="details">
                                <TabItem label="Details" value="details">
                                    <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.nameSummary()} help="A short, memorable, unique name for this account.">
                                        <Text
                                            value={this.state.model.name}
                                            onChange={(name) => this.setModelState({ name })}
                                            label="Account name"
                                            validate={required("Please enter an account name")}
                                            error={this.getFieldError("name")}
                                            autoFocus={true}
                                        />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="A summary explaining the use of the account to other users.">
                                        <MarkdownEditor value={this.state.model.description} label="Account description" onChange={(description) => this.setModelState({ description })} />
                                    </ExpandableFormSection>

                                    {this.customExpandableFormSections()}

                                    <ExpanderSectionHeading title="Restrictions" key={"header"} />
                                    <ExpandableFormSection errorKey="environment" title="Environments" summary={this.environmentsSummary()} help="Choose the environments that are allowed to use this account">
                                        <Note>If this field is left blank, the account can be used for deployments to any environment. Specifying environment/s (especially for production accounts) is strongly recommended.</Note>
                                        <EnvironmentMultiSelect items={this.props.environments} onChange={(environmentIds) => this.setModelState({ environmentIds })} value={this.state.model.environmentIds} accessibleName="Allowed environments" />
                                    </ExpandableFormSection>

                                    <FeatureToggle feature={Feature.MultiTenancy}>
                                        <PermissionCheck permission={Permission.TenantView} tenant="*">
                                            <ExpandableFormSection
                                                errorKey="TenantedDeploymentMode"
                                                title="Tenanted Deployments"
                                                summary={this.tenantDeploymentModeSummary()}
                                                help={"Choose the kind of deployments where this account should be included."}
                                            >
                                                <TenantedDeploymentParticipationSelector tenantMode={this.state.model.tenantMode} resourceTypeLabel="account" onChange={(x) => this.setModelState({ tenantMode: x as TenantedDeploymentMode })} />
                                            </ExpandableFormSection>
                                            {this.state.model.tenantMode !== TenantedDeploymentMode.Untenanted && (
                                                <ExpandableFormSection errorKey="Tenants" title="Associated Tenants" summary={this.tenantSummary()} help={"Choose tenants this account should be associated with."}>
                                                    <AdvancedTenantsAndTenantTagsSelector
                                                        tenants={this.props.tenants}
                                                        selectedTenantIds={this.state.model.tenantIds}
                                                        selectedTenantTags={this.state.model.tenantTags}
                                                        doBusyTask={this.doBusyTask}
                                                        onChange={(tenantIds, tenantTags) => this.setModelState({ tenantIds, tenantTags })}
                                                        showPreviewButton={true}
                                                    />
                                                </ExpandableFormSection>
                                            )}
                                        </PermissionCheck>
                                    </FeatureToggle>
                                </TabItem>
                                {usageTab}
                            </UrlNavigationTabsContainer>
                        </div>
                    )}
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    protected defaultAccountModel(): AccountEditModel {
        return defaultModel;
    }

    private onUsageTabActive = async () => {
        if (!this.state.accountData) {
            return;
        }

        const account = this.state.accountData.account;

        await this.doBusyTask(async () => {
            const usages = await repository.Accounts.getAccountUsages(account);
            this.setState({
                ...this.state,
                accountData: {
                    account,
                    accountUsage: usages,
                },
            });
        });
    };
}

export { AccountEditModel, AccountEditState, AccountDisplayProps, AccountScopingProps, AccountEditProps };
export default AccountEdit;
