/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { RadioButton, Text, Select } from "components/form";
import { BooleanRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import ComponentRow from "components/ComponentRow";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { RetentionPeriod, RetentionUnit } from "client/resources";
import Note from "components/form/Note/Note";
import { cloneDeep } from "lodash";
const styles = require("./style.less");
import ExternalLink from "components/Navigation/ExternalLink";
import { minimum } from "components/form/Validators";

interface RetentionPolicyControlProps {
    releaseRetentionPolicy: RetentionPeriod;
    tentacleRetentionPolicy: RetentionPeriod;
    onReleaseRetentionPolicyChange(policy: RetentionPeriod): void;
    onTentacleRetentionPolicyChange(policy: RetentionPeriod): void;
}

export default class RetentionPolicyControl extends React.Component<RetentionPolicyControlProps> {
    createCallback(callback: (policy: RetentionPeriod) => void, policy: RetentionPeriod) {
        return (mutation: (policy: RetentionPeriod) => void) => {
            const pol = cloneDeep(policy);
            mutation(pol);
            callback(pol);
        };
    }

    render() {
        const releaseCallback = this.createCallback(this.props.onReleaseRetentionPolicyChange, this.props.releaseRetentionPolicy);
        const tentacleCallback = this.createCallback(this.props.onTentacleRetentionPolicyChange, this.props.tentacleRetentionPolicy);

        return (
            <div>
                <div>
                    <BooleanRadioButtonGroup
                        value={this.props.releaseRetentionPolicy.ShouldKeepForever}
                        onChange={(val) =>
                            releaseCallback((pol) => {
                                pol.ShouldKeepForever = val;
                                pol.QuantityToKeep = val ? 0 : 3;
                            })
                        }
                        label="How long should we keep releases?"
                    >
                        <RadioButton value={true} label="Keep all" isDefault={true} />
                        <RadioButton value={false} label="Keep a limited number" />
                    </BooleanRadioButtonGroup>
                    {!this.props.releaseRetentionPolicy.ShouldKeepForever && (
                        <ComponentRow>
                            <span className={styles.narrow}>
                                <Text
                                    type="number"
                                    min={1}
                                    value={this.props.releaseRetentionPolicy.QuantityToKeep ? this.props.releaseRetentionPolicy.QuantityToKeep.toString() : ""}
                                    onChange={(x) => releaseCallback((pol) => (pol.QuantityToKeep = ParseHelper.safeParseInt(x)))}
                                    validate={minimum("Please provide a valid value for release retention.", 1)}
                                />
                            </span>
                            <span className={styles.narrow}>
                                <Select
                                    value={this.props.releaseRetentionPolicy.Unit}
                                    onChange={(val) => releaseCallback((pol) => (pol.Unit = val as RetentionUnit))}
                                    items={[
                                        { value: RetentionUnit.Days, text: "Days" },
                                        { value: RetentionUnit.Items, text: "Releases" },
                                    ]}
                                />
                            </span>
                        </ComponentRow>
                    )}
                </div>
                <div>
                    <BooleanRadioButtonGroup
                        value={this.props.tentacleRetentionPolicy.ShouldKeepForever}
                        onChange={(val) =>
                            tentacleCallback((prevState) => {
                                prevState.ShouldKeepForever = val;
                                prevState.QuantityToKeep = val ? 0 : 3;
                            })
                        }
                        label="How long should we keep extracted packages and files on disk on Tentacles?"
                    >
                        <RadioButton value={true} label="Keep all" isDefault={true} />
                        <RadioButton value={false} label="Keep a limited number" />
                    </BooleanRadioButtonGroup>
                    {!this.props.tentacleRetentionPolicy.ShouldKeepForever && (
                        <ComponentRow>
                            <span className={styles.narrow}>
                                <Text
                                    type="number"
                                    min={1}
                                    validate={minimum("Please provide a valid value for tentacle retention policy", 1)}
                                    value={this.props.tentacleRetentionPolicy.QuantityToKeep ? this.props.tentacleRetentionPolicy.QuantityToKeep.toString() : ""}
                                    onChange={(x) => tentacleCallback((pol) => (pol.QuantityToKeep = ParseHelper.safeParseInt(x)))}
                                />
                            </span>
                            <span className={styles.narrow}>
                                <Select
                                    value={this.props.tentacleRetentionPolicy.Unit}
                                    onChange={(val) => tentacleCallback((pol) => (pol.Unit = val as RetentionUnit))}
                                    items={[
                                        { value: RetentionUnit.Days, text: "Days" },
                                        { value: RetentionUnit.Items, text: "Releases" },
                                    ]}
                                />
                            </span>
                        </ComponentRow>
                    )}
                    <br />
                    <Note>
                        Retention policies dictate how long releases and deployments are kept for. For more information please see <ExternalLink href="RetentionPolicies">retention policies</ExternalLink> documentation.
                    </Note>
                </div>
            </div>
        );
    }
}
