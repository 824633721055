import * as React from "react";
import { WorkItemLink } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";

interface WorkItemsProps {
    data: WorkItemLink[];
}

export default class WorkItems extends DataBaseComponent<WorkItemsProps, DataBaseComponentState> {
    constructor(props: WorkItemsProps) {
        super(props);
        this.state = {};
    }

    resolveLink = (workItem: WorkItemLink): JSX.Element => {
        if (workItem.LinkUrl) {
            return (
                <div key={workItem.Id}>
                    <ExternalLink href={workItem.LinkUrl}>{workItem.Id}</ExternalLink>
                    {workItem.Id !== workItem.Description ? ` - ${workItem.Description}` : ""}
                </div>
            );
        } else {
            return (
                <div key={workItem.Id}>
                    {workItem.Id}
                    {workItem.Description && workItem.Id !== workItem.Description ? ` - ${workItem.Description}` : ""}
                </div>
            );
        }
    };

    render() {
        return this.props.data.map((workItem) => this.resolveLink(workItem));
    }
}
