/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { repository } from "clientInstance";
import { ActionButton, ActionButtonType } from "components/Button";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import { cloneDeep } from "lodash";
import { ProjectResource, EnvironmentResource, TenantedDeploymentMode, Permission, NonVcsRunbookResource, ProgressionResource } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import { TenantResource } from "../../../client/resources/tenantResource";
import { Callout, CalloutType } from "../../../components/Callout/Callout";
import Select from "components/form/Select/Select";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";
import DataLoader from "components/DataLoader";

interface InitialDataProps {
    projects: ProjectResource[];
}

interface AddProjectsToTenantDialogProps {
    existingProjectLink?: { projectId: string; environmentIds: string[] };
    excludedProjects: string[];
    tenant: TenantResource;
    onUpdated(tenant: TenantResource): void;
}

interface AddProjectsToTenantDialogState extends DataBaseComponentState {
    selectedProject?: ProjectResource;
    runbooksInSelectedProject: NonVcsRunbookResource[];
    environmentIds: string[];
    availableEnvironments?: EnvironmentResource[];
    isLoaded: boolean;
}

const InitialDataLoader = DataLoader<InitialDataProps>();
const connectToAProjectTitle = "Connect to a project";

const AddProjectToTenantDialogDataLoader: React.FC<AddProjectsToTenantDialogProps> = (props) => {
    return (
        <InitialDataLoader
            load={async () => {
                const projects = await repository.Projects.all();
                return { projects };
            }}
            renderWhenLoaded={(data) => <AddProjectsToTenantDialog {...data} {...props} />}
            renderAlternate={({ busy, errors }) => <SaveDialogLayout title={connectToAProjectTitle} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)} />}
        />
    );
};

export class AddProjectsToTenantDialog extends DataBaseComponent<AddProjectsToTenantDialogProps & InitialDataProps, AddProjectsToTenantDialogState> {
    constructor(props: AddProjectsToTenantDialogProps & InitialDataProps) {
        super(props);
        this.state = {
            environmentIds: [],
            isLoaded: false,
            runbooksInSelectedProject: [],
        };
    }

    componentDidMount() {
        this.setExistingProject(this.props);
    }

    componentWillReceiveProps(nextProps: AddProjectsToTenantDialogProps) {
        this.setExistingProject(nextProps);
    }

    setExistingProject(props: AddProjectsToTenantDialogProps) {
        const { projectId = null, environmentIds = [] } = props.existingProjectLink || {};
        const selectedProject = projectId ? this.props.projects.find((p) => p.Id === projectId) : undefined;
        //TODO markse: shouldn't need to chain these calls, review isLoaded
        this.setState(
            {
                environmentIds,
                selectedProject,
                availableEnvironments: [],
                isLoaded: !selectedProject,
            },
            async () => {
                if (selectedProject) {
                    await this.doBusyTask(async () => {
                        const progression = await repository.Progression.getProgression(selectedProject);
                        const runbooksInProject = await repository.Projects.getRunbooks(selectedProject, { take: repository.takeAll });
                        this.setState({
                            availableEnvironments: progression.Environments as EnvironmentResource[],
                            isLoaded: true,
                            runbooksInSelectedProject: runbooksInProject.Items,
                        });
                    });
                }
            }
        );
    }

    enableMultiTenancy = async (project: ProjectResource, runbooksInSelectedProject: NonVcsRunbookResource[]) => {
        const updateProject = () => {
            project.TenantedDeploymentMode = TenantedDeploymentMode.TenantedOrUntenanted;
            return repository.Projects.modify(project);
        };

        const updateRunbooks = () =>
            runbooksInSelectedProject.map((runbook) => {
                runbook.MultiTenancyMode = TenantedDeploymentMode.TenantedOrUntenanted;
                return repository.Runbooks.modify(runbook);
            });

        await this.doBusyTask(async () => {
            await Promise.all([updateProject(), updateRunbooks()]);
        });
    };

    handleProjectSelected = async (projectId: string | undefined) => {
        const selectedProject = projectId ? this.props.projects.find((p) => p.Id === projectId) : undefined;
        this.setState({ selectedProject, environmentIds: [], availableEnvironments: [] }, async () => {
            if (selectedProject) {
                await this.doBusyTask(async () => {
                    const [progression, runbooksInProject] = await Promise.all([repository.Progression.getProgression(selectedProject), repository.Projects.getRunbooks(selectedProject, { take: repository.takeAll })]);

                    this.setState({
                        availableEnvironments: progression.Environments as EnvironmentResource[],
                        runbooksInSelectedProject: runbooksInProject.Items,
                    });
                });
            }
        });
    };

    handleEnvironmentsSelected = async (environmentIds: string[]) => {
        this.setState({ environmentIds });
    };

    renderProjectUntenanted(project: ProjectResource) {
        return (
            <Callout title="To connect, a project must have tenanted deployments enabled." type={CalloutType.Warning}>
                Do you want to enable tenanted deployments for <b>{project.Name}</b>?
                <PermissionCheck permission={Permission.ProjectEdit} project={project.Id} projectGroup={project.ProjectGroupId} tenant="*">
                    <div style={{ marginTop: "1rem" }}>
                        <ActionButton label={`Enable tenanted deployments`} type={ActionButtonType.Primary} onClick={() => this.enableMultiTenancy(project, this.state.runbooksInSelectedProject)} />
                    </div>
                </PermissionCheck>
            </Callout>
        );
    }

    renderEnvironmentMultiSelect() {
        return (
            this.state.isLoaded && [
                <EnvironmentMultiSelect key="select" onChange={this.handleEnvironmentsSelected} value={this.state.environmentIds} items={this.state.availableEnvironments || []} autoFocus />,
                this.state.environmentIds && this.state.environmentIds.length === 0 ? (
                    <Callout key="callout" title="No Environments Selected" type={CalloutType.Information}>
                        A tenant needs to be linked to an environment of a project before deployments can take place.
                    </Callout>
                ) : null,
            ]
        );
    }

    renderExistingProject() {
        return this.state.selectedProject && this.state.runbooksInSelectedProject && (this.canProjectDeployTenanted() ? this.renderEnvironmentMultiSelect() : this.renderProjectUntenanted(this.state.selectedProject));
    }

    renderNewProjectLink() {
        const projects = this.props.projects.filter((p) => !this.props.excludedProjects.includes(p.Id)).map((p) => ({ value: p.Id, text: p.Name }));

        return (
            <div>
                {projects && projects.length === 0 ? (
                    <Callout type={CalloutType.Information} title="No Projects">
                        Create your first <InternalLink to={routeLinks.projects.root}>project</InternalLink> now.
                    </Callout>
                ) : (
                    [<Select onChange={this.handleProjectSelected} label="Select a project" value={this.state.selectedProject ? this.state.selectedProject.Id : undefined} items={projects} allowFilter={true} autoFocus />, this.renderExistingProject()]
                )}
            </div>
        );
    }

    canProjectDeployTenanted() {
        const deploymentsAllowsMultiTenancy = this.state.selectedProject && this.state.selectedProject.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted;
        if (deploymentsAllowsMultiTenancy) {
            return true;
        }

        return this.state.runbooksInSelectedProject && this.state.runbooksInSelectedProject.some((r) => r.MultiTenancyMode !== TenantedDeploymentMode.Untenanted);
    }

    save = async () => {
        return this.doBusyTask(async () => {
            const tenant = cloneDeep(this.props.tenant);
            if (this.state.selectedProject) {
                tenant.ProjectEnvironments[this.state.selectedProject.Id] = this.state.environmentIds ? this.state.environmentIds : [];
            }

            const savedTenant = await repository.Tenants.save(tenant);
            setImmediate(() => this.props.onUpdated(savedTenant));
        });
    };

    render() {
        const isExistingProjectLink = !!(this.props.existingProjectLink && this.props.existingProjectLink.projectId);
        const title = isExistingProjectLink && this.state.selectedProject ? `Change Connection to ${this.state.selectedProject.Name}` : connectToAProjectTitle;
        const updateButton = isExistingProjectLink ? "Update Connection" : "Add Connection";

        return (
            <SaveDialogLayout
                title={title}
                busy={this.state.busy}
                errors={this.errors}
                onSaveClick={this.save}
                savePermission={{ permission: Permission.TenantEdit, tenant: this.props.tenant.Id }}
                saveButtonDisabled={!this.canProjectDeployTenanted()}
                saveButtonLabel={updateButton}
            >
                {isExistingProjectLink ? this.renderExistingProject() : this.renderNewProjectLink()}
            </SaveDialogLayout>
        );
    }
}

export default AddProjectToTenantDialogDataLoader;
