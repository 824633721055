/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { Permission, EnvironmentResource } from "client/resources";
import routeLinks from "../../../../routeLinks";
import { DeploymentCreateGoal } from "./ReleasesRoutes/releaseRouteLinks";
import { ResourcesById } from "client/repositories/basicRepository";
import { repository, session } from "../../../../clientInstance";
import TenantedDeploymentMode from "client/resources/tenantedDeploymentMode";
import FilterSearchBox from "components/FilterSearchBox";
import Divider from "components/Divider/Divider";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { RaisedButtonProps } from "material-ui";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import { ActionButtonType } from "components/Button";
import { flow as compose, identity, head } from "lodash";
import { OctopusTheme, withTheme } from "components/Theme";
const styles = require("./style.less");

function createDeployButtonStyle(theme: OctopusTheme): Partial<RaisedButtonProps> {
    return {
        labelColor: theme.primaryButtonText,
        backgroundColor: theme.primaryButtonBackground,
        labelStyle: {
            fontSize: "0.8125rem",
            whiteSpace: "nowrap",
        },
    };
}

interface DeployButtonControllerState {
    open: boolean;
    filterText?: string;
}

export interface DeployButtonProps {
    open: boolean;
    filter: string;
    visibleEnvironments: string[];
    environments: string[];
    getEnvironmentLink: (environment?: string) => string;
    canDeployToMultipleEnvironments: boolean;
    onFilterChange: (filter: string) => void;
    getEnvironment: (id: string) => EnvironmentResource;
    onClose: () => void;
    onClick: () => void;
}

export interface DeployButtonControllerProps {
    projectSlug: string;
    projectId: string;
    releaseVersion: string;
    nextDeployments: string[];
    environmentsById: ResourcesById<EnvironmentResource>;
    component?: React.ComponentType<DeployButtonProps>;
    tenantedDeploymentMode: TenantedDeploymentMode;
    checkAuthorized?: (permission: Permission, project: string, environment: string) => boolean;
}

function createEnvironmentAuthFilter(predicate: (permission: Permission, project: string, environment: string) => boolean) {
    return (permission: Permission, project: string) => {
        return (items: string[]) => {
            return items.filter((e) => predicate(permission, project, e));
        };
    };
}

const filterEnvironmentsByLookup = (environmentsById: ResourcesById<EnvironmentResource>, environmentName: string) => (items: string[]) => items.filter((id) => environmentsById[id].Name.toLocaleLowerCase().indexOf(environmentName) !== -1);
const filterCondition = <T extends any>(predicate: (items: T[]) => boolean, filter: (items: T[]) => T[]) => (items: T[]) => (predicate(items) ? filter(items) : items);

function filterByProvidedKeys<T>(getValue: (item: T) => string = identity) {
    return (lookup: { [key: string]: any }) => {
        return (items: T[]) => items.filter((x) => lookup.hasOwnProperty(getValue(x)));
    };
}

const filterByKnownEnvironments = filterByProvidedKeys<string>();

export class DeployButtonController extends React.Component<DeployButtonControllerProps, DeployButtonControllerState> {
    constructor(props: DeployButtonControllerProps) {
        super(props);

        this.state = {
            filterText: null!,
            open: false,
        };
    }

    handleButtonClick = () => {
        this.setState({
            open: true,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    updateFilter = (value: string) => {
        this.setState({ filterText: value.toLowerCase() });
    };

    render() {
        const deploymentLinks = routeLinks.project(this.props.projectSlug).release(this.props.releaseVersion).deployments;
        const getEnvironmentLink = (environment?: string) => deploymentLinks.create(environment ? DeploymentCreateGoal.To : null!, environment);
        const canDeployToMultipleEnvironments = this.props.tenantedDeploymentMode !== TenantedDeploymentMode.Tenanted;
        const nextDeployments = this.props.nextDeployments || [];
        const filterEnvironmentsByName = filterCondition((items) => items.length > 1 && !!this.state.filterText, filterEnvironmentsByLookup(this.props.environmentsById, this.state.filterText!));
        const getEnvironment = (id: string) => this.props.environmentsById[id];
        const filterAuthorizedEnvironments = this.props.checkAuthorized
            ? createEnvironmentAuthFilter(this.props.checkAuthorized)
            : createEnvironmentAuthFilter((permission, projectId, environmentId) => session!.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({ permission, environmentId, projectId, tenantId: "*" }));

        const visibleEnvironments = compose(filterByKnownEnvironments(this.props.environmentsById), filterAuthorizedEnvironments(Permission.DeploymentCreate, this.props.projectId))(nextDeployments);

        const environments = filterEnvironmentsByName(visibleEnvironments);
        const Component = this.props.component || DeployButton;

        return (
            <Component
                visibleEnvironments={visibleEnvironments}
                environments={environments}
                getEnvironmentLink={getEnvironmentLink}
                onFilterChange={this.updateFilter}
                canDeployToMultipleEnvironments={canDeployToMultipleEnvironments}
                open={this.state.open}
                getEnvironment={getEnvironment}
                filter={this.state.filterText!}
                onClose={this.handleRequestClose}
                onClick={this.handleButtonClick}
            />
        );
    }
}

export const DeployButton: React.FC<DeployButtonProps> = (props) => {
    return withTheme((theme) => {
        const { canDeployToMultipleEnvironments, environments, getEnvironmentLink, getEnvironment, visibleEnvironments } = props;

        if (visibleEnvironments.length === 0) {
            return null;
        } else if (visibleEnvironments.length === 1) {
            const environment = getEnvironment(head(environments)!);

            return (
                <InternalLink to={getEnvironmentLink(head(environments))}>
                    <RaisedButton {...createDeployButtonStyle(theme)} label={`Deploy to ${environment ? environment.Name : ""}...`} />
                </InternalLink>
            );
        }

        if (visibleEnvironments.length > 1) {
            return (
                <DropdownButton label={"Deploy to..."} open={props.open} type={ActionButtonType.Primary} onClose={props.onClose} onClick={props.onClick}>
                    {(renderMenuProps) => (
                        //We have to disable auto focusing behavior in order to get the filter search box to focus
                        <DropdownButtonMenu {...renderMenuProps.getMenuProps({ disableAutoFocus: true, disableAutoFocusItem: true, disableRestoreFocus: true, disableEnforceFocus: true })}>
                            {canDeployToMultipleEnvironments && visibleEnvironments.length === 2 && (
                                <DropdownButtonMenuItem key="deploy-to-both" {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.navigation.navigate(getEnvironmentLink(environments.join(","))) })}>
                                    Deploy to both environments...
                                </DropdownButtonMenuItem>
                            )}
                            {canDeployToMultipleEnvironments && visibleEnvironments.length > 2 && (
                                <DropdownButtonMenuItem key="deploy-to-all" {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.navigation.navigate(getEnvironmentLink(environments.join(","))) })}>
                                    {`Deploy to all ${environments.length} environments...`}
                                </DropdownButtonMenuItem>
                            )}
                            {canDeployToMultipleEnvironments && (
                                <div key="filter">
                                    <Divider />
                                    <div className={styles.deployButtonFilterContainer}>
                                        <FilterSearchBox
                                            autoFocus={true}
                                            value={props.filter}
                                            placeholder="Filter..."
                                            onKeyDown={(e) => {
                                                //There is special handling for keydown events in menu items so we have to stop this from propagating.
                                                e.stopPropagation();
                                            }}
                                            onChange={props.onFilterChange}
                                            fullWidth={true}
                                            containerClassName={styles.filterFieldContainer}
                                            iconColor={theme.whiteConstant}
                                            iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                                        />
                                    </div>
                                </div>
                            )}
                            {environments.map((e: string) => {
                                const environment = getEnvironment(e);
                                return (
                                    <DropdownButtonMenuItem key={environment.Name} {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.navigation.navigate(getEnvironmentLink(e)) })}>
                                        {environment.Name}
                                    </DropdownButtonMenuItem>
                                );
                            })}
                        </DropdownButtonMenu>
                    )}
                </DropdownButton>
            );
        }
    });
};

export default DeployButtonController;
