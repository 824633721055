import * as React from "react";
import { DataBaseComponentState } from "components/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import { actions as dialogActions, selectors as dialogSelectors } from "components/Dialog/store";
import { connect } from "react-redux";
import { ActionButton } from "components/Button/ActionButton";
import ExampleDialogContent from "components/StyleGuide/ExampleDialogContent";
import Dialog from "components/Dialog/Dialog";
import { Dispatch } from "redux";

interface ExampleComponentThatShowsDialogsProps {
    someProp?: boolean;
}

interface ExampleComponentThatShowsDialogsState extends DataBaseComponentState {
    someState?: string;
}

interface GlobalDispatchProps {
    openDialog(id: string): void;
}

interface StateProps {
    dialogAOpen: boolean;
    dialogBOpen: boolean;
}

type Props = ExampleComponentThatShowsDialogsProps & StateProps & GlobalDispatchProps;

class ExampleComponentThatShowsDialogsInternal extends DataBaseComponent<Props, ExampleComponentThatShowsDialogsState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    doSomeProcess() {
        // Do stuff
        this.props.openDialog(Math.random() < 0.5 ? "DialogA" : "DialogB");
    }

    render() {
        return (
            <div>
                <ActionButton onClick={() => this.doSomeProcess()} label="Do a process that opens a dialog" />
                <Dialog open={this.props.dialogAOpen}>
                    <ExampleDialogContent title="Dialog A" />
                </Dialog>
                <Dialog open={this.props.dialogBOpen}>
                    <ExampleDialogContent title="Dialog B" />
                </Dialog>
            </div>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        dialogAOpen: dialogSelectors.createDialogOpenSelector("DialogA")(state),
        dialogBOpen: dialogSelectors.createDialogOpenSelector("DialogB")(state),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => {
    return {
        openDialog: (key: string) => {
            dispatch(dialogActions.open(key));
        },
    };
};

const ExampleComponentThatShowsDialogs = connect<StateProps, GlobalDispatchProps, ExampleComponentThatShowsDialogsProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExampleComponentThatShowsDialogsInternal);

export default ExampleComponentThatShowsDialogs;
