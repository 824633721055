/* eslint-disable @typescript-eslint/no-non-null-assertion */
import routeLinks from "../../../routeLinks";
import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation, SetPrimaryPackageReference } from "../../../client/resources";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import FeedResource from "client/resources/feedResource";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { ExpandableFormSection, FormSectionHeading } from "components/form";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import { DisplayFeedName } from "../DisplayFeedName";
import { TargetRoles } from "areas/projects/components/Process/types";
import { GetPrimaryPackageReference, InitialisePrimaryPackageReference } from "../../../client/resources";
import Callout, { CalloutType } from "components/Callout";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { PackageSelectionMode } from "client/resources/packageReference";
import { ActionWithFeeds, LoadFeedsFromOptionalContext } from "../commonActionHelpers";
import { WithOptionalProcessContextInjectedProps, withOptionalProcessContext } from "areas/projects/components/Process/Contexts/ProcessContext";

interface VhdActionSummaryState {
    feedName?: string;
}

class VhdActionSummary extends BaseComponent<ActionSummaryProps, VhdActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages!);
        return pkg ? (
            <div>
                {"Deploy VHD"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface VhdProperties {
    "Octopus.Action.Package.PackageId": string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VhdActionEditState extends ActionWithFeeds {}

type Props = ActionEditProps<VhdProperties> & WithOptionalProcessContextInjectedProps;

class VhdActionEditInternal extends BaseComponent<Props, VhdActionEditState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            feeds: [],
        };
    }

    async componentDidMount() {
        await LoadFeedsFromOptionalContext(this.props.doBusyTask, this.props.processContext, (feeds: FeedResource[]) => {
            this.setState({ feeds }, () => {
                this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds));
            });
        });
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);
        const feed = _.find(this.state.feeds, (f) => f.Id === pkg.FeedId);

        const help =
            this.state.feeds.length > 0 ? (
                <span>
                    This step is used to deploy a VHD to one or more machines. The machines require Windows Server 2012 or newer. You can configure the remote machines to deploy to in the{" "}
                    <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>
                        Infrastructure
                    </InternalLink>{" "}
                    tab. The package that you wish to deploy should contain a single VHD or VHDX file in the root of the package. Learn more about{" "}
                    <ExternalLink href="DocumentationPackaging">what your packages should contain, and how to create them</ExternalLink>.
                </span>
            ) : (
                <span>Choose the package you which to deploy</span>
            );

        return (
            <div>
                <FormSectionHeading title="Package Details" />
                <ExpandableFormSection errorKey="package" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.state.feeds)} help={help}>
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <PackageDownloadOptions
                            packageAcquisitionLocation={pkg.AcquisitionLocation}
                            onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                            feed={feed}
                            projectId={this.props.projectId}
                            localNames={this.props.localNames}
                        />
                    )}
                </ExpandableFormSection>
            </div>
        );
    }

    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };
}

export const VhdActionEdit = withOptionalProcessContext(VhdActionEditInternal);

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.Vhd",
    summary: (properties, targetRolesAsCSV, packages) => <VhdActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: VhdActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        permanent: ["Octopus.Features.Vhd"],
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
        ],
        initial: ["Octopus.Features.SubstituteInFiles"],
    },
});
