import * as React from "react";
import List from "components/List";
import { ResourceCollection } from "client/resources";
import { MachinePolicyResource } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import MachinePolicySummary from "../MachinePolicySummary";
import Chip from "components/Chips/Chip";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import FormPage from "components/FormPage/FormPage";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

class MachinePolicyList extends List<MachinePolicyResource> {}

interface MachinePoliciesLayoutProps extends RouteComponentProps<void> {
    initialData: InitialData;
}

interface InitialData {
    machinePoliciesResponse: ResourceCollection<MachinePolicyResource>;
}

const Title = "Machine Policies";
const MachinePoliciesFormPage = FormPage<InitialData>();
const MachinePoliciesLayout: React.FC<RouteComponentProps<void>> = (props: RouteComponentProps<void>) => {
    return (
        <MachinePoliciesFormPage
            title={Title}
            load={async () => {
                const machinePoliciesResponse = repository.MachinePolicies.list();

                return { machinePoliciesResponse: await machinePoliciesResponse };
            }}
            renderWhenLoaded={(data) => <MachinePoliciesLayoutInner initialData={data} {...props} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class MachinePoliciesLayoutInner extends DataBaseComponent<MachinePoliciesLayoutProps, DataBaseComponentState> {
    constructor(props: MachinePoliciesLayoutProps) {
        super(props);
        this.state = {};
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.MachinePolicyCreate}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add machine policy" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );
        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout busy={this.state.busy} errors={this.errors} title={Title} sectionControl={addButton}>
                    <MachinePolicyList
                        initialData={this.props.initialData.machinePoliciesResponse}
                        onRow={(item: MachinePolicyResource) => this.buildMachinePolicyRow(item)}
                        onFilter={this.filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        match={this.props.match}
                        filterHintText="Filter by name..."
                        showPagingInNumberedStyle={false}
                    />
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    private filter(filter: string, resource: MachinePolicyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildMachinePolicyRow(machinePolicy: MachinePolicyResource) {
        return (
            <div>
                <ListTitle>
                    {machinePolicy.Name}
                    {machinePolicy.IsDefault && (
                        <span>
                            {" "}
                            <Chip>default</Chip>
                        </span>
                    )}
                </ListTitle>
                <MachinePolicySummary machinePolicy={machinePolicy} hideDescription={false} conciseView={false} />
            </div>
        );
    }
}

export default MachinePoliciesLayout;
