/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { WithRunbookContextInjectedProps, withRunbookContext } from "./RunbookContext";
import routeLinks from "routeLinks";
import RunbooksPaperLayout from "./Layouts/RunbooksPaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import ActionList from "components/ActionList/ActionList";
import RunNowButton from "./RunNowButton";
import { RunbookRouteProps } from "./RunbookSnapshots/RunbookSnapshots";
import { isEqual } from "lodash";
import { RunbookSnapshotResource, RunbooksDashboardItemResource, TaskResource, IsNonVcsRunbook } from "client/resources";
import PublishButton from "./PublishButton";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import { FormTitleForRunbook } from "./LastPublishedChip";
import InternalLink from "components/Navigation/InternalLink";
import TaskDetails from "components/TaskDetails";
const styles = require("./RunbookRunsListLayout.less");

type RunbookRunsListProps = RouteComponentProps<ProjectRouteParams & RunbookRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

interface RunbookRunsListState extends DataBaseComponentState {
    hasSteps: boolean;
    publishedRunbookSnapshot: RunbookSnapshotResource;
    dashboardItems: RunbooksDashboardItemResource[];
}

class RunbookRunsListLayout extends DataBaseComponent<RunbookRunsListProps, RunbookRunsListState> {
    constructor(props: RunbookRunsListProps) {
        super(props);
        this.state = {
            hasSteps: false,
            publishedRunbookSnapshot: null!,
            dashboardItems: [],
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(prevProps: RunbookRunsListProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        await this.doBusyTask(async () => {
            const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
            if (!runbook) {
                return;
            }
            if (!IsNonVcsRunbook(runbook)) {
                throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
            }
            const [runbookProcess, publishedRunbookSnapshot] = await Promise.all([
                repository.RunbookProcess.get(runbook.RunbookProcessId),
                runbook.PublishedRunbookSnapshotId ? repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : Promise.resolve(null!),
            ]);
            this.setState({
                hasSteps: runbookProcess.Steps.length > 0,
                publishedRunbookSnapshot,
            });
        });
    }

    render() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!project || !runbook || this.errors) {
            return <RunbooksPaperLayout busy={true} errors={this.errors} />;
        }

        const sectionControlActions = [];
        if (this.state.hasSteps) {
            sectionControlActions.push(<PublishButton doBusyTask={this.doBusyTask} />);
        }
        sectionControlActions.push(<RunNowButton isDisabled={!this.state.hasSteps} />);
        const sectionControl = <ActionList actions={sectionControlActions} />;
        const layoutTitle = <FormTitleForRunbook runbookName={runbook.Name} />;
        return (
            <Tasks
                {...this.props}
                restrictToProjectId={project.Id}
                restrictToRunbookId={runbook.Id}
                hideScriptConsoleAction={true}
                renderLayout={(layoutProps) => {
                    return (
                        <RunbooksPaperLayout title={layoutTitle} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(project.Slug).operations.runbooks} sectionControl={sectionControl} busy={layoutProps.busy} errors={layoutProps.errors}>
                            <div className={styles.layoutContainer}>{layoutProps.children}</div>
                        </RunbooksPaperLayout>
                    );
                }}
                onNewItems={async (tasks: Array<TaskResource<any>>) => {
                    await this.loadRunbookRunsAndSnapshots(tasks);
                    return tasks;
                }}
                renderCell={(cellProps) => {
                    const task = cellProps.task;
                    const dashboardItem = this.state.dashboardItems.find((x) => x.TaskId === task.Id);
                    if (dashboardItem) {
                        return <RunbookTaskStatusDetails project={project} item={dashboardItem} />;
                    }
                    // Permission edge-case: We fall back to the regular task-cell for cases where scopings on the user's permissions
                    // stop them from seeing dashboard items related to this task.
                    return (
                        <InternalLink to={(!!task.SpaceId ? routeLinks.forSpace(task.SpaceId) : routeLinks).task(task).root}>
                            <TaskDetails task={task} stripTopBottomPadding={true} />
                        </InternalLink>
                    );
                }}
            />
        );
    }

    private async loadRunbookRunsAndSnapshots(task: Array<TaskResource<any>>) {
        const taskIds = task.map((x) => x.Id);
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
        }
        const dashboardItemsCollection = await repository.Progression.getTaskRunDashboardItemsForRunbook(runbook!, { taskIds, take: repository.takeAll });
        this.setState({ dashboardItems: dashboardItemsCollection.Items });
    }
}

const EnhancedRunbookRunsListLayout = withRunbookContext(withProjectContext(RunbookRunsListLayout));
export default EnhancedRunbookRunsListLayout;
