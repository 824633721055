/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { Dictionary } from "lodash";
import { RouteComponentProps } from "react-router";
import { LibraryVariableSetResource } from "client/resources/libraryVariableSetResource";
import { VariableSetResource } from "client/resources/variableSetResource";
import { repository } from "clientInstance";
import { LibraryLayout } from "areas/library/components/LibraryLayout/LibraryLayout";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { VariableSaveConfirmationContent, default as VariableSaveConfirmationDialog } from "areas/variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import { UrlNavigationTabsContainer } from "components/Tabs";
import TabItem from "components/Tabs/TabItem";
import VariableSetSettings from "areas/library/components/VariableSets/VariableSetSettings";
import VariableSetTemplates from "areas/library/components/VariableSets/VariableSetTemplates";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { Permission } from "client/resources";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { VariableModel } from "../../../variables/VariablesModel/VariablesModel";
import FormBaseComponent from "../../../../components/FormBaseComponent";
import { OptionalFormBaseComponentState } from "../../../../components/FormBaseComponent/FormBaseComponent";
import { createDialogContent, createViewModel, getVariableResources } from "../../../variables/VariableEditor/conversions";
import ReadonlyVariableResource from "../../../variables/ReadonlyVariableResource";
import VariableEditor from "areas/variables/VariableEditor/VariableEditor";
import groupVariablesByName from "../../../variables/groupVariablesByName";
import { connect } from "react-redux";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import { ExpandableFormSection } from "components/form";
import { VariableSetProjectUsages, variableSetProjectUsageSummary, variableSetProjectUsageHelp } from "./VariableSetProjectUsage";
import { VariableSetReleaseUsages, variableSetReleaseUsageHelp, variableSetReleaseUsageSummary, VariableSetRunbookUsages, variableSetRunbookUsageHelp, variableSetRunbookSnapshotUsageSummary } from "./VariableSetReleaseUsage";
import LibraryVariableSetUsageResource, { ProjectUsage } from "client/resources/libraryVariableSetUsageResource";

interface VariableSetRouteParams {
    variableSetId: string;
}

interface VariableSetState extends OptionalFormBaseComponentState<VariableSetModel> {
    dialogContent?: VariableSaveConfirmationContent;
    libraryVariableSet: LibraryVariableSetResource;
    hasBeenDeleted: boolean;
    initialVariables?: ReadonlyArray<VariableModel>;
    groupedVariableResources?: Dictionary<ReadonlyVariableResource[]>;
    variableSet?: VariableSetResource;
    relativeColumnWidths?: ReadonlyArray<number>;
    cellFocusResetKey: string;
    usages: LibraryVariableSetUsageResource;
    usagesInProjects: ProjectUsage[];
    usagesInReleaseSnapshots: ProjectUsage[];
    usagesInRunbookSnapshots: ProjectUsage[];
}

interface VariableSetModel {
    readonly variables: ReadonlyArray<VariableModel>;
    readonly libraryVariableSet: LibraryVariableSetResource;
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

type Props = RouteComponentProps<VariableSetRouteParams> & GlobalConnectedProps;

class VariableSet extends FormBaseComponent<Props, VariableSetState, VariableSetModel> {
    constructor(props: Props) {
        super(props);
        this.state = {
            libraryVariableSet: null!,
            hasBeenDeleted: false,
            cellFocusResetKey: DateFormatter.timestamp(),
            usages: null!,
            usagesInProjects: [],
            usagesInReleaseSnapshots: [],
            usagesInRunbookSnapshots: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.load());
    }

    render() {
        const title = this.state.model ? this.state.model.libraryVariableSet.Name : StringHelper.ellipsis;

        const overFlowActions = this.state.model
            ? [
                  OverflowMenuItems.deleteItemDefault("variable set", () => this.handleDeleteConfirm(), { permission: Permission.LibraryVariableSetDelete }),
                  [
                      OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.libraryVariableSet.Id]), null!, {
                          permission: Permission.EventView,
                          wildcard: true,
                      }),
                  ],
              ]
            : [];
        return (
            <LibraryLayout {...this.props}>
                <FormPaperLayout
                    busy={this.state.busy}
                    errors={this.errors}
                    fullWidth={true}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    hideExpandAll={true}
                    title={title}
                    breadcrumbTitle={"Variable Sets"}
                    breadcrumbPath={routeLinks.library.variableSets}
                    savePermission={{ permission: Permission.LibraryVariableSetEdit, environment: "*", tenant: "*" }}
                    onSaveClick={() => {
                        const dialogContent = createDialogContent(this.state.model!.variables, this.state.initialVariables!, this.state.variableSet!.Variables);

                        if (dialogContent && dialogContent.hasContent) {
                            this.setState({ dialogContent });
                        } else {
                            return this.doBusyTask(() => this.save());
                        }
                    }}
                    overFlowActions={overFlowActions}
                >
                    {this.state.hasBeenDeleted && <InternalRedirect to={routeLinks.library.variableSets} />}
                    <UrlNavigationTabsContainer defaultValue="variables">
                        <TabItem label="Variables" value="variables">
                            {this.state.model && (
                                <VariableEditor
                                    initialVariables={this.state.initialVariables!}
                                    scopeValues={this.state.variableSet!.ScopeValues}
                                    isProjectScoped={false}
                                    isTenanted={this.props.isMultiTenancyEnabled}
                                    doBusyTask={this.doBusyTask}
                                    onVariablesChanged={(variables) => this.setState((prevState) => ({ model: { ...prevState.model, variables } }))}
                                    cellFocusResetKey={this.state.cellFocusResetKey}
                                />
                            )}
                        </TabItem>
                        <TabItem label="Variable Templates" value="variableTemplates">
                            {this.state.model && (
                                <VariableSetTemplates
                                    parameters={this.state.model.libraryVariableSet.Templates}
                                    onParametersChanged={(Templates) =>
                                        this.setState((prev) => ({
                                            model: {
                                                ...prev.model,
                                                libraryVariableSet: {
                                                    ...prev.model!.libraryVariableSet,
                                                    Templates,
                                                },
                                            },
                                        }))
                                    }
                                />
                            )}
                        </TabItem>
                        <TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                            {this.state.usages && (
                                <ExpandableFormSection
                                    key="usageInProjects"
                                    errorKey="usageInProjects"
                                    title="Projects"
                                    expandable={this.state.usagesInProjects.length > 0}
                                    summary={variableSetProjectUsageSummary(this.state.usagesInProjects, this.state.usages.CountOfProjectsUserCannotSee)}
                                    help={variableSetProjectUsageHelp(this.state.usagesInProjects, this.state.usages.CountOfProjectsUserCannotSee)}
                                >
                                    <VariableSetProjectUsages usage={this.state.usagesInProjects} />
                                </ExpandableFormSection>
                            )}
                            {this.state.usages && (
                                <ExpandableFormSection
                                    key="usageInReleaseSnapshots"
                                    errorKey="usageInReleaseSnapshots"
                                    title="Releases"
                                    expandable={this.state.usagesInReleaseSnapshots.length > 0}
                                    summary={variableSetReleaseUsageSummary(this.state.usagesInReleaseSnapshots, this.state.usages.CountOfReleasesUserCannotSee)}
                                    help={variableSetReleaseUsageHelp(this.state.usagesInReleaseSnapshots, this.state.usages.CountOfReleasesUserCannotSee)}
                                >
                                    <VariableSetReleaseUsages usage={this.state.usagesInReleaseSnapshots} />
                                </ExpandableFormSection>
                            )}
                            {this.state.usages && (
                                <ExpandableFormSection
                                    key="usagesInRunbookSnapshots"
                                    errorKey="usagesInRunbookSnapshots"
                                    title="Runbook Snapshots"
                                    expandable={this.state.usagesInRunbookSnapshots.length > 0}
                                    summary={variableSetRunbookSnapshotUsageSummary(this.state.usagesInRunbookSnapshots, this.state.usages.CountOfRunbookSnapshotsUserCannotSee)}
                                    help={variableSetRunbookUsageHelp(this.state.usagesInRunbookSnapshots, this.state.usages.CountOfRunbookSnapshotsUserCannotSee)}
                                >
                                    <VariableSetRunbookUsages usage={this.state.usagesInRunbookSnapshots} />
                                </ExpandableFormSection>
                            )}
                        </TabItem>
                        <TabItem label="Settings" value="settings">
                            {this.state.model && (
                                <VariableSetSettings
                                    errors={this.errors!}
                                    libraryVariableSet={this.state.model.libraryVariableSet}
                                    onLibraryVariableSetChanged={(libraryVariableSet) => {
                                        this.setState((prev) => ({
                                            model: {
                                                ...prev.model,
                                                libraryVariableSet,
                                            },
                                        }));
                                    }}
                                />
                            )}
                        </TabItem>
                    </UrlNavigationTabsContainer>
                    <VariableSaveConfirmationDialog content={this.state.dialogContent} onClosed={() => this.setState({ dialogContent: null! })} onSaveClick={() => this.save()} />
                </FormPaperLayout>
            </LibraryLayout>
        );
    }

    private async load() {
        const libraryVariableSet = await repository.LibraryVariableSets.get(this.props.match.params.variableSetId);
        const variableSet = await repository.Variables.get(libraryVariableSet.VariableSetId);

        const groupedVariableResources = groupVariablesByName(variableSet.Variables, (v) => v.Name);
        const variables = createViewModel(groupedVariableResources);
        const model: VariableSetModel = { variables, libraryVariableSet };

        this.setState({
            libraryVariableSet,
            variableSet,
            groupedVariableResources,
            initialVariables: [...variables],
            model,
            cleanModel: { ...model },
            cellFocusResetKey: DateFormatter.timestamp(),
        });
    }

    private async onUsageTabActive() {
        if (this.state.usages) {
            return;
        }

        await this.doBusyTask(async () => {
            const libraryVariableSet = this.state.libraryVariableSet ? this.state.libraryVariableSet : await repository.LibraryVariableSets.get(this.props.match.params.variableSetId);
            const usages = await repository.LibraryVariableSets.getUsages(libraryVariableSet);
            const usagesInProjects = usages.Projects.filter((x) => x.IsCurrentlyBeingUsedInProject === true);
            const usagesInReleaseSnapshots = usages.Projects.filter((x) => x.Releases.length > 0);
            const usagesInRunbookSnapshots = usages.Projects.filter((x) => x.RunbookSnapshots.length > 0);

            this.setState({
                usages,
                usagesInProjects,
                usagesInReleaseSnapshots,
                usagesInRunbookSnapshots,
            });
        });
    }

    private async save() {
        await this.doBusyTask(async () => {
            const variableSet = { ...this.state.variableSet!, Variables: getVariableResources(this.state.model!.variables, this.state.groupedVariableResources!) };
            const librarySetSaveResult = await repository.LibraryVariableSets.modify(this.state.model!.libraryVariableSet);
            const variableSaveResult = librarySetSaveResult && (await repository.Variables.modify(variableSet));

            if (librarySetSaveResult && variableSaveResult) {
                // reload everything, as the result from the save isn't in the exact format we need, old portal did the same
                return this.load();
            }
        });
    }

    private async handleDeleteConfirm() {
        await this.doBusyTask(async () => {
            await repository.LibraryVariableSets.del(this.state.model!.libraryVariableSet);
            this.setState({ hasBeenDeleted: true });
        });
        return true;
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: RouteComponentProps<VariableSetRouteParams>): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default connect(mapGlobalStateToProps)(VariableSet);
