import * as React from "react";
import { SFC } from "react";
import moment from "moment";

interface DurationLabelProps {
    duration: string;
}

const DurationLabel: SFC<DurationLabelProps> = (props) => {
    const duration = moment.duration(props.duration);
    return <span title={props.duration}>{duration.humanize()}</span>;
};

export default DurationLabel;
