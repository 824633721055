import * as React from "react";
import { Switch } from "react-router-dom";
import { Projects } from "../Projects";
import { ProjectLayout } from "../ProjectLayout";
import { TriggersRoute } from "../Triggers";
import VariablesRoute from "../Variables/VariablesRoute";
import ProjectSettingsRoute from "../ProjectSettings/ProjectSettingsRoute";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { RouteComponentProps } from "react-router";
import * as H from "history";
import AreaTitle from "components/AreaTitle/index";
import { repository } from "clientInstance";
import SlugSafeRedirect from "components/SlugSafeRedirect/SlugSafeRedirect";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout";
import { RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import DeploymentsRoute from "../DeploymentsRoute";
import { generatePath } from "react-router";
import OperationsRoute from "../OperationsRoute";
import ProjectTasks from "../ProjectTasks/ProjectTasks";
import { ProjectToOverviewRedirect } from "./ProjectToOverviewRedirect";
import { ProcessType } from "client/resources";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import DeploymentProcessRoute from "../Process/Routing/ProcessRoute";
import { EnhancedRunbookRoutesWithBranching as RunbookRoutesWithBranching } from "../Runbooks/RunbookRoutesWithBranching";
import { EnhancedRunbooksRoute as RunbooksRoute } from "../Runbooks/RunbooksRoute";

const ProjectTasksPage = withPage({ page: pageIds.project().tasks })(ProjectTasks);
const ProjectsPage = withPage({ page: pageIds.projects.root })(Projects);

export class ProjectRoutes extends React.Component<RouteComponentProps> {
    async loadSlugFromProjectId(projectId: string, location: H.Location) {
        if (location.state && location.state.project) {
            return location.state.project.Slug;
        }
        return (await repository.Projects.get(projectId)).Slug;
    }

    projectSlugLoading() {
        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.projects.root} title="Projects" />
                <PaperLayout busy={true} fullWidth={true} />
            </main>
        );
    }

    buildProjectRoutes() {
        const projectRouteLinks = routeLinks.projectRoutes();
        const branchRouteLinks = routeLinks.projectBranchRoutes();

        const branchRoutes = (
            <ReloadableRoute path={branchRouteLinks.root} doNotReloadWhenTheseKeysChange={["branchName"]}>
                {(routeProps: RouteComponentProps<ProjectRouteParams>) => {
                    return (
                        <SlugSafeRedirect parameter={"projectSlug"} regexp={/^Projects-[0-9]+$/} getRealParam={this.loadSlugFromProjectId} loadingComponent={this.projectSlugLoading}>
                            <ProjectLayout branchName={routeProps.match.params.branchName} projectSlug={routeProps.match.params.projectSlug}>
                                <Switch>
                                    <DeploymentProcessRoute path={branchRouteLinks.deploymentProcess.root} processType={ProcessType.Deployment} />
                                    <RunbookRoutesWithBranching path={branchRouteLinks.runbook} />
                                    <RunbooksRoute path={branchRouteLinks.runbooks} />
                                </Switch>
                            </ProjectLayout>
                        </SlugSafeRedirect>
                    );
                }}
            </ReloadableRoute>
        );

        const projectRoutes = (
            <ReloadableRoute path={projectRouteLinks.root}>
                {(routeProps: RouteComponentProps<ProjectRouteParams>) => {
                    return (
                        <SlugSafeRedirect parameter={"projectSlug"} regexp={/^Projects-[0-9]+$/} getRealParam={this.loadSlugFromProjectId} loadingComponent={this.projectSlugLoading}>
                            <ProjectLayout branchName={routeProps.match.params.branchName} projectSlug={routeProps.match.params.projectSlug}>
                                <Switch>
                                    <ReloadableRoute path={projectRouteLinks.tasks} component={ProjectTasksPage} />
                                    <ReloadableRoute path={projectRouteLinks.settings.root} component={ProjectSettingsRoute} />
                                    <ReloadableRoute path={projectRouteLinks.triggers} component={TriggersRoute} />
                                    <ReloadableRoute path={projectRouteLinks.deployments.root} component={DeploymentsRoute} />
                                    <OperationsRoute path={projectRouteLinks.operations.root} />
                                    <ReloadableRoute path={projectRouteLinks.variables.root} component={VariablesRoute} />
                                    <ReloadableRoute
                                        path={projectRouteLinks.stepTemplates}
                                        render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.stepTemplates} />}
                                    />
                                    <ReloadableRoute
                                        exact={true}
                                        path={projectRouteLinks.overview}
                                        render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.root} />}
                                    />
                                    <ReloadableRoute exact={true} path={projectRouteLinks.root} render={(props: RouteComponentProps<ProjectRouteParams>) => <ProjectToOverviewRedirect />} />
                                    <ReloadableRoute
                                        path={projectRouteLinks.childStepTemplates(":parentStepId").root}
                                        render={(props: RouteComponentProps<ProjectRouteParams & { parentStepId: string }>) => (
                                            <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.childStepTemplates(props.match.params.parentStepId).root} />
                                        )}
                                    />
                                    <ReloadableRoute
                                        path={`${projectRouteLinks.channels}*`}
                                        render={(props: RouteComponentProps) => {
                                            //Rewrite any old release based routes from project/releases to project/deployments/releases
                                            const rewrite = props.match.path.replace(projectRouteLinks.channels, projectRouteLinks.deployments.channels);
                                            const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                                            return <InternalRedirect to={destination} />;
                                        }}
                                    />
                                    <ReloadableRoute
                                        path={`${projectRouteLinks.releases}*`}
                                        render={(props: RouteComponentProps) => {
                                            //Rewrite any old release based routes from project/releases to project/deployments/releases
                                            const rewrite = props.match.path.replace(projectRouteLinks.releases, projectRouteLinks.deployments.releases);
                                            const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                                            return <InternalRedirect to={destination} />;
                                        }}
                                    />
                                    <ReloadableRoute
                                        path={`${projectRouteLinks.deployments.process.root}*`}
                                        render={(props: RouteComponentProps) => {
                                            //Rewrite any old process links to the new route
                                            const rewrite = props.match.path.replace(projectRouteLinks.deployments.process.root, projectRouteLinks.deployments.process.root);
                                            const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                                            return <InternalRedirect to={destination} />;
                                        }}
                                    />
                                    <RedirectAs404 />
                                </Switch>
                            </ProjectLayout>
                        </SlugSafeRedirect>
                    );
                }}
            </ReloadableRoute>
        );

        return [branchRoutes, projectRoutes];
    }

    render() {
        const projectRoutes = this.buildProjectRoutes();

        return (
            <Switch>
                <ReloadableRoute path={routeLinks.v3projectRoutes.newProject}>
                    <InternalRedirect to={routeLinks.projects.root} />
                </ReloadableRoute>
                {projectRoutes}
                <ReloadableRoute path={routeLinks.projects.root} render={() => <ProjectsPage />} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

export default ProjectRoutes;
