import { filter, join, trimEnd } from "lodash";

export interface CommitMessageWithDetails {
    summary: string;
    details: string;
}

export function getFormattedCommitMessage(commitMessage: CommitMessageWithDetails, defaultSummary: string) {
    const summary = commitMessage.summary.trim() === "" ? defaultSummary : commitMessage.summary;
    const formattedMessage = join(filter([summary, trimEnd(commitMessage.details)]), "\n\n");
    return formattedMessage;
}
