/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
const styles = require("./style.less");
import { CloseButton } from "components/Button";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Note from "components/form/Note/Note";
import SmoothScroll from "components/SmoothScroll/SmoothScroll";

type InformationPanelProps = InformationListProps &
    InformationTitleProps &
    InformationHelpProps & {
        fullWidth?: boolean;
        scrollToPanel?: boolean;
    };

type InformationDetailProps = {
    notice: string;
    index: number;
    parsedHelpLinks?: string[];
};

const InformationDetail: React.FC<InformationDetailProps> = ({ notice, index, parsedHelpLinks }) => {
    const detailLinks = parsedHelpLinks;
    const detailLink = detailLinks && detailLinks.length > index && detailLinks[index];
    return (
        <li key={index}>
            {notice}
            {detailLink && (
                <div className={styles.detailLink}>
                    <ExternalLink href={detailLink}>More information...</ExternalLink>
                </div>
            )}
        </li>
    );
};

type InformationListProps = {
    notices: string[];
    parsedHelpLinks?: string[];
};

const InformationsList: React.FC<InformationListProps> = (props) => {
    return (
        <React.Fragment>
            {props.notices.length > 0 && (
                <ul>
                    {props.notices.map((e, index) => (
                        <InformationDetail index={index} notice={e} parsedHelpLinks={props.parsedHelpLinks} />
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
};

type InformationTitleProps = {
    canClose?: boolean;
    onInformationClose?: () => void;
    message: string;
};

const InformationTitle: React.FC<InformationTitleProps> = ({ onInformationClose, canClose, message }) => {
    return (
        <div className={styles.titleContainer}>
            <span className={styles.title}>{message}</span>
            <div>{canClose && <CloseButton size="1.5rem" onClose={onInformationClose} />}</div>
        </div>
    );
};

type InformationHelpProps = {
    helpText?: string;
    helpLink?: string;
};

const InformationHelp: React.FC<InformationHelpProps> = ({ helpLink, helpText }) => {
    return (
        <React.Fragment>
            {helpText && (
                <div className={styles.note}>
                    <Note>{helpText}</Note>
                </div>
            )}
            {helpLink && <ExternalLink href={helpLink}>More information...</ExternalLink>}
        </React.Fragment>
    );
};

const InformationPanel: React.FC<InformationPanelProps> = ({ fullWidth = true, scrollToPanel = true, ...props }) => {
    const panelRef = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (scrollToPanel && panelRef.current) {
            SmoothScroll.scrollTo(panelRef.current);
        }
    }, [panelRef, scrollToPanel]);

    return (
        <div
            ref={panelRef}
            className={styles.notice}
            style={{
                display: fullWidth ? "block" : "inline-block",
                width: fullWidth ? "100%" : "auto",
            }}
        >
            <InformationTitle message={props.message} canClose={props.canClose} onInformationClose={props.onInformationClose} />
            {props.notices && <InformationsList notices={props.notices} />}
            <InformationHelp helpLink={props.helpLink} helpText={props.helpText} />
        </div>
    );
};

export default InformationPanel;
