import releaseRouteLinks from "../Releases/ReleasesRoutes/releaseRouteLinks";
import { ProjectOrSummaryResource, VcsBranchResource } from "client/resources/projectResource";
import { DashboardProjectResource } from "client/resources/dashboardProjectResource";
import { channel, trigger, scheduledTrigger, runbook } from "../routeLinkHelpers";
import { deploymentLinks } from "../DeploymentsRoute";
import { opsRouteLinks, runbookLinks } from "../OperationsRoute";
import { BranchSpecifier, ShouldUseDefaultBranch } from "./BranchSpecifier";

export default (root: string) => ({
    v3projectRoutes: {
        newProject: `${root}/projects/new`,
    },
    projects: {
        root: `${root}/projects`,
        filteredByGroup: (projectGroupId: string) => `${root}/projects?projectGroupId=${projectGroupId}`,
    },
    trigger: trigger(root),
    channel: channel(root),
    release: (releaseId: string) => `${root}/releases/${releaseId}`,
    runbookSnapshot: (runbookSnapshotId: string) => `${root}/snapshots/${runbookSnapshotId}`, // Needed for audit-screen document redirection.
    runbook: runbook(root), // Needed for audit-screen document redirection.
    project: (projectSlug: string | ProjectOrSummaryResource | DashboardProjectResource) => projectRoutes(root, projectSlug),
    projectRoutes: () => projectRoutes(root, ":projectSlug"),
    projectBranch: (projectSlug: string, branch: BranchSpecifier | VcsBranchResource | undefined) => branchRoutes(root, projectSlug, branch),
    projectBranchRoutes: () => branchRoutes(root, ":projectSlug", ":branchName"),
});

const isVcsBranchResource = (branch: unknown): branch is VcsBranchResource => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (branch as VcsBranchResource).Name !== undefined;
};

const rootForBranchResource = (projectRootLink: string, branch: VcsBranchResource) => {
    return `${projectRootLink}/branches/${branch.Name}`;
};

const rootForBranchSpecifier = (projectRootLink: string, branch: BranchSpecifier) => {
    return ShouldUseDefaultBranch(branch) ? projectRootLink : `${projectRootLink}/branches/${branch}`;
};

const branchRoutes = (root: string, projectRoot: string, branch: BranchSpecifier | VcsBranchResource | undefined) => {
    const projectRootLink = projectRoutes(root, projectRoot).root;
    let branchRoot = projectRootLink;

    if (branch) {
        branchRoot = isVcsBranchResource(branch) ? rootForBranchResource(projectRootLink, branch) : rootForBranchSpecifier(projectRootLink, branch);
    }

    return {
        root: branchRoot,
        deploymentProcess: deploymentLinks(`${branchRoot}/deployments`).process,
        runbooks: opsRouteLinks(`${branchRoot}/operations`).runbooks,
        runbook: opsRouteLinks(`${branchRoot}/operations`).runbook(":runbookId").root,
    };
};

export type ProjectRouteLinks = ReturnType<typeof projectRoutes>;
function projectRoutes(root: string, projectSlug: string | ProjectOrSummaryResource | DashboardProjectResource) {
    if (!!projectSlug && typeof projectSlug !== "string") {
        projectSlug = projectSlug.Slug;
    }

    const projectRoot = `${root}/projects/${projectSlug}`;

    return {
        root: projectRoot,
        ...releaseRouteLinks(`${projectRoot}/releases`),
        overview: `${projectRoot}/overview`,
        settings: {
            root: `${projectRoot}/settings`,
            versionControl: `${projectRoot}/settings/versioncontrol`,
        },
        tasks: `${projectRoot}/tasks`,
        channels: `${projectRoot}/channels`,
        channel: channel(`${projectRoot}/channels`),
        operations: opsRouteLinks(`${projectRoot}/operations`),
        triggers: `${projectRoot}/triggers`,
        trigger: trigger(`${projectRoot}/triggers`),
        scheduledTrigger: scheduledTrigger(`${projectRoot}/triggers`),
        scheduledTriggers: {
            root: `${projectRoot}/triggers`,
            new: `${projectRoot}/triggers/scheduled/create`,
        },
        variables: {
            root: `${projectRoot}/variables`,
            projectTemplates: `${projectRoot}/variables/projectvariabletemplates`,
            commonTemplates: `${projectRoot}/variables/commonvariabletemplates`,
            library: `${projectRoot}/variables/library`,
            preview: `${projectRoot}/variables/preview`,
            all: `${projectRoot}/variables/all`,
        },
        vcsConnectivityCheck: `${projectRoot}/vcs/connectivityCheck`,
        deployments: deploymentLinks(`${projectRoot}/deployments`),
        childStepTemplates: (parentStepId: string) => ({
            root: `${projectRoot}?childStepTemplates=1&parentStepId=${parentStepId}`,
        }),
        stepTemplates: `${projectRoot}?stepTemplates=1`,
    };
}
