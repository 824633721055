import * as React from "react";
import { Switch, RouteComponentProps } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { repository } from "clientInstance";
import SlugSafeRedirect from "components/SlugSafeRedirect/SlugSafeRedirect";
import routeLinks from "routeLinks";
import StringHelper from "utils/StringHelper";
import { Releases } from "../Releases";
import { Release } from "../Release";
import Edit from "../Edit/Edit";
import DeploymentCreate from "../Deployments/Create";
import { DeploymentDetails } from "../Deployments/Details";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";

const releaseInstancePages = pageIds.project().release();

export const ReleasePage = withPage({ page: releaseInstancePages.root })(Release);
export const ReleaseDeploymentDetailsPage = withPage({ page: releaseInstancePages.deployments.specific })(DeploymentDetails);
export const CreateDeploymentPage = withPage({ page: releaseInstancePages.deployments.create })(DeploymentCreate);
export const CreateReleasePage = withPage({ page: pageIds.project().releaseCreate })(Edit);
export const ReleasesPage = withPage({ page: pageIds.project().releases })(Releases);
export const EditReleasePage = withPage({ page: releaseInstancePages.edit })(Edit);

export class ReleasesRoute extends React.Component<RouteComponentProps> {
    async loadVersionFromReleaseId(releaseId: string) {
        return (await repository.Releases.get(releaseId)).Version;
    }

    releaseVersionLoading() {
        return <div>{StringHelper.ellipsis}</div>;
    }

    render() {
        const projectLinks = routeLinks.projectRoutes();
        const releaseLinks = projectLinks.deployments.releases;
        const releaseInstanceLinks = projectLinks.deployments.release(`:releaseVersion`);

        return (
            <Switch>
                <ReloadableRoute
                    path={projectLinks.deployments.releaseCreate}
                    render={(routeProps: RouteComponentProps<{ channelId: string; releaseVersion: string }>) => <CreateReleasePage channelId={routeProps.match.params.channelId} releaseVersion={routeProps.match.params.releaseVersion} />}
                />
                <ReloadableRoute path={releaseInstanceLinks.root}>
                    <SlugSafeRedirect parameter="releaseVersion" regexp={/^Releases-[0-9]+$/} getRealParam={this.loadVersionFromReleaseId} loadingComponent={this.releaseVersionLoading}>
                        <Switch>
                            <ReloadableRoute
                                path={releaseInstanceLinks.edit}
                                render={(routeProps: RouteComponentProps<{ channelId: string; releaseVersion: string }>) => <EditReleasePage channelId={routeProps.match.params.channelId} releaseVersion={routeProps.match.params.releaseVersion} />}
                            />
                            <ReloadableRoute path={releaseInstanceLinks.deployments.create(":goal?", ":previousId?", ":tenantIds?")} component={CreateDeploymentPage} />
                            <ReloadableRoute path={releaseInstanceLinks.deployments.specific(":deploymentId")} component={ReleaseDeploymentDetailsPage} />
                            <ReloadableRoute exact path={releaseInstanceLinks.root} component={ReleasePage} />
                        </Switch>
                    </SlugSafeRedirect>
                </ReloadableRoute>
                <ReloadableRoute path={releaseLinks} component={ReleasesPage} />
            </Switch>
        );
    }
}
