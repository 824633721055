import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/Variables.svg");
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import { Note } from "components/form";
import ExternalVideoLink from "components/Navigation/ExternalLink/ExternalVideoLink";

export const CommonProjectVariableOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Define <ExternalLink href="DocumentationVariables">variables</ExternalLink> with values that change based on the scope you've assigned to the variables and the scope of your deployments.
                </p>
                <p>
                    <ExternalVideoLink href="OnboardingProjectVariablesVideo" />
                </p>
                <ImageWithPlaceholder src={image} alt={"Variables"} />
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Sharing variables between projects?"}>
                    Instead of defining variables for each project, you can define a set of variables into a <ExternalLink href="LibraryVariableSets">Library Variable Set</ExternalLink> and then access them from every project that needs them.
                </Note>
            </TermsText>
        </OverviewContainer>
    );
};

export const CommonProjectVariableTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="DocumentationVariables">Variables</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
