import * as React from "react";
import { ActionPlugin, default as pluginRegistry, ActionEditProps, ActionScope } from "../Actions/pluginRegistry";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";
import { PropertyValueResource } from "client/resources/propertyValueResource";
import ActionProperties from "client/resources/actionProperties";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import { IProcessResource } from "client/resources";

export interface ActionEditorProps extends ActionEditProps {
    isNew: boolean;
    plugin: ActionPlugin;
    expandedByDefault: boolean;
}

type ProjectActionEditorProps = Omit<ActionEditorProps, "localNames"> & WithActionScopeInjectedProps & WithProjectContextInjectedProps;

type ActionEditorInternalProps = Omit<ActionEditorProps, "projectId"> & WithActionScopeInjectedProps;

export const enforceNewActionFeatures = (plugin: ActionPlugin, scope: ActionScope, properties: ActionProperties, isNew: boolean) => {
    const existingEnabledFeatures: PropertyValueResource = properties["Octopus.Action.EnabledFeatures"] || "";

    // Enable any permanent or initial features
    let enabledFeatures = [...(typeof existingEnabledFeatures === "string" ? existingEnabledFeatures.split(",") : [])];

    if (isNew) {
        if (plugin.features) {
            if (plugin.features.permanent) {
                plugin.features.permanent.forEach((feature) => {
                    const featurePlugin = pluginRegistry.getFeature(feature, scope);
                    if (featurePlugin.enable) {
                        featurePlugin.enable(properties);
                    }
                    enabledFeatures.push(feature);
                });
            }

            if (plugin.features.initial) {
                plugin.features.initial.forEach((feature) => {
                    const featurePlugin = pluginRegistry.getFeature(feature, scope);
                    if (featurePlugin.enable) {
                        featurePlugin.enable(properties);
                    }
                    enabledFeatures.push(feature);
                });
            }
        }

        enabledFeatures = Array.from(new Set(enabledFeatures));
    }

    return enabledFeatures.join(",");
};

class ProjectActionEditorInternal extends React.Component<ProjectActionEditorProps, never> {
    componentDidMount() {
        const properties = { ...this.props.properties };

        const newActionFeatures = enforceNewActionFeatures(this.props.plugin, this.props.scope, this.props.properties, this.props.isNew);
        if (newActionFeatures.length > 0) {
            // We don't assign empty features, or this causes existing steps to trigger the "unsaved changes" dialog.
            properties["Octopus.Action.EnabledFeatures"] = newActionFeatures;
        }

        this.props.setProperties(properties, true);
    }

    render() {
        if (!this.props.plugin.edit) {
            throw new Error(`Plugin ${this.props.plugin.actionType} is missing 'Edit' property`);
        }
        return (
            <this.props.plugin.edit
                plugin={this.props.plugin}
                projectId={this.props.projectContext.state.model.Id}
                properties={this.props.properties}
                packages={this.props.packages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                runOn={this.props.runOn}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                additionalActions={this.props.additionalActions}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}
                refreshRunOn={this.props.refreshRunOn}
                parameters={this.props.parameters}
                getProcessResource={this.props.getProcessResource}
            />
        );
    }
}

export const ProjectActionEditor = withProjectContext(ProjectActionEditorInternal);

export default class ActionEditor extends React.Component<ActionEditorInternalProps, never> {
    componentDidMount() {
        const properties = { ...this.props.properties };

        const enabledFeatures = enforceNewActionFeatures(this.props.plugin, this.props.scope, this.props.properties, this.props.isNew);
        if (!!enabledFeatures) {
            // Server will strip empty properties, so only set this if we need to, otherwise this triggers our save-changes dialog unnecessarily.
            properties["Octopus.Action.EnabledFeatures"] = enforceNewActionFeatures(this.props.plugin, this.props.scope, this.props.properties, this.props.isNew);
        }

        this.props.setProperties(properties, true);
    }

    render() {
        if (!this.props.plugin.edit) {
            throw new Error(`Plugin ${this.props.plugin.actionType} is missing 'Edit' property`);
        }
        return (
            <this.props.plugin.edit
                plugin={this.props.plugin}
                localNames={this.props.localNames}
                properties={this.props.properties}
                packages={this.props.packages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                runOn={this.props.runOn}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                additionalActions={this.props.additionalActions}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}
                refreshRunOn={this.props.refreshRunOn}
                parameters={this.props.parameters}
            />
        );
    }
}
