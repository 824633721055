import React from "react";
import { Select } from "components/form";
import { repository } from "clientInstance";
import { buildBranchNamesList } from "utils/BranchHelpers/BranchHelpers";
import { Item, SelectProps } from "components/form/Select/Select";
import { useProjectContext } from "areas/projects/context";
import { VcsBranchResource } from "client/resources";
import { DoBusyTask } from "../../../../components/DataBaseComponent";

interface Props {
    onBranchItemsChange: (branches: VcsBranchResource[]) => void;
    doBusyTask: DoBusyTask; // TODO: remove when a hooks implementation for loading indicators has been implemented
}

type BranchSwitcherProps = Props & Omit<SelectProps, "items">;

/* TODO: This switcher is intended to be changed in the future to support commits/tags */
const BranchSwitcher: React.FC<BranchSwitcherProps> = ({ onBranchItemsChange, doBusyTask, ...props }) => {
    const projectContext = useProjectContext();
    const [project] = React.useState(projectContext.state.model);
    const selectedBranch = projectContext.state.branch && projectContext.state.branch.Name == props.value && projectContext.state.branch;
    const [items, setItems] = React.useState<Item[]>(selectedBranch ? buildBranchNamesList([selectedBranch], project) : []);

    React.useEffect(() => {
        async function getBranches() {
            if (project.IsVersionControlled) {
                // TODO: add a call to the progress indicator for this loading event when it has been implemented.
                const responseItems = await repository.Projects.getBranches(project);
                if (selectedBranch && !responseItems.find((r) => r.Name === selectedBranch.Name)) {
                    responseItems.push(selectedBranch);
                }

                const items = (responseItems && buildBranchNamesList(responseItems, project)) || [];
                onBranchItemsChange(responseItems);
                setItems(items);
            }
        }
        doBusyTask(async () => getBranches());
    }, [doBusyTask, onBranchItemsChange, project, selectedBranch]);

    return <Select label="Select branch" placeholder={props.value} items={items} {...props} />;
};

export default BranchSwitcher;
