/* eslint-disable @typescript-eslint/init-declarations */

import { firstAuthorized } from "components/PermissionCheck/PermissionCheck";
import * as React from "react";
import { Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import AuditLayout from "../AuditLayout/AuditLayout";
import Backup from "../Backup/Backup";
import SmtpLayout from "../Smtp/Smtp";
import SubscriptionsLayout from "../SubscriptionsLayout/SubscriptionsLayout";
import SubscriptionLayout from "../SubscriptionLayout/SubscriptionLayout";
import FeaturesLayout from "../FeaturesLayout/FeaturesLayout";
import { Nodes } from "areas/configuration/components/Nodes/Nodes";
import { TestPermissions } from "areas/configuration/components/TestPermissions/TestPermissions";
import { Thumbprint } from "areas/configuration/components/Thumbprint/Thumbprint";
import { Maintenance } from "areas/configuration/components/Maintenance/Maintenance";
import { License } from "areas/configuration/components/License/License";
import { Diagnostics } from "areas/configuration/components/Diagnostics/Diagnostics";
import { Settings } from "areas/configuration/components/Settings/SettingsLayout";
import { SettingsEdit } from "areas/configuration/components/Settings/SettingsEdit";
import Teams from "../Teams/Teams";
import TeamEdit, { TeamEditProps } from "../Teams/TeamEdit";
import Spaces from "../Spaces/Spaces";
import SpaceEdit from "../Spaces/SpaceEdit";
import Users from "../Users/Users";
import UserEdit from "../Users/UserEdit";
import UserInvite from "../Users/UserInvite";
import ConfigureLetsEncrypt from "areas/configuration/components/LetsEncrypt/ConfigureLetsEncrypt";
import LetsEncrypt from "areas/configuration/components/LetsEncrypt/LetsEncrypt";
import { DetailedServerLogs } from "areas/configuration/components/Diagnostics/DetailedServerLogs";
import { AutoDeployLogs } from "areas/configuration/components/Diagnostics/Logs/AutoDeployLogs";
import { ScheduledDeployLogs } from "areas/configuration/components/Diagnostics/Logs/ScheduledDeployLogs";
import { SubscriptionLogs } from "areas/configuration/components/Diagnostics/Logs/SubscriptionLogs";
import { Roles } from "../Roles/Roles";
import RoleEdit from "../Roles/RoleEdit";
import ConfigurationLayout from "areas/configuration/components/ConfigurationLayout/ConfigurationLayout";
import { renderWithLayout } from "components/RenderWithLayout/RenderWithLayout";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { Permission } from "client/resources";
import routeLinks from "routeLinks";
import pageIds from "pageIds";
import { ServerConfigurationSettings } from "areas/configuration/components/Nodes/ServerConfigurationSettings";
import { RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import { Performance } from "areas/configuration/components/Performance/Performance";
import { client } from "clientInstance";
import { RedirectFromScopedRole } from "../Teams/RedirectFromScopedRole";
import { withPage, withPageFromProps } from "components/Page/Page";

const auditLayout = renderWithLayout(ConfigurationLayout)(AuditLayout);
const backup = renderWithLayout(ConfigurationLayout)(Backup);
const autoDeployLogs = renderWithLayout(ConfigurationLayout)(AutoDeployLogs);
const scheduledDeployLogs = renderWithLayout(ConfigurationLayout)(ScheduledDeployLogs);
const subscriptionLogs = renderWithLayout(ConfigurationLayout)(SubscriptionLogs);
const detailedServerLogs = renderWithLayout(ConfigurationLayout)(DetailedServerLogs);
const diagnostics = renderWithLayout(ConfigurationLayout)(Diagnostics);
const featuresLayout = renderWithLayout(ConfigurationLayout)(FeaturesLayout);
const license = renderWithLayout(ConfigurationLayout)(License);
const configureLetsEncrypt = renderWithLayout(ConfigurationLayout)(ConfigureLetsEncrypt);
const letsEncrypt = renderWithLayout(ConfigurationLayout)(LetsEncrypt);
const maintenance = renderWithLayout(ConfigurationLayout)(Maintenance);
const nodes = renderWithLayout(ConfigurationLayout)(Nodes);
const smtpLayout = renderWithLayout(ConfigurationLayout)(SmtpLayout);
const subscriptionLayout = renderWithLayout(ConfigurationLayout)(SubscriptionLayout);
const subscriptionsLayout = renderWithLayout(ConfigurationLayout)(SubscriptionsLayout);
const testPermissions = renderWithLayout(ConfigurationLayout)(TestPermissions);
const thumbprint = renderWithLayout(ConfigurationLayout)(Thumbprint);
const roleEdit = renderWithLayout(ConfigurationLayout)(RoleEdit);
const roles = renderWithLayout(ConfigurationLayout)(Roles);
const spaceEdit = renderWithLayout(ConfigurationLayout)(SpaceEdit);
const spaces = renderWithLayout(ConfigurationLayout)(Spaces);
const teamEdit = renderWithLayout(ConfigurationLayout)(TeamEdit);
const teams = renderWithLayout(ConfigurationLayout)(Teams);
const settings = renderWithLayout(ConfigurationLayout)(Settings);
const settingsEdit = renderWithLayout(ConfigurationLayout)(SettingsEdit);
const userEdit = renderWithLayout(ConfigurationLayout)(UserEdit);
const users = renderWithLayout(ConfigurationLayout)(Users);
const userInvite = renderWithLayout(ConfigurationLayout)(UserInvite);
const serverConfigurationSettings = renderWithLayout(ConfigurationLayout)(ServerConfigurationSettings);
const performance = renderWithLayout(ConfigurationLayout)(Performance);

export const AuditPage = withPage({ page: pageIds.configuration.auditBaseRoute })(auditLayout);
export const BackupPage = withPage({ page: pageIds.configuration.backup })(backup);
export const AutoDeployLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.autoDeploy })(autoDeployLogs);
export const ScheduledDeployLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.scheduledDeploy })(scheduledDeployLogs);
export const SubscriptionLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.subscription })(subscriptionLogs);
export const DetailedServerLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.root })(detailedServerLogs);
export const DiagnosticsPage = withPage({ page: pageIds.configuration.diagnostics.root })(diagnostics);
export const FeaturesPage = withPage({ page: pageIds.configuration.features })(featuresLayout);
export const LicensePage = withPage({ page: pageIds.configuration.license })(license);
export const ConfigureLetsEncryptPage = withPage({ page: pageIds.configuration.letsEncrypt.configure })(configureLetsEncrypt);
export const LetsEncryptPage = withPage({ page: pageIds.configuration.letsEncrypt.root })(letsEncrypt);
export const MaintenancePage = withPage({ page: pageIds.configuration.maintenance })(maintenance);
export const PerformancePage = withPage({ page: pageIds.configuration.performance })(performance);
export const ServerConfigurationSettingsPage = withPage({ page: pageIds.configuration.nodes.serverSettings })(serverConfigurationSettings);
export const NodesPage = withPage({ page: pageIds.configuration.nodes.root })(nodes);
export const SmtpPage = withPage({ page: pageIds.configuration.smtp })(smtpLayout);
export const CreateSubscriptionPage = withPage({ page: pageIds.configuration.subscriptions.create })(subscriptionLayout);
export const SubscriptionPage = withPage({ page: pageIds.configuration.subscription })(subscriptionLayout);
export const SubscriptionsPage = withPage({ page: pageIds.configuration.subscriptions.root })(subscriptionsLayout);
export const TestPermissionPage = withPage({ page: pageIds.configuration.testPermission })(testPermissions);
export const TestPermissionsPage = withPage({ page: pageIds.configuration.testPermissions })(testPermissions);
export const ThumbprintPage = withPage({ page: pageIds.configuration.thumbprint })(thumbprint);
export const RoleCreatePage = withPage({ page: pageIds.configuration.roles.create })(roleEdit);
export const RoleEditPage = withPage({ page: pageIds.configuration.role })(roleEdit);
export const RolesPage = withPage({ page: pageIds.configuration.roles.root })(roles);
export const SpaceCreatePage = withPage({ page: pageIds.configuration.spaces.create })(spaceEdit);
export const SpaceEditPage = withPage({ page: pageIds.configuration.space })(spaceEdit);
export const SpacesPage = withPage({ page: pageIds.configuration.spaces.root })(spaces);
export const TeamsPage = withPage({ page: pageIds.configuration.teams.root })(teams);
export const SettingPage = withPageFromProps(settingsEdit, (props) => ({ page: pageIds.configuration.setting(props.match.params.settingId).root }));
export const SettingsPage = withPage({ page: pageIds.configuration.settings.root })(settings);
export const UserCreatePage = withPage({ page: pageIds.configuration.users.create })(userEdit);
export const UserEditPage = withPage({ page: pageIds.configuration.user })(userEdit);
export const UsersPage = withPage({ page: pageIds.configuration.users.root })(users);
export const UserInvitePage = withPage({ page: pageIds.configuration.userInvites })(userInvite);
export const EditTeamPage = withPage({ page: pageIds.configuration.team })(teamEdit);

const ConfigurationRoutes: React.StatelessComponent<RouteComponentProps<{}>> = (props) => {
    let defaultRoute;
    switch (firstAuthorized([Permission.AdministerSystem, Permission.EventView, Permission.TeamView])) {
        case Permission.AdministerSystem:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.features} />;
            break;
        case Permission.EventView:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.audit(client.spaceId)} />;
            break;
        case Permission.TeamView:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.teams.root()} />;
            break;
        default:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.spaces.root} />;
    }

    return (
        <Switch>
            <ReloadableRoute path={routeLinks.configuration.auditBaseRoute} component={AuditPage} />
            <ReloadableRoute path={routeLinks.configuration.backup} component={BackupPage} />
            <ReloadableRoute path={routeLinks.configuration.v3route.certificates}>
                <InternalRedirect to={routeLinks.configuration.thumbprint} />
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.v3route.usersInvite}>
                <InternalRedirect to={routeLinks.configuration.userInvites} />
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.v3route.testPermissions}>
                <InternalRedirect to={routeLinks.configuration.testPermissions} />
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.autoDeploy} component={AutoDeployLogsPage} />
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.scheduledDeploy} component={ScheduledDeployLogsPage} />
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.subscription} component={SubscriptionLogsPage} />
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.root} component={DetailedServerLogsPage} />
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.machineCleanup}>
                <InternalRedirect to={routeLinks.configuration.machineEventsForUser("users-system", "Deleted")} />
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.root} component={DiagnosticsPage} />
            <ReloadableRoute path={routeLinks.configuration.features} component={FeaturesPage} />
            <ReloadableRoute path={routeLinks.configuration.license} component={LicensePage} />
            <ReloadableRoute path={routeLinks.configuration.letsEncrypt.configure} component={ConfigureLetsEncryptPage} />
            <ReloadableRoute path={routeLinks.configuration.letsEncrypt.root} component={LetsEncryptPage} />
            <ReloadableRoute path={routeLinks.configuration.maintenance} component={MaintenancePage} />
            <ReloadableRoute path={routeLinks.configuration.performance} component={PerformancePage} />
            <ReloadableRoute path={routeLinks.configuration.nodes.serverSettings} component={ServerConfigurationSettingsPage} />
            <ReloadableRoute path={routeLinks.configuration.nodes.root} component={NodesPage} />
            <ReloadableRoute path={routeLinks.configuration.smtp} component={SmtpPage} />
            <ReloadableRoute path={routeLinks.configuration.subscriptions.create} render={(routeProps) => <CreateSubscriptionPage create {...routeProps} />} />
            <ReloadableRoute path={routeLinks.configuration.subscription(":subscriptionId")} component={SubscriptionPage} />
            <ReloadableRoute path={routeLinks.configuration.subscriptions.root} component={SubscriptionsPage} />
            <ReloadableRoute path={routeLinks.configuration.testPermissions}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.testPermission(":userId")} component={TestPermissionPage} />
                    <ReloadableRoute component={TestPermissionsPage} />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.thumbprint} component={ThumbprintPage} />
            <ReloadableRoute path={routeLinks.configuration.roles.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.roles.create} component={RoleCreatePage} />
                    <ReloadableRoute path={routeLinks.configuration.role(":roleId")} component={RoleEditPage} />
                    <ReloadableRoute component={RolesPage} />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.spaces.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.spaces.create} component={SpaceCreatePage} />
                    <ReloadableRoute path={routeLinks.configuration.space(":currentSpaceId")} component={SpaceEditPage} />
                    <ReloadableRoute component={SpacesPage} />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.teams.baseRoute}>
                <Switch>
                    <ReloadableRoute exact={true} path={routeLinks.configuration.teams.redirect(":scopedRoleId")} component={RedirectFromScopedRole} />
                    <ReloadableRoute path={routeLinks.configuration.team(":teamId")} render={(routeProps: RouteComponentProps<TeamEditProps>) => <EditTeamPage teamId={routeProps.match.params.teamId} {...routeProps} />} />
                    <ReloadableRoute component={TeamsPage} />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.settings.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.setting(":settingId")} component={SettingPage} />
                    <ReloadableRoute component={SettingsPage} />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.users.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.users.create} component={UserCreatePage} />
                    <ReloadableRoute path={routeLinks.configuration.user(":userId")} component={UserEditPage} />
                    <ReloadableRoute component={UsersPage} />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.userInvites} component={UserInvitePage} />
            <ReloadableRoute exact={true} path={routeLinks.configuration.root}>
                {defaultRoute}
            </ReloadableRoute>
            <RedirectAs404 />
        </Switch>
    );
};

export default ConfigurationRoutes;
