import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/Project.svg");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonProjectOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    A <ExternalLink href="OnboardingProjectsLearnMore">project</ExternalLink> is the collection of deployment steps, configuration variables, packages and assets that Octopus brings together and executes during the process of
                    deploying your software.
                </p>
                <ImageWithPlaceholder src={image} alt={"Project"} />
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
