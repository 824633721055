/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import SpecialVariables from "client/specialVariables";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation, GetPrimaryPackageReference, GetNamedPackageReferences, InitialisePrimaryPackageReference, RemovePrimaryPackageReference, SetPrimaryPackageReference, WorkerPoolResource } from "client/resources";
import { ExpandableFormSection, Summary, FormSectionHeading, MoreInfo } from "components/form";
import PackageSelector from "components/PackageSelector/PackageSelector";
import { StringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import Note from "components/form/Note/Note";
import { VariableLookupCodeEditor, withExternallyHandledFullScreen } from "components/CodeEditor/CodeEditor";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import FeedResource from "client/resources/feedResource";
import { repository } from "clientInstance";
import { WorkerPoolChip } from "components/Chips";
import { VariableLookupText } from "components/form/VariableLookupText";
import { default as PackageDownloadOptions } from "components/PackageDownloadOptions/PackageDownloadOptions";
import { ScriptingLanguage } from "components/scriptingLanguage";
import ScriptingLanguageSelector, { SupportedLanguage } from "components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import { TargetRoles } from "areas/projects/components/Process/types";
import { PackageRequirement } from "client/resources";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import ActionButton from "../../Button";
import DialogOpener from "../../Dialog/DialogOpener";
import { ScriptPackageReferenceDialog, ScriptPackageReference, ScriptPackageProperties } from "./ScriptPackageReferenceDialog";
import * as _ from "lodash";
import ListTitle from "../../ListTitle/ListTitle";
import { PackageAcquisitionLocation } from "../../../client/resources/packageAcquisitionLocation";
import { DisplayFeedName } from "../DisplayFeedName";
import { PackageReference, PackageSelectionMode } from "../../../client/resources/packageReference";
import ToolTip from "components/ToolTip";
import SourceCodeDialog from "components/SourceCodeDialog";
import { codeEditorVariablesList } from "utils/ScriptIntellisense/scriptIntellisense";
import { withOptionalProcessContext, WithOptionalProcessContextInjectedProps } from "areas/projects/components/Process/Contexts/ProcessContext";
import { ActionWithFeeds, LoadFeedsFromOptionalContext } from "../commonActionHelpers";
import { mapScriptActionContextToInjectedProps, withScriptActionContext, WithScriptActionContextInjectedProps } from "./withScriptActionContext";

const EnhancedCodeEditor = withExternallyHandledFullScreen(VariableLookupCodeEditor);

export interface ScriptActionSummaryProps extends ActionSummaryProps {
    workerPool?: WorkerPoolResource;
    workerPoolVariable?: string | null;
}

interface ScriptActionSummaryState {
    feedNames: Map<string, string>;
}

class ScriptActionSummary extends BaseComponent<ScriptActionSummaryProps, ScriptActionSummaryState> {
    constructor(props: ScriptActionSummaryProps) {
        super(props);
    }

    render() {
        const runsOnServer = this.props.properties[SpecialVariables.Action.RunOnServer] === "true";

        let summary;
        if (this.props.workerPool || this.props.workerPoolVariable) {
            const poolDescription = this.props.workerPoolVariable ? (
                <React.Fragment>
                    Run a script on a worker from the pool selected via the <strong>{this.props.workerPoolVariable}</strong> variable
                </React.Fragment>
            ) : (
                <React.Fragment>
                    Run a script on a worker from the <WorkerPoolChip workerPoolName={this.props.workerPool!.Name} workerPoolType={this.props.workerPool!.WorkerPoolType} /> pool
                </React.Fragment>
            );
            summary = (
                <React.Fragment>
                    {poolDescription}
                    {this.props.targetRolesAsCSV && (
                        <React.Fragment>
                            on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else {
            summary = runsOnServer ? (
                <React.Fragment>
                    Run a script on the Octopus Server{" "}
                    {this.props.targetRolesAsCSV && (
                        <React.Fragment>
                            on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    Run a script{" "}
                    {this.props.targetRolesAsCSV ? (
                        <React.Fragment>
                            across targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>on the deployment target</React.Fragment>
                    )}
                </React.Fragment>
            );
        }

        const packageContainingScript = GetPrimaryPackageReference(this.props.packages);
        const packageReferences = GetNamedPackageReferences(this.props.packages!);

        if (!!packageContainingScript) {
            summary = (
                <React.Fragment>
                    {summary}
                    <div>
                        {" "}
                        <ToolTip content={<React.Fragment>{this.props.properties["Octopus.Action.Script.ScriptParameters"] || "No script arguments specified"}</React.Fragment>}>
                            <strong>{this.props.properties["Octopus.Action.Script.ScriptFileName"] || "Script"}</strong>
                        </ToolTip>{" "}
                        contained in package {this.packageAndFeed(packageContainingScript)}
                        {packageReferences.length > 0 ? <span> and {this.packageReferenceSummary(packageReferences)}</span> : ""}
                    </div>
                </React.Fragment>
            );
        } else {
            if (packageReferences.length > 0) {
                summary = (
                    <div>
                        {summary}. Script {this.packageReferenceSummary(packageReferences)}.
                    </div>
                );
            }
        }

        return <div>{summary}</div>;
    }

    packageReferenceSummary(packageReferences: PackageReference[]) {
        if (packageReferences.length === 0) {
            return null;
        }

        if (packageReferences.length === 1) {
            return <span>references package {this.packageAndFeed(packageReferences[0])}</span>;
        }

        if (packageReferences.length < 3) {
            return (
                <React.Fragment>
                    <span>references packages:</span>
                    {packageReferences.map((pkg) => (
                        <div key={pkg.Id}>{this.packageAndFeed(pkg)}</div>
                    ))}
                </React.Fragment>
            );
        }

        return <span>references {packageReferences.length} packages</span>;
    }

    packageAndFeed(pkg: PackageReference) {
        return (
            <span>
                <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
            </span>
        );
    }
}

export interface ScriptProperties {
    "Octopus.Action.Script.ScriptSource": string;
    "Octopus.Action.Script.Syntax": ScriptingLanguage;
    "Octopus.Action.Script.ScriptBody": string;
    "Octopus.Action.Script.ScriptFileName": string;
    "Octopus.Action.Script.ScriptParameters": string;
    "Octopus.Action.RunOnServer": string;
}

interface ScriptActionEditState extends ActionWithFeeds {
    feeds: FeedResource[];
    originalCanRunBeforeAcquisition: boolean;
    originalStepPackageRequirement: PackageRequirement;
    editPackageReference: ScriptPackageReference | null;
    editPackageReferenceIndex: number | null;
    editScriptDialogOpen: boolean;
    serverNames: string[];
}

interface ScriptActionEditProps extends ActionEditProps<ScriptProperties, ScriptPackageProperties> {
    supportedLanguages?: SupportedLanguage;
}

type Props = ScriptActionEditProps & WithOptionalProcessContextInjectedProps & WithScriptActionContextInjectedProps;

class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {}

// Note: this is also used in the Azure PowerShell step and the ServiceFabric PowerShell step
class ScriptActionEditInternal extends BaseComponent<Props, ScriptActionEditState> {
    public static defaultProps: Partial<Props> = {
        supportedLanguages: SupportedLanguage.All,
    };

    constructor(props: Props) {
        super(props);

        const packageAcquisition = props.additionalActions && props.additionalActions.packageAcquisition;

        this.state = {
            editScriptDialogOpen: false,
            feeds: [],
            serverNames: [],
            originalCanRunBeforeAcquisition: packageAcquisition ? packageAcquisition.canRunBeforeAcquisition : false,
            originalStepPackageRequirement: packageAcquisition ? packageAcquisition.stepPackageRequirement! : PackageRequirement.LetOctopusDecide,
            editPackageReference: null,
            editPackageReferenceIndex: null,
        };
    }

    async componentDidMount() {
        await LoadFeedsFromOptionalContext(this.props.doBusyTask, this.props.processContext, (feeds: FeedResource[]) => {
            this.setState({ feeds }, () => {
                if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
                    this.handleScriptSourceChanged("Inline", feeds, true);
                }
            });
        });

        return this.props.doBusyTask(async () => {
            this.setState({
                serverNames: this.props.scriptActionContext ? await this.props.scriptActionContext.loadVariables() : [],
            });
        });
    }

    handleScriptSourceChanged(val: string, feeds: FeedResource[], initialise: boolean = false) {
        const isRunFromPackage = val === "Package";
        const properties: any = { ["Octopus.Action.Script.ScriptSource"]: val };

        if (isRunFromPackage) {
            properties["Octopus.Action.Script.Syntax"] = null;
            properties["Octopus.Action.Script.ScriptBody"] = null;
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds), initialise);
        } else {
            if (!this.props.properties["Octopus.Action.Script.Syntax"]) {
                properties["Octopus.Action.Script.Syntax"] = ScriptingLanguage.PowerShell;
            }
            properties["Octopus.Action.Script.ScriptFileName"] = null;
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages), initialise);
        }

        this.props.setProperties(properties, initialise);

        // if we are pulling the script from a package we might need to override things
        // if the user has set this step to run before package acquisition
        if (this.props.additionalActions) {
            if (isRunFromPackage) {
                this.props.additionalActions.packageAcquisition.onCanRunBeforeAcquisitionChanged(false);
            } else {
                this.props.additionalActions.packageAcquisition.onCanRunBeforeAcquisitionChanged(this.state.originalCanRunBeforeAcquisition);
            }
            if (this.state.originalStepPackageRequirement === PackageRequirement.BeforePackageAcquisition) {
                const newValue = isRunFromPackage ? PackageRequirement.LetOctopusDecide : this.state.originalStepPackageRequirement;

                this.props.additionalActions.packageAcquisition.onStepPackageRequirementChanged(newValue);
            }
        }
    }

    scriptSourceIsPackage() {
        return this.props.properties["Octopus.Action.Script.ScriptSource"] && this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package";
    }

    scriptSourceSummary() {
        if (this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline") {
            return Summary.default("The script is defined below");
        } else {
            return Summary.summary("The script is contained in a package");
        }
    }

    scriptBodySummary() {
        if (!this.props.properties["Octopus.Action.Script.ScriptBody"]) {
            return Summary.placeholder("The script body has not been defined");
        }
        if (this.isPowershellOnly()) {
            return Summary.summary("The script body has been defined");
        }
        const syntax = this.props.properties["Octopus.Action.Script.Syntax"];
        if (syntax === ScriptingLanguage.FSharp) {
            return Summary.summary(
                <span>
                    An <strong>F#</strong> script has been defined
                </span>
            );
        } else if (syntax === ScriptingLanguage.CSharp) {
            return Summary.summary(
                <span>
                    A <strong>C#</strong> script has been defined
                </span>
            );
        }
        return Summary.summary(
            <span>
                A <strong>{syntax}</strong> script has been defined
            </span>
        );
    }

    scriptFileFromPackageSummary() {
        const packageContainingScript = GetPrimaryPackageReference(this.props.packages);
        const fileName = this.props.properties["Octopus.Action.Script.ScriptFileName"];

        if (!packageContainingScript || !packageContainingScript.PackageId) {
            return Summary.placeholder("Select the package containing the script");
        }

        if (!fileName) {
            return Summary.placeholder("Enter the path to the script file");
        }

        const feed = this.state.feeds.find((f) => f.Id === packageContainingScript.FeedId);

        return Summary.summary(
            <span>
                <strong>{fileName}</strong> in package <strong>{packageContainingScript.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : packageContainingScript.FeedId}</strong>
            </span>
        );
    }

    render() {
        const properties = this.props.properties;
        const runOnServer = this.props.properties && this.props.properties["Octopus.Action.RunOnServer"] ? this.props.properties["Octopus.Action.RunOnServer"].toLowerCase() === "true" : false;

        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());

        const parameters = this.props.parameters || [];

        const editPackageReferenceDialog = (
            <DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog
                    packageReference={this.state.editPackageReference!}
                    runOn={this.props.runOn}
                    feeds={this.state.feeds}
                    localNames={localNames}
                    projectId={this.props.projectId!}
                    onChange={(packageReference) => this.savePackageReference(packageReference)}
                    refreshFeeds={this.loadFeeds}
                    parameters={parameters}
                />
            </DialogOpener>
        );

        return (
            <div>
                {editPackageReferenceDialog}
                <FormSectionHeading title="Script" />
                <ExpandableFormSection errorKey="scriptsource" isExpandedByDefault={this.props.expandedByDefault} title="Script Source" summary={this.scriptSourceSummary()} help="Select where the script is defined.">
                    <StringRadioButtonGroup value={properties["Octopus.Action.Script.ScriptSource"]} onChange={(val) => this.handleScriptSourceChanged(val, this.state.feeds)}>
                        <RadioButton value="Inline" label="Inline source code (with optional package references)" />
                        <Note>The script body is defined in the Inline Source Code field below, Referenced Packages below this</Note>
                        <RadioButton value="Package" label="Script file inside a package" />
                        <Note>The script is contained in a package that will be deployed</Note>
                    </StringRadioButtonGroup>
                </ExpandableFormSection>

                {properties["Octopus.Action.Script.ScriptSource"] === "Inline" && this.inlineScriptFormSections(runOnServer, localNames)}

                {properties["Octopus.Action.Script.ScriptSource"] === "Package" && this.packageScriptFormSections(localNames)}
            </div>
        );
    }

    inlineScriptFormSections = (runOnServer: boolean, localNames: string[]) => {
        const properties = this.props.properties;
        const packageReferences = GetNamedPackageReferences(this.props.packages) as ScriptPackageReference[];
        const results = codeEditorVariablesList(this.state.serverNames, localNames ?? [], properties["Octopus.Action.Script.Syntax"], "");

        return (
            <div>
                <ExpandableFormSection
                    isExpandedByDefault={true}
                    errorKey="Octopus.Action.Script.ScriptBody"
                    title="Inline Source Code"
                    fillCardWidth={CardFill.FillRight}
                    summary={this.scriptBodySummary()}
                    help={this.isPowershellOnly() ? "Enter the body of the script that will be executed." : "Select the script language and enter the body of the script that will be executed."}
                >
                    <Note>
                        Scripts can contain <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink> below and <ExternalLink href="DocumentationVariables">variables</ExternalLink> can be added by the{" "}
                        <em>Insert a variable</em> button. Script modules included in this project
                        {this.isPowershellOnly() || (properties["Octopus.Action.Script.Syntax"] as ScriptingLanguage) === ScriptingLanguage.PowerShell ? (
                            <>
                                {" "}
                                will be <ExternalLink href="ImportingScriptModules">automatically imported</ExternalLink>.
                            </>
                        ) : (
                            <>
                                {" "}
                                can be <ExternalLink href="ImportingScriptModules">imported</ExternalLink> into your script.
                            </>
                        )}
                    </Note>
                    {packageReferences.length > 0 && (
                        <Note>
                            There are referenced packages. See the <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">documentation</ExternalLink> for information on consuming packages from your scripts.
                        </Note>
                    )}

                    {!this.isPowershellOnly() && (
                        <ScriptingLanguageSelector
                            supportedLanguages={this.props.supportedLanguages}
                            value={properties["Octopus.Action.Script.Syntax"] as ScriptingLanguage}
                            onChange={(syntax) => {
                                this.props.setProperties({ ["Octopus.Action.Script.Syntax"]: syntax });
                            }}
                        />
                    )}
                    {this.isPowershellOnly() && <h4>PowerShell script</h4>}
                    <EnhancedCodeEditor
                        onToggleFullScreen={() => this.setState({ editScriptDialogOpen: true })}
                        localNames={localNames}
                        autoComplete={results}
                        syntax={properties["Octopus.Action.Script.Syntax"]}
                        value={properties["Octopus.Action.Script.ScriptBody"]}
                        language={properties["Octopus.Action.Script.Syntax"]}
                        allowFullScreen={true}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptBody"]: x })}
                    />
                    <SourceCodeDialog
                        open={this.state.editScriptDialogOpen}
                        close={() => this.setState({ editScriptDialogOpen: false })}
                        autocomplete={results}
                        language={properties["Octopus.Action.Script.Syntax"]}
                        value={properties["Octopus.Action.Script.ScriptBody"]}
                        saveDone={(value) => this.props.setProperties({ ["Octopus.Action.Script.ScriptBody"]: value })}
                    />
                </ExpandableFormSection>
                {this.packagesFormSection()}
            </div>
        );
    };

    packageScriptFormSections = (localNamesWithPackageVariables: string[]) => {
        const properties = this.props.properties;
        const primaryPackage = GetPrimaryPackageReference(this.props.packages);
        const primaryPackageFeed = primaryPackage ? _.find(this.state.feeds, (f) => f.Id === primaryPackage.FeedId) : null;

        return (
            <div>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId|Octopus.Action.Script.ScriptFileName|Octopus.Action.Script.ScriptParameters"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Script File in Package"
                    summary={this.scriptFileFromPackageSummary()}
                    help={this.scriptInPackageHelp()}
                >
                    <PackageSelector
                        packageId={primaryPackage?.PackageId}
                        feedIdOrName={primaryPackage?.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds}
                    />
                    <PackageDownloadOptions
                        packageAcquisitionLocation={primaryPackage.AcquisitionLocation}
                        onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                        feed={primaryPackageFeed!}
                        runOn={this.props.runOn}
                        projectId={this.props.projectId}
                        localNames={this.props.localNames}
                    />
                    <VariableLookupText
                        localNames={localNamesWithPackageVariables}
                        value={properties["Octopus.Action.Script.ScriptFileName"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptFileName"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Script.ScriptFileName")}
                        label="Script file name"
                    />
                    <Note>
                        The relative path to the script file within the package.
                        <br />
                        e.g. <code>MyScript.ps1</code> or <code>Scripts\MyScript.ps1</code>
                    </Note>
                    <VariableLookupText
                        localNames={localNamesWithPackageVariables}
                        value={properties["Octopus.Action.Script.ScriptParameters"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptParameters"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Script.ScriptParameters")}
                        multiline={true}
                        label="Script parameters"
                    />
                    {this.isPowershellOnly() ? (
                        <Note>
                            Parameters expected by the script. Use platform specific calling convention.
                            <br />
                            e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell
                        </Note>
                    ) : (
                        <Note>
                            Parameters expected by the script. Use platform specific calling convention.
                            <br />
                            e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell or <code>-- #{`{VariableStoringPath}`}</code> for ScriptCS
                        </Note>
                    )}
                </ExpandableFormSection>
                {this.packagesFormSection()}
            </div>
        );
    };

    packagesFormSection = () => {
        const packageReferences = GetNamedPackageReferences(this.props.packages) as ScriptPackageReference[];

        return (
            <ExpandableFormSection
                title={this.scriptSourceIsPackage() ? "Additional Packages" : "Referenced Packages"}
                isExpandedByDefault={this.props.expandedByDefault}
                errorKey="Octopus.Action.Script.Packages"
                summary={this.packageReferenceSummary()}
                help={this.scriptSourceIsPackage() ? "Add additional package references" : "Add packages to be referenced by your scripts at execution-time"}
            >
                <Note>
                    Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                </Note>
                <PackageReferenceList
                    listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()} />]}
                    data={packageReferences}
                    onRow={(p) => this.packageReferenceListItem(p)}
                    onRowTouch={(pkg) => this.editPackageReference(pkg)}
                    onRemoveRow={(pkg) => this.removePackageReference(pkg)}
                />
            </ExpandableFormSection>
        );
    };

    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        const scriptSourceIsPackage = this.scriptSourceIsPackage();

        if (namedPackageReferences.length === 0) {
            return Summary.placeholder(scriptSourceIsPackage ? "No additional packages referenced" : "No packages referenced");
        }

        return Summary.summary(`${namedPackageReferences.length} package references`);
    };

    addPackageReference = () => {
        const additionalPackage: ScriptPackageReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            AcquisitionLocation: PackageAcquisitionLocation.Server,
            Properties: { Extract: "True" },
        };

        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null });
    };

    editPackageReference = (pkg: ScriptPackageReference) => {
        this.setState({
            editPackageReference: _.clone(pkg),
            editPackageReferenceIndex: this.props.packages.indexOf(pkg),
        });
    };

    removePackageReference = (pkg: ScriptPackageReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    };

    packageReferenceListItem = (pkg: ScriptPackageReference) => {
        const feed = this.state.feeds.find((f) => f.Id === pkg.FeedId);
        return (
            <div>
                <ListTitle>{pkg.Name}</ListTitle>
                <div>
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <React.Fragment>
                            <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                        </React.Fragment>
                    )}
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                    <Note>
                        Extracted: <strong>{pkg.Properties.Extract}</strong>
                    </Note>
                </div>
            </div>
        );
    };

    savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        } else {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }

        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }

    resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null,
            editPackageReferenceIndex: null,
        });
    };

    packageVariableNames = (): string[] => {
        return _.flatten(
            GetNamedPackageReferences(this.props.packages).map((pkg) => [
                `Octopus.Action.Package[${pkg.Name}].PackageId`,
                `Octopus.Action.Package[${pkg.Name}].FeedId`,
                `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
                `Octopus.Action.Package[${pkg.Name}].ExtractedPath`,
                `Octopus.Action.Package[${pkg.Name}].PackageFilePath`,
                `Octopus.Action.Package[${pkg.Name}].PackageFileName`,
            ])
        );
    };

    private isPowershellOnly = () => {
        return this.props.supportedLanguages === SupportedLanguage.PowerShell;
    };

    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void, loadFromContextIfAvailable?: boolean) => {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };

    private scriptInPackageHelp = () => {
        return <span>Select the package containing your script.</span>;
    };
}

export const ScriptActionEdit = withScriptActionContext(withOptionalProcessContext(ScriptActionEditInternal), mapScriptActionContextToInjectedProps);

pluginRegistry.registerActionForAllScopes({
    actionType: "Octopus.Script",
    summary: (properties, targetRolesAsCSV, packages, workerPool, workerPoolVariable) => (
        <ScriptActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} workerPool={workerPool} workerPoolVariable={workerPoolVariable} />
    ),
    edit: ScriptActionEdit,
    canHaveChildren: (step) => !!step.Properties[SpecialVariables.Action.TargetRoles],
    canBeChild: true,
    executionLocation: ActionExecutionLocation.TargetOrServer,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => {
        if (action.Properties && action.Properties["Octopus.Action.Script.ScriptSource"] === "Package") {
            return true;
        }
        return false;
    },
    features: {
        optional: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
});
