import React from "react";
import ToolTip from "components/ToolTip";
import { useOctopusTheme } from "components/Theme";
const styles = require("./ProcessActionErrorIndicator.less");

interface ProcessActionNoticeIndicatorProps {
    actionNotices: string[];
}

export const ProcessActionNoticeIndicator: React.FC<ProcessActionNoticeIndicatorProps> = ({ actionNotices }) => {
    const theme = useOctopusTheme();
    const items = actionNotices.map((x) => <li>{x}</li>);

    return (
        <ToolTip content={<ul>{items}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa fa-exclamation-triangle" style={{ color: theme.info }} />
            </div>
        </ToolTip>
    );
};
