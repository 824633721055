/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import Popover from "components/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import InternalLink, { FontWeight } from "../Navigation/InternalLink/InternalLink";
import { withTheme } from "components/Theme";
const styles = require("./SectionStepLink.less");

interface SectionStepLinkProps {
    text: string;
    weight?: FontWeight;
    path: string | undefined | null;
    items: Array<{
        label: string;
        link: string;
    }>;
    onNavigating(): void;
}

interface SectionStepLinkState {
    open: boolean;
    anchor: EventTarget | null;
}

export class SectionStepLink extends React.Component<SectionStepLinkProps, SectionStepLinkState> {
    constructor(props: SectionStepLinkProps) {
        super(props);

        this.state = {
            open: false,
            anchor: null,
        };
    }

    render() {
        return withTheme((theme) => {
            const popoverStyle = {
                backgroundColor: theme.whiteConstant,
                color: theme.primaryText,
            };

            const menuItemStyle = {
                color: theme.primaryText,
            };

            if (this.props.items.length) {
                return (
                    <span>
                        <Popover
                            open={this.state.open}
                            style={popoverStyle}
                            anchorEl={this.state.anchor as any}
                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                            transformOrigin={{ horizontal: "left", vertical: "top" }}
                            onClose={this.handleRequestClose}
                        >
                            <Menu>
                                {this.props.items.map((item) => (
                                    <InternalLink key={item.label} to={item.link} weight={this.props.weight}>
                                        <MenuItem key={item.label} style={menuItemStyle} primaryText={item.label} onClick={this.handleNavigate} />
                                    </InternalLink>
                                ))}
                            </Menu>
                        </Popover>
                        <span className={styles.lookLikeALink} onClick={this.handleTouchTap}>
                            {this.props.text}
                            {this.props.items && <em style={{ paddingLeft: "5px" }} className="fa fa-caret-down" />}
                        </span>
                    </span>
                );
            }

            if (this.props.path) {
                return (
                    <InternalLink to={this.props.path} weight={this.props.weight}>
                        <span onClick={() => this.handleNavigate()}>{this.props.text}</span>
                    </InternalLink>
                );
            }

            return <span>{this.props.text}</span>;
        });
    }

    private handleNavigate = () => {
        this.setState({
            open: false,
        });

        if (this.props.onNavigating) {
            this.props.onNavigating();
        }
    };

    private handleTouchTap = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();

        this.setState({
            open: true,
            anchor: event.currentTarget,
        });
    };

    private handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };
}
