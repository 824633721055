import { DeepPartial } from "redux";
type GlobalState = import("./reducers").GlobalState;

const STATE_KEY = "OctopusState";

export const loadState = (): DeepPartial<GlobalState> | undefined => {
    try {
        const value = localStorage.getItem(STATE_KEY);
        if (value === null) {
            return undefined;
        }
        return JSON.parse(value);
    } catch {
        return undefined;
    }
};

export const saveState: (state: DeepPartial<GlobalState>) => void = (state) => {
    try {
        localStorage.setItem(STATE_KEY, JSON.stringify(state));
    } catch {
        //There may be cases where we can't actually persist to storage.
    }
};
