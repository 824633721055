import * as React from "react";
import { throttle } from "lodash";
import { CellAligner } from "components/ScrollTable/ScrollTable";
const styles = require("./style.less");
import VariableCell from "areas/variables/VariableCell/VariableCell";
import { MeasureWidth } from "components/Measure/Measure";
import EventListener, { withOptions } from "react-event-listener";

interface VariableEditorHeadingsProps {
    cellAligner: CellAligner;
    cells: JSX.Element[];
    isDisplayedFullWidth: boolean;
    columnWidths: ReadonlyArray<number>;
    onWidthMeasured(cellIndex: number, width: number): void;
}

interface VariableEditorHeadingsState {
    resizeCount: number;
}

export default class VariableEditorHeadings extends React.Component<VariableEditorHeadingsProps, VariableEditorHeadingsState> {
    private readonly throttledRemeasure = throttle(() => {
        this.setState((prev) => ({ resizeCount: prev.resizeCount + 1 }));
    }, 100);

    constructor(props: VariableEditorHeadingsProps) {
        super(props);
        this.state = {
            resizeCount: 0,
        };
    }

    render() {
        const columnWidthsKey = this.props.columnWidths.map((c) => c.toString()).join("-");
        // If any of these things change, we need to re-measure
        const measurerKey = `${this.props.isDisplayedFullWidth}-${columnWidthsKey}-${this.state.resizeCount}`;
        return this.props.cellAligner(
            this.props.cells.map((c, index) => (
                <VariableCell key={index}>
                    <EventListener target="window" onResize={withOptions(this.throttledRemeasure, { passive: true })} />
                    <MeasureWidth key={measurerKey} onMeasured={(width) => this.props.onWidthMeasured(index, width)}>
                        <div className={styles.headerRow}>{c}</div>
                    </MeasureWidth>
                </VariableCell>
            )),
            { showResizeHandles: true }
        );
    }
}
