import React from "react";
import { useProjectContext } from "areas/projects/context";
import { NameOrIdKey } from "components/KeyAccessProvider/types";

export const useKeyedItemAccessForConfigurationAsCode = (): NameOrIdKey => {
    const context = useProjectContext();
    const isVersionControlled = context.state.model.IsVersionControlled;

    return isVersionControlled ? "Name" : "Id";
};

export default useKeyedItemAccessForConfigurationAsCode;
