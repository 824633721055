import React = require("react");
import { DefaultOverview, DefaultTopics } from "./DefaultViews";

export const CommonUserProfileOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};

export const CommonUserProfileTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
