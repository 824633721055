import * as React from "react";
import SimpleDataTable from "components/SimpleDataTable";
import { ProjectUsage, ReleaseUsageEntry, RunbookSnapshotUsageEntry } from "client/resources/libraryVariableSetUsageResource";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import Chip from "components/Chips/Chip";
import { pluraliseSummary, pluraliseHelp } from "./VariableSetUsage";

export const VariableSetReleaseUsages: React.FC<{ usage: ProjectUsage[] }> = ({ usage }) => {
    return (
        <SimpleDataTable<ProjectUsage>
            data={usage.filter((x) => x.Releases.length > 0)}
            headerColumns={["Project Name", "Release Version"]}
            onRow={(usageEntry) => [
                usageEntry.ProjectName,
                usageEntry.Releases.map((release: ReleaseUsageEntry) => (
                    <InternalLink to={routeLinks.release(release.ReleaseId)} key={`${release.ReleaseId}-releaseSnapshotLink`}>
                        <Chip>{release.ReleaseVersion}</Chip>
                    </InternalLink>
                )),
            ]}
        />
    );
};

export const VariableSetRunbookUsages: React.FC<{ usage: ProjectUsage[] }> = ({ usage }) => {
    return (
        <SimpleDataTable<ProjectUsage>
            data={usage.filter((x) => x.RunbookSnapshots.length > 0)}
            headerColumns={["Project Name", "Runbook Snapshots"]}
            onRow={(usageEntry) => [
                usageEntry.ProjectName,
                usageEntry.RunbookSnapshots.map((snapshot: RunbookSnapshotUsageEntry) => (
                    <InternalLink to={routeLinks.runbookSnapshot(snapshot.SnapshotId)} key={snapshot.SnapshotId}>
                        <Chip>{snapshot.SnapshotName}</Chip>
                    </InternalLink>
                )),
            ]}
        />
    );
};

export function getReleaseUsageSummary(type: string, count: number, countOfReleasesUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "has been", "has not been", "release snapshot", countOfReleasesUserCannotSee);
}

export function getRunbookSnapshotUsageSummary(type: string, count: number, countOfRunbookSnapshotsUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "has been", "has not been", "runbook snapshot", countOfRunbookSnapshotsUserCannotSee);
}

export function getReleaseUsageHelp(type: string, count: number, countOfReleasesUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "has been", "has not been", "release", countOfReleasesUserCannotSee);
}

export function getRunbookSnapshotUsageHelp(type: string, count: number, countOfRunbookSnapshotsUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "has been", "has not been", "runbook snapshot", countOfRunbookSnapshotsUserCannotSee);
}

export function scriptModuleReleaseUsageSummary(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageSummary("script module", usageCount, countOfReleasesUserCannotSee);
}

export function scriptModuleRunbookSnapshotUsageSummary(usagesInRunbookSnapshots: ProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagesInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageSummary("script module", usageCount, countOfRunbookSnapshotsUserCannotSee);
}

export function variableSetReleaseUsageSummary(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageSummary("variable set", usageCount, countOfReleasesUserCannotSee);
}
export function variableSetRunbookSnapshotUsageSummary(usagedInRunbookSnapshots: ProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagedInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageSummary("variable set", usageCount, countOfRunbookSnapshotsUserCannotSee);
}

export function scriptModuleReleaseUsageHelp(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageHelp("script module", usageCount, countOfReleasesUserCannotSee);
}

export function scriptModuleRunbookUsageHelp(usagesInRunbookSnapshots: ProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagesInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageHelp("script module", usageCount, countOfRunbookSnapshotsUserCannotSee);
}

export function variableSetReleaseUsageHelp(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageHelp("variable set", usageCount, countOfReleasesUserCannotSee);
}

export function variableSetRunbookUsageHelp(usagesInRunbookSnapshots: ProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagesInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageHelp("variable set", usageCount, countOfRunbookSnapshotsUserCannotSee);
}
