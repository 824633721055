import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { repository } from "clientInstance";
import { TeamResource } from "client/resources/teamResource";
import { ChipIcon, TeamChip } from "components/Chips/index";
import { ExpandableFormSection, Summary, FormSectionHeading, SummaryNode, required, Note } from "components/form";
import TeamMultiSelect from "components/MultiSelect/TeamMultiSelect";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { ActionExecutionLocation } from "../../../client/resources";
import { TargetRoles } from "areas/projects/components/Process/types";
import { VariableLookupMarkdownEditor } from "components/form/MarkdownEditor/MarkdownEditor";
import { BoundStringRadioButtonGroup, RadioButton } from "components/form/RadioButton";
import StringHelper from "utils/StringHelper";
import isBound from "components/form/BoundField/isBound";
import KeyedAccessLookupResourceChip from "../../LookupResourceChip/KeyedAccessLookupResourceChip";

class ManualActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return <div>Manual intervention</div>;
    }
}

interface ManualActionProperties {
    "Octopus.Action.Manual.Instructions": string;
    "Octopus.Action.Manual.ResponsibleTeamIds": string;
    "Octopus.Action.Manual.BlockConcurrentDeployments": string;
}

interface ManualActionEditState {
    teams: TeamResource[];
}

class ManualActionEdit extends BaseComponent<ActionEditProps<ManualActionProperties>, ManualActionEditState> {
    constructor(props: ActionEditProps<ManualActionProperties>) {
        super(props);
        this.state = {
            teams: [],
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const teams = await repository.Teams.all();
            this.setState({ teams });

            if (!this.props.properties["Octopus.Action.Manual.BlockConcurrentDeployments"]) {
                this.props.setProperties({ ["Octopus.Action.Manual.BlockConcurrentDeployments"]: "False" });
            }
        });
    }

    render() {
        return (
            <div>
                <FormSectionHeading title="Manual Intervention" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Manual.Instructions"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Instructions"
                    help="These instructions will be presented to the user to follow."
                    summary={this.instructionsSummary()}
                >
                    <VariableLookupMarkdownEditor
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Manual.Instructions"]}
                        label="Instructions"
                        error={this.props.getFieldError("Octopus.Action.Manual.Instructions")}
                        onChange={(val: string) => this.props.setProperties({ ["Octopus.Action.Manual.Instructions"]: val })}
                        validate={required("Please provide instructions to display during the step")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Manual.ResponsibleTeamIds"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Responsible Teams"
                    summary={this.teamSummary()}
                    help="Select the teams responsible for this manual step."
                >
                    <TeamMultiSelect
                        label="Responsible teams"
                        items={this.state.teams}
                        value={ParseHelper.parseCSV(this.props.properties["Octopus.Action.Manual.ResponsibleTeamIds"])}
                        error={this.props.getFieldError("Octopus.Action.Manual.ResponsibleTeamIds")}
                        onChange={(val) => this.props.setProperties({ ["Octopus.Action.Manual.ResponsibleTeamIds"]: ParseHelper.encodeCSV(val) })}
                    />
                    <Note>
                        If no teams are specified, all users who have permission to deploy the project will be able to perform the manual step. When one or more teams are specified, as in the case of an approval workflow, then only members of those
                        teams will be able to perform the step.
                    </Note>
                    <Note>Specifying responsible teams will make the step unable to be skipped.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Manual.BlockConcurrentDeployments"
                    title="Block Deployments"
                    summary={this.blockDeploymentsSummary()}
                    help="Should other deployments be blocked while this manual intervention is awaiting action."
                >
                    <Note>
                        In general only one deployment will execute at any time for each project/environment combination (and tenant if applicable). If this manual intervention step may pause the deployment for a long time period, this option allows
                        specifying that another deployment may begin while this step is awaiting intervention.
                    </Note>

                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        isBound={isBound(this.props.properties["Octopus.Action.Manual.BlockConcurrentDeployments"]) || !StringHelper.isBooleanString(this.props.properties["Octopus.Action.Manual.BlockConcurrentDeployments"])}
                        resetValue={"False"}
                        value={this.props.properties["Octopus.Action.Manual.BlockConcurrentDeployments"]}
                        onChange={(x) => {
                            if (StringHelper.isBooleanString(x)) {
                                this.props.setProperties({ "Octopus.Action.Manual.BlockConcurrentDeployments": StringHelper.toOctopusStringBoolean(x) });
                            } else {
                                this.props.setProperties({ "Octopus.Action.Manual.BlockConcurrentDeployments": x });
                            }
                        }}
                        label="Block Deployments"
                    >
                        <RadioButton value={"False"} label="Allow another deployment to begin while awaiting intervention" isDefault />
                        <RadioButton value={"True"} label="Prevent other deployments while awaiting intervention" />
                    </BoundStringRadioButtonGroup>
                </ExpandableFormSection>
            </div>
        );
    }

    instructionsSummary(): SummaryNode {
        const instructions = this.props.properties["Octopus.Action.Manual.Instructions"];
        return instructions ? Summary.summary("Instructions have been provided") : Summary.placeholder("Instructions have not been provided");
    }

    teamSummary(): SummaryNode {
        const teams = ParseHelper.parseCSV(this.props.properties["Octopus.Action.Manual.ResponsibleTeamIds"]);
        if (teams.length === 0) {
            return Summary.placeholder("All authorized teams");
        }

        const chips = teams.map((t) => <KeyedAccessLookupResourceChip lookupCollection={this.state.teams} key={t} lookupId={t} type={ChipIcon.Environment} chipRender={(team) => <TeamChip team={team} />} />);
        return Summary.summary(<div>{chips}</div>);
    }

    blockDeploymentsSummary(): SummaryNode {
        const property = this.props.properties["Octopus.Action.Manual.BlockConcurrentDeployments"];
        if (property === "" || property === null) {
            return Summary.placeholder("No value has been provided.");
        }

        if (isBound(property) || !StringHelper.isBooleanString(property)) {
            return Summary.summary(
                <span>
                    Other deployments will be blocked based on the value of <strong>{property}</strong>.
                </span>
            );
        }
        const blockConcurrentDeployments = property && /true/i.test(property);

        return Summary.summary(
            blockConcurrentDeployments ? (
                <span>
                    Other deployments will be <strong>prevented</strong> while this step is awaiting intervention
                </span>
            ) : (
                <span>
                    Other deployments will be <strong>allowed</strong> while this step is awaiting intervention
                </span>
            )
        );
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunOnWorker: false,
    actionType: "Octopus.Manual",
    summary: (properties, targetRolesAsCSV) => <ManualActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => false,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.None,
    hasPackages: (action) => false,
    edit: ManualActionEdit,
});
