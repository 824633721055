import * as React from "react";
import { EnvironmentResource } from "client/resources";
import { FormFieldProps } from "components/form";
import { ChipIcon, EnvironmentChip, ContextualMissingChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";
import { useKeyedItemAccess } from "../KeyAccessProvider/KeyedItemAccessProvider";

export const EnvironmentTypedMultiSelect = MultiSelect<ReferenceDataItem>();

interface EnvironmentMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
    accessibleName?: string;
    onRemove?: (nameOrId: string) => void;
}

const EnvironmentMultiSelect: React.FC<EnvironmentMultiSelectProps> = (props) => {
    const itemKey = useKeyedItemAccess();

    const chipRenderer = (r: EnvironmentResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r[itemKey]}
                getIdFromElement={(element: ReferenceDataItem) => element[itemKey]}
                render={(item: ReferenceDataItem) => <EnvironmentChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} environmentName={item.Name} />}
                renderFallback={<ContextualMissingChip lookupKey={r[itemKey]} type={ChipIcon.Environment} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <EnvironmentTypedMultiSelect itemKey={itemKey} fieldName="environments" renderChip={chipRenderer} accessibleName={props.accessibleName} {...props} />;
};

export default EnvironmentMultiSelect;
