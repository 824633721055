/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { repository } from "clientInstance";
import { MachineResource, WorkerPoolsSummaryResource, WorkerPoolResource, WorkerMachineResource, TaskRestrictedTo, ResourceCollection } from "client/resources";
import { OctopusIcon, OctopusIconType } from "components/Icon";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { isEqual, each } from "lodash";
import routeLinks from "../../../../routeLinks";
import { Card } from "@material-ui/core";
import { CardTitle } from "components/form/Sections";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import Permission from "client/resources/permission";
import BaseAllMachinesSummary, { HealthStatusRecord } from "./BaseAllMachinesSummary";
import { BaseAllMachinesSummaryProps, BaseAllMachinesSummaryState } from "./BaseAllMachinesSummary";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ReactNode } from "react";
import MachineRow from "../MachineRow/MachineRow";
import { withTheme } from "components/Theme";
import { createMachineHealthMap } from "./MachineFilter";
import FormPage from "components/FormPage/FormPage";
import { orderedHealthStatuses } from "areas/infrastructure/InfrastructureDetails";
import { WorkerPoolsSummaryFilter, defaultWorkerPoolsSummaryFilter, createWorkerPoolListWorkerArgs } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
const styles = require("./style.less");

interface WorkerMachinesSummarySectionProps extends BaseAllMachinesSummaryProps<WorkerPoolsSummaryFilter> {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    workerPools: WorkerPoolResource[];
    filter: WorkerPoolsSummaryFilter;
}

interface WorkerMachinesSummarySectionInnerProps extends WorkerMachinesSummarySectionProps {
    initialData: InitialData;
}

interface InitialData {
    machinesResponse: ResourceCollection<MachineResource>;
    machineHealthStatusFastLookup: HealthStatusRecord;
}

const WorkerMachinesSummaryFormPage = FormPage<InitialData>();
const WorkerMachinesSummarySection: React.FC<WorkerMachinesSummarySectionProps> = (props: WorkerMachinesSummarySectionProps) => {
    return (
        <WorkerMachinesSummaryFormPage
            title={"Workers"}
            load={async () => {
                const machineRequestArgs = createWorkerPoolListWorkerArgs(defaultWorkerPoolsSummaryFilter, null, true);
                const machinesResponse = await repository.Workers.list(machineRequestArgs);
                const machineHealthStatusFastLookup = createMachineHealthMap(machinesResponse, repository.takeDefaultPageSize);

                return { machinesResponse, machineHealthStatusFastLookup };
            }}
            renderWhenLoaded={(data) => <WorkerMachinesSummarySectionInner initialData={data} {...props} />}
        />
    );
};

class WorkerMachinesSummarySectionInner extends BaseAllMachinesSummary<WorkerMachinesSummarySectionInnerProps, WorkerPoolsSummaryFilter, BaseAllMachinesSummaryState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: WorkerMachinesSummarySectionInnerProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            machinesResponse: this.props.initialData.machinesResponse,
            machineHealthStatusFastLookup: this.props.initialData.machineHealthStatusFastLookup,
        };
    }

    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }

    componentWillReceiveProps(nextProps: BaseAllMachinesSummaryProps<WorkerPoolsSummaryFilter>) {
        if (!isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const workerPoolsSummary = this.props.workerPoolsSummary;
        const machineStatusesLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(workerPoolsSummary, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(workerPoolsSummary);
        const summaryComponents = [...machineStatusesLinks, machinesDisabledLinks];

        const componentKey = "allMachines";
        const overflowMenuItems = [];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (workerPoolsSummary.TotalMachines > 0) {
            const machineIds = this.state.machinesResponse && this.state.machinesResponse.Items.map((x) => x.Id);
            overflowMenuItems.push(
                OverflowMenuItems.item(`Check Health for ${machineIds && machineIds.length} Worker${machineIds && machineIds.length === 1 ? "" : "s"}`, () => this.performHealthCheck(TaskRestrictedTo.Workers, machineIds), {
                    permission: Permission.WorkerEdit,
                    wildcard: true,
                })
            );
            const tentacleIds = workerPoolsSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"}`, () => this.performTentacleUpgrade(TaskRestrictedTo.Workers, machineIds), {
                        permission: Permission.WorkerEdit,
                        wildcard: true,
                    })
                );
            }
            const calamariIds = workerPoolsSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Worker${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgradeOnWorkers(calamariIds), {
                        permission: Permission.WorkerEdit,
                        wildcard: true,
                    })
                );
            }
        }
        const titleContainer = (
            <div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    {withTheme((theme) => (
                        <OctopusIcon iconType={OctopusIconType.Worker} color={theme.iconDark} />
                    ))}
                </div>
                <div className={styles.environmentName}>Workers</div>
                <div className={styles.environmentMachinesCount}>({workerPoolsSummary.TotalMachines && workerPoolsSummary.TotalMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems} />
                </div>
            </div>
        );

        return (
            <PaperLayout key={componentKey} busy={this.state.busy} errors={this.errors} className={styles.paperLayoutOverride}>
                <Card className={styles.formExpander}>
                    <CardTitle title={titleContainer} />
                    <div className={styles.cardMedia}>{this.renderMachinesList(workerPoolsSummary)}</div>
                </Card>
            </PaperLayout>
        );
    }

    protected async loadData() {
        const machineArgs = createWorkerPoolListWorkerArgs(this.props.filter, this.state.healthStatusFilter, this.state.isDisabledFilter);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Workers.list(machineArgs), (machinesResponse) => this.setMachineResponseState(machinesResponse));
    }

    protected renderMachine(machine: MachineResource, needsUpgrading: boolean = false): ReactNode {
        return <MachineRow machine={machine as WorkerMachineResource} workerPools={this.props.workerPools} needsUpgrading={needsUpgrading} />;
    }
}

export default WorkerMachinesSummarySection;
