/* eslint-disable @typescript-eslint/consistent-type-assertions */

import ResourceWithId from "./resource";
import DeploymentStepResource from "./deploymentStepResource";
import { RunbookProcessResource, Permission } from ".";
import { typeSafeHasOwnProperty } from "../utils";

interface DeploymentProcessResourceLinks {
    Self: string;
    Project: string;
    Template: string;
}

export interface IProcessResource extends ResourceWithId<DeploymentProcessResourceLinks> {
    Id: string;
    SpaceId: string;
    ProjectId: string;
    Steps: DeploymentStepResource[];
    LastSnapshotId?: string;
    Version: number;
}
export type DeploymentProcessResource = IProcessResource;

export function isDeploymentProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<IProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }

    const converted = resource as DeploymentProcessResource;
    return !isRunbookProcessResource(resource) && converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}

export function isRunbookProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<RunbookProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }

    const converted = resource as RunbookProcessResource;
    return converted.RunbookId !== undefined && typeSafeHasOwnProperty(converted, "RunbookId");
}

export function processResourcePermission(resource: IProcessResource): Permission {
    const isRunbook = isRunbookProcessResource(resource);
    return isRunbook ? Permission.RunbookEdit : Permission.ProcessEdit;
}

export default DeploymentProcessResource;
