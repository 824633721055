/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

export interface DataTableRowProps {
    displayNoBorder?: boolean;
    className?: string;
    onClick?: (e: any) => void;
}

export class DataTableRow extends React.Component<DataTableRowProps> {
    render() {
        const { displayNoBorder, className, ...otherProps } = this.props;

        return (
            <tr {...otherProps} className={cn(className, styles.dataTableRow, displayNoBorder ? styles.noBorder : "")}>
                {this.props.children}
            </tr>
        );
    }
}
