import { DoBusyTask, Errors, useDoBusyTaskEffect } from "components/DataBaseComponent";
import { ProcessContextProps, loadProcess, ProcessContext, ProcessContextLookupState, useBoundProcessActions } from "./ProcessContext";
import { ProcessPageSupportedActions, ProcessContextModelState } from "../types";
import React from "react";
import { IProcessResource, Permission, ProcessType, ProjectResource, isDeploymentProcessResource, isRunbookProcessResource } from "client/resources";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import FeedResource from "client/resources/feedResource";
import { repository } from "clientInstance";
import { ProcessStateSelectors, getSelectors, processContextModelStateReducer, getProcessContextModelInitialState } from "./ProcessContextState";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { ProcessSearchFilterController } from "./ProcessSearchFilter/ProcessSearchFilterContext";
import { ProcessErrorsController } from "./ProcessErrors/ProcessErrorsContext";
import ProjectContextRepository from "client/repositories/projectContextRepository";
import { withProjectContext, WithProjectContextInjectedProps, useProjectContext } from "areas/projects/context";
import { DevToolsTab } from "components/DevTools/DevToolsContext";
import { DevToolbarProcessUpload } from "./DeploymentProcessUpload";
import { ProcessWarningsController } from "./ProcessWarnings/ProcessWarningsContext";
import { OctopusValidationResponse } from "client/resources/octopusValidationResponse";

interface ProcessControllerProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (renderProps: ProcessContextProps) => React.ReactNode;
    processType: ProcessType;
    project: Readonly<ProjectResource>;
    layoutActions: ProcessPageSupportedActions;
    errors?: Errors;
}

type Props = ProcessControllerProps & WithProjectContextInjectedProps;

const useProcessState = () => {
    return React.useState<ProcessContextLookupState>({
        actionTemplates: [],
        feeds: [],
    });
};

const useLoadProcessEffect = (projectContextRepository: ProjectContextRepository, processType: ProcessType, id: string, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => void) => {
    return useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            if (!id) {
                return;
            }

            const result: IProcessResource = await loadProcess(projectContextRepository, processType, id);

            if (onLoaded) {
                onLoaded(result);
            }
        },
        [id, processType, projectContextRepository]
    );
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>) => {
    return {
        onActionTemplatesUpdated: (actionTemplates: ActionTemplateSearchResource[]) => setState((current) => ({ ...current, actionTemplates })),
        onFeedsUpdated: (feeds: FeedResource[]) => setState((current) => ({ ...current, feeds })),
    };
};

const useSelectors = (state: ProcessContextModelState): ProcessStateSelectors => {
    return React.useMemo(() => getSelectors(state), [state]);
};

const ProcessControllerInternal: React.FC<Props> = (props: Props) => {
    const { children, doBusyTask, id, processType, project, layoutActions } = props;
    const [state, dispatch] = React.useReducer(processContextModelStateReducer, getProcessContextModelInitialState(processType));
    const selectors = useSelectors(state);

    const [lookupsState, setState] = useProcessState();

    const boundActions = useBoundProcessActions(dispatch);

    const refreshFromServer = useLoadProcessEffect(props.projectContext.state.projectContextRepository, processType, id, doBusyTask, (process) => {
        boundActions.setProcess(process, true);
    });

    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const refreshActionTemplates = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const templates = await repository.ActionTemplates.search();
            stateUpdaters.onActionTemplatesUpdated(templates);
        },
        []
    );

    const refreshFeeds = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const feeds = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true }) ? await repository.Feeds.all() : [];
            stateUpdaters.onFeedsUpdated(feeds);
        },
        []
    );

    const validate = async (projectContextRepository: ProjectContextRepository, process: IProcessResource, setErrors: (errors: Errors) => void, onSuccess: () => void): Promise<OctopusValidationResponse | null> => {
        let validationResponse: OctopusValidationResponse | null = null;
        await doBusyTask(
            async () => {
                if (isDeploymentProcessResource(process)) {
                    validationResponse = await projectContextRepository.DeploymentProcesses.validate(process);
                } else if (isRunbookProcessResource(process)) {
                    //TODO: #project-configuration-as-code: Make this available once runbooks support gitref processes.
                }
            },
            true,
            setErrors,
            onSuccess
        );
        return validationResponse;
    };

    const saveOnServer = async (projectContextRepository: ProjectContextRepository, process: IProcessResource, setErrors: (errors: Errors) => void, onSuccess: () => void, commitMessage?: string): Promise<IProcessResource | null> => {
        let processResult: IProcessResource | null = null;
        await doBusyTask(
            async () => {
                if (isDeploymentProcessResource(process)) {
                    processResult = await projectContextRepository.DeploymentProcesses.modify(process, commitMessage);
                } else if (isRunbookProcessResource(process)) {
                    processResult = await repository.RunbookProcess.modify(process);
                }

                if (processResult && id === processResult.Id) {
                    boundActions.setProcess(processResult, true);
                }
            },
            true,
            setErrors,
            onSuccess
        );
        return processResult;
    };

    const contextValue: ProcessContextProps = {
        lookupsState,
        state,
        actions: {
            ...boundActions,
            saveOnServer,
            validate,
            onActionTemplatesUpdated: stateUpdaters.onActionTemplatesUpdated,
            onFeedsUpdated: stateUpdaters.onFeedsUpdated,
            refreshFromServer,
            refreshActionTemplates,
            refreshFeeds,
            ...layoutActions,
        },
        selectors,
    };

    const projectContext = useProjectContext();

    return (
        <ProcessContext.Provider value={contextValue}>
            <DevToolsTab name={`Upload ${contextValue.state.processType} Process`}>
                <DevToolbarProcessUpload processContext={contextValue} projectContext={projectContext} />
            </DevToolsTab>
            <ProcessSearchFilterController processType={contextValue.state.processType} selectors={contextValue.selectors}>
                {() => (
                    <ProcessErrorsController>
                        <ProcessWarningsController>{children(contextValue)}</ProcessWarningsController>
                    </ProcessErrorsController>
                )}
            </ProcessSearchFilterController>
        </ProcessContext.Provider>
    );
};

export const ProcessController = withProjectContext(ProcessControllerInternal);
