import React from "react";
import { useFieldErrors } from "components/FieldErrorContext/FieldErrorContext";
import { useErrorActions, useErrors } from "components/ErrorContext/ErrorContext";
import { Errors } from "./Errors";

type DatabaseComponentContextProps = {
    actions: ReturnType<typeof useErrorActions>;
    getFieldError: ReturnType<typeof useFieldErrors>["getFieldError"];
    errors: Errors | undefined;
};

export const DataBaseComponentContext = React.createContext<DatabaseComponentContextProps | undefined>(undefined);
DataBaseComponentContext.displayName = "DataBaseComponentContext";

export const DatabaseComponentContextProvider: React.FC = (props) => {
    const errorActions = useErrorActions();
    const { getFieldError } = useFieldErrors();
    const errors = useErrors();

    const value: DatabaseComponentContextProps = React.useMemo(
        () => ({
            actions: errorActions,
            getFieldError,
            errors,
        }),
        [errorActions, errors, getFieldError]
    );

    return <DataBaseComponentContext.Provider value={value}>{props.children}</DataBaseComponentContext.Provider>;
};
