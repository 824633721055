/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { EventGroupResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { EventGroupChip, MissingChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface EventGroupMultiSelectProps extends FormFieldProps<string[]> {
    items: EventGroupResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EventGroupTypedMultiSelect = MultiSelect<EventGroupResource>();

const EventGroupMultiSelect: React.FC<EventGroupMultiSelectProps> = (props) => {
    const chipRenderer = (r: EventGroupResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <EventGroupChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventGroup={item} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.EventGroup} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <EventGroupTypedMultiSelect fieldName="event groups" renderChip={chipRenderer} {...props} />;
};

export default EventGroupMultiSelect;
