/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
const styles = require("./style.less");
import FormFieldProps from "components/form/FormFieldProps";
import { AccountResource, AccountType } from "client/resources/accountResource";
import Note from "components/form/Note/Note";
import routeLinks from "routeLinks";
import { AccountIcon } from "areas/infrastructure/components/AccountsLayout/AccountIcons";
import { withBoundField } from "components/form/BoundField/BoundField";
import SelectWithAddRefresh from "../SelectWithAddRefresh/SelectWithAddRefresh";
import { KeyedItemAccessConsumer } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import { NameOrIdKey } from "../../KeyAccessProvider/types";

interface AccountSelectProps extends FormFieldProps<string> {
    multiple?: boolean;
    fixedLabel?: boolean;
    type: AccountType | AccountType[];
    items: AccountResource[];
    placeholder?: string;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onRequestRefresh(): Promise<any>;
}

interface TextState {
    error?: string;
    refreshing: boolean;
}

export default class AccountSelect extends React.Component<AccountSelectProps, TextState> {
    constructor(props: AccountSelectProps) {
        super(props);
        this.state = {
            error: null!,
            refreshing: false,
        };
    }

    handleChange = (accountId: string | undefined) => {
        const value = accountId === "" ? null : accountId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };

    getItems(itemKey: NameOrIdKey) {
        const map = (acc: AccountResource) => ({ value: acc[itemKey], text: acc.Name, icon: <AccountIcon accountType={acc.AccountType} /> });
        const types = Array.isArray(this.props.type) ? this.props.type : [this.props.type];

        return this.props.items.filter((a) => types.find((t) => a.AccountType === t)).map(map);
    }

    selectionRenderer = (accountId: string) => {
        const account = this.props.items.find((acc) => acc.Id === accountId);
        if (!account) {
            return accountId;
        }
        return (
            <div className={styles.innerContainer}>
                <span className={styles.selectedIcon}>
                    <AccountIcon accountType={account.AccountType} />
                </span>{" "}
                {account.Name}
            </div>
        );
    };

    onlyTypeIs(type: string) {
        const types = Array.isArray(this.props.type) ? this.props.type : [this.props.type];
        return types.length === 1 && types[0] === type;
    }

    render() {
        const { items, onChange, onValidate, onRequestRefresh, ...otherProps } = this.props;

        let warning = "";
        if (this.onlyTypeIs(AccountType.AzureSubscription)) {
            warning = "Only Azure Management Certificate Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.AzureServicePrincipal)) {
            warning = "Only Azure Service Principal Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.AmazonWebServicesAccount)) {
            warning = "Only Amazon Web Service Accounts may be selected.";
        }

        return (
            <KeyedItemAccessConsumer>
                {(itemKey) => (
                    <div className={styles.outerContainer}>
                        <SelectWithAddRefresh
                            addUrl={`#${routeLinks.infrastructure.accounts.root}`}
                            onRequestRefresh={this.onRequestRefresh}
                            label="Select account"
                            {...otherProps}
                            allowFilter={true}
                            onChange={this.handleChange}
                            error={this.state.error || this.props.error}
                            items={this.getItems(itemKey)}
                            selectionRenderer={this.selectionRenderer}
                        />
                        {warning && <Note>{warning}</Note>}
                    </div>
                )}
            </KeyedItemAccessConsumer>
        );
    }

    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        } finally {
            this.setState({ refreshing: false });
        }
    };
}

export const BoundAccountSelect = withBoundField(AccountSelect);
