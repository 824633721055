/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Action, Dispatch } from "redux";
import { defaultContainerKey, defaultExpanderContainer, expanderActions, UseInitialValueOfContainer, ExpanderActions } from "components/form/Sections/reducers/expanders";
import { GlobalState } from "store/reducers";

export interface ExpandableProps {
    isExpanded: boolean;
    expandingAll?: boolean;
    onExpandedChanged(isExpanded: boolean): void;
}

export interface ExpandableContainerProps {
    containerKey?: string;
    errorKey: string;
    isExpandedByDefault?: boolean;
}

// HOC that hooks up a component that manages expansion to the redux store
export default function Expandable<TComponent extends React.ComponentType<Props>, Props extends ExpandableProps & Pick<ExpandableContainerProps, "isExpandedByDefault">>(Comp: React.ComponentType<Props>) {
    //type InternalProps = Props & ExpanderInjectedProps & ExpandableContainerProps;

    function mapGlobalStateToProps(state: GlobalState, ownProps: ExpandableContainerProps) {
        const container = state.expanders?.[ownProps.containerKey || defaultContainerKey] ?? defaultExpanderContainer();
        const expanderState = container.expanderValues[ownProps.errorKey.toLowerCase()];
        const initialState = container.initialState || (ownProps.isExpandedByDefault ?? false);
        const isExpanded = expanderState === UseInitialValueOfContainer ? initialState : expanderState;
        return { isExpanded, expandingAll: container.expandingAll };
    }

    function mapGlobalActionDispatchersToProps(dispatch: Dispatch<Action>, ownProps: ExpandableContainerProps) {
        return {
            onExpandedChanged: (state: boolean) => {
                dispatch(expanderActions.onExpanderStateChanged({ containerKey: ownProps.containerKey ?? defaultContainerKey, key: ownProps.errorKey, expanded: state }));
            },
            onMount: (errorKey: string, containerKey: string | undefined, expanded: boolean | undefined) => {
                dispatch(expanderActions.onExpanderCreated({ containerKey: containerKey ?? defaultContainerKey, key: errorKey, expanded: expanded ?? UseInitialValueOfContainer }));
            },
        };
    }

    type DispatchInjectedProps = ReturnType<typeof mapGlobalActionDispatchersToProps>;
    type StateInjectedProps = ReturnType<typeof mapGlobalStateToProps>;
    type InjectedProps = DispatchInjectedProps & StateInjectedProps;
    type ExpandableInternalProps = ExpandableContainerProps & ExpandableProps & InjectedProps;
    type ExternalProps = Omit<Props, keyof InjectedProps> & ExpandableContainerProps;

    class ExpandableInternal extends React.Component<ExpandableInternalProps> {
        componentDidMount() {
            this.props.onMount(this.props.errorKey, this.props.containerKey, this.props.isExpandedByDefault);
        }

        componentWillReceiveProps(nextProps: Readonly<ExpandableInternalProps>) {
            if (nextProps.containerKey !== this.props.containerKey || nextProps.errorKey !== this.props.errorKey) {
                this.props.onMount(nextProps.errorKey, nextProps.containerKey, nextProps.isExpandedByDefault);
            }
        }

        render() {
            const { containerKey, errorKey, onMount, ...rest } = this.props;
            return <Comp {...(rest as Props)} />;
        }
    }

    return withContextualExpandableContainer(connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExpandableInternal)) as React.ComponentType<ExternalProps>;
}

const ExpandableContainerContext = React.createContext<string | null>(null);

export const ExpandableContainer: React.FC<{ containerKey: string }> = (props) => {
    const dispatch = useDispatch<Dispatch<ExpanderActions>>();

    React.useEffect(() => {
        return () => {
            dispatch(expanderActions.onExpanderContainerDestroyed({ containerKey: props.containerKey }));
        };
    }, [dispatch, props.containerKey]);
    return <ExpandableContainerContext.Provider value={props.containerKey}>{props.children}</ExpandableContainerContext.Provider>;
};

interface ContainerKeyProp {
    containerKey?: string;
}

const withContextualExpandableContainer = <T extends ContainerKeyProp>(Component: React.ComponentType<T>) => {
    class ContextualExpandableContainer extends React.Component<T> {
        render() {
            return <ExpandableContainerContext.Consumer>{(containerKey) => <Component {...this.props} containerKey={this.props.containerKey ?? containerKey} />}</ExpandableContainerContext.Consumer>;
        }
    }

    return ContextualExpandableContainer;
};

export const useContextualExpandableContainer = () => {
    return React.useContext(ExpandableContainerContext);
};
