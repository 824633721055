/* eslint-disable @typescript-eslint/no-non-null-assertion */
import IconButtonList from "../../IconButtonList/IconButtonList";
import InputWithActions from "../../InputWithActions";
import * as React from "react";
import FormFieldProps from "components/form/FormFieldProps";
import * as _ from "lodash";
import { ProjectResource, ActionTemplateParameterResource, ControlType } from "client/resources";
import { VariableResource, VariableType } from "client/resources/variableResource";
import { client, repository } from "clientInstance";
import Select from "components/form/Select/Select";
import BusyIndicator from "../../BusyIndicator/index";
import IconButton, { Icon } from "components/IconButton/IconButton";
import { withBoundField } from "components/form/BoundField/BoundField";
import { resolveStringPathWithSpaceId } from "../../Navigation/resolvePathWithSpaceId";
import routeLinks from "../../../routeLinks";

interface WorkerPoolVariableSelectProps extends FormFieldProps<string | null> {
    projectId: string;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface WorkerPoolVariableSelectState {
    error?: string;
    refreshing: boolean;
    project: ProjectResource | null;
    variables: string[];
    isDataLoaded: boolean;
}

export default class WorkerPoolVariableSelect extends React.Component<WorkerPoolVariableSelectProps, WorkerPoolVariableSelectState> {
    constructor(props: WorkerPoolVariableSelectProps) {
        super(props);
        this.state = {
            refreshing: false,
            project: null,
            variables: [],
            isDataLoaded: false,
        };
    }

    handleChange = (poolVariable: string | undefined) => {
        const value = poolVariable === "" ? null : poolVariable;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };

    componentDidMount() {
        return this.props.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectId);
            const variables = await this.getWorkerPoolVariables(project);

            this.setState({ project, variables, isDataLoaded: true });
        });
    }

    render() {
        if (!this.state.isDataLoaded) {
            return <BusyIndicator show={true} inline={true} />;
        }

        const { onChange, onValidate, projectId, doBusyTask, value, ...otherProps } = this.props;

        return (
            <InputWithActions
                input={
                    <Select
                        label="Select worker pool variable"
                        {...otherProps}
                        value={value!} // Material UI should handle nulls, we are adding this assertion here to comply with that
                        allowFilter={true}
                        error={this.state.error || this.props.error}
                        onChange={this.handleChange}
                        items={this.state.variables.map((v) => ({ value: v, text: v }))}
                    />
                }
                actions={<IconButtonList buttons={this.getButtons()} />}
            />
        );
    }

    private getButtons() {
        const buttons = [];

        if (!this.state.refreshing) {
            buttons.push(<IconButton toolTipContent="Refresh" icon={Icon.Refresh} onClick={this.onRequestRefresh} />);
        } else {
            buttons.push(<BusyIndicator show={true} inline={true} />);
        }
        buttons.push(<IconButton toolTipContent="Add" icon={Icon.Add} onClick={this.goTo} />);

        return buttons;
    }
    private goTo = () => {
        window.open(`#${resolveStringPathWithSpaceId(routeLinks.project(this.props.projectId).variables.root, client.spaceId!)}`, "_blank");
    };

    private getWorkerPoolVariables = async (project: ProjectResource) => {
        const libraryVariableSets = await Promise.all(project.IncludedLibraryVariableSetIds.map((libraryVariableSetId) => repository.LibraryVariableSets.get(libraryVariableSetId)));
        const templates = _.union(project.Templates, _.flattenDeep<ActionTemplateParameterResource>(libraryVariableSets.map((lvs) => lvs.Templates)))
            .filter((template: ActionTemplateParameterResource) => !!template.DisplaySettings && template.DisplaySettings["Octopus.ControlType"] === ControlType.WorkerPool)
            .map((v) => v.Name);
        const variableSetIds = _.union(
            [project.VariableSetId],
            libraryVariableSets.map((lvs) => lvs.VariableSetId)
        );
        const variableResults = await Promise.all(variableSetIds.map(async (variableSetId) => (await repository.Variables.get(variableSetId)).Variables));
        const variables = _.flattenDeep<VariableResource>(variableResults)
            .filter((v: VariableResource) => v.Type === VariableType.WorkerPool)
            .map((v) => v.Name);

        return _.chain(variables).union(templates).sort().uniq().value();
    };

    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });

        try {
            const variables = await this.getWorkerPoolVariables(this.state.project!);
            this.setState({ variables });
        } finally {
            this.setState({ refreshing: false });
        }
    };
}
