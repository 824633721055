import * as React from "react";
import BaseComponent from "../BaseComponent";
import { OctopusError } from "client/resources";

export default class NetworkFailure extends BaseComponent<{}, {}> {
    private message: string;
    constructor(props: {}) {
        super(props);
        this.message = "Sample unhandled network error";
    }

    async componentDidMount() {
        await this.simulateNetworkCall();
        const error: OctopusError = new OctopusError(500, this.message);
        throw error;
    }
    render() {
        return (
            <div>
                <strong>{this.message}</strong> should be visible at the top of the page.
            </div>
        );
    }
    private simulateNetworkCall(ms = 1000) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
