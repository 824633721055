/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { session, repository } from "clientInstance";
import { Text, MarkdownEditor, Select, required, Checkbox } from "components/form";
import { LifecycleResource, ProjectGroupResource, EnvironmentResource, Permission, ProjectSummaryResource, ProjectResource } from "client/resources";
import { ActionButton, ActionButtonType } from "components/Button";
import Note from "../../../../components/form/Note/Note";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap";
const styles = require("./style.less");
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, { CalloutType } from "components/Callout";
import { ResourcesById } from "client/repositories/basicRepository";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";

interface AddProjectDialogProps {
    title: string;
    cloneId?: string;
    groupId?: string;
    hasProjects: boolean;
    lifecycleId?: string;
    projectCreated(project: ProjectResource): any;
}

interface AddProjectDialogState extends DataBaseComponentState {
    name: string;
    description: string;
    newProjectId: string;
    projectGroupId?: string;
    selectedLifecycle: LifecycleResource;
    cloneGroupId: string;
    projectGroups: ProjectGroupResource[];
    lifecycles: LifecycleResource[] | null;
    environmentsById: ResourcesById<EnvironmentResource>;
    selectedCloneId: string;
    otherProjects: ProjectSummaryResource[];
    dialogResizeKey: string;
    showLifecycleMap: boolean;
    showAdvanced: boolean;
}

export default class AddProject extends DataBaseComponent<AddProjectDialogProps, AddProjectDialogState> {
    constructor(props: AddProjectDialogProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            projectGroupId: "",
            selectedLifecycle: null!,
            cloneGroupId: null!,
            projectGroups: [],
            lifecycles: null,
            environmentsById: {},
            newProjectId: null!,
            selectedCloneId: this.props.cloneId!,
            otherProjects: [],
            dialogResizeKey: "",
            showLifecycleMap: false,
            showAdvanced: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            let allProjects: ProjectSummaryResource[] = [];
            if (this.props.cloneId) {
                allProjects = await repository.Projects.summaries();
            }

            const [projectGroups, lifecycles, environmentsById, otherProjects] = await Promise.all<ProjectGroupResource[], LifecycleResource[], ResourcesById<EnvironmentResource>, ProjectSummaryResource[]>([
                repository.ProjectGroups.all(),
                isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.all() : Promise.resolve([]),
                repository.Environments.allById(),
                allProjects,
            ]);

            const lifecyclePreviews = await Promise.all(lifecycles.map((l) => repository.Lifecycles.preview(l)));

            let predicate = (x: any) => x.Name === "Default Lifecycle";
            if (this.props.lifecycleId) {
                predicate = (x) => x.Id === this.props.lifecycleId;
            }

            const lifecycle = lifecyclePreviews.find(predicate);
            const projectGroup = projectGroups.find((x) => x.Name === "All Projects" || x.Name === "Default Project Group")!;

            const projectGroupId = this.props.groupId || (projectGroup ? projectGroup.Id : projectGroups[0].Id);

            this.setState({
                lifecycles: lifecyclePreviews,
                projectGroups,
                environmentsById,
                selectedLifecycle: lifecycle || lifecyclePreviews[0]!,
                projectGroupId,
                cloneGroupId: this.props.groupId!,
                otherProjects,
            });
        });
    }

    async save() {
        await this.doBusyTask(async () => {
            const args = { clone: this.state.selectedCloneId };
            const result = await repository.Projects.create(
                {
                    Name: this.state.name,
                    Description: this.state.description,
                    ProjectGroupId: this.state.projectGroupId!,
                    LifecycleId: this.state.selectedLifecycle.Id,
                },
                args
            );

            // refresh permissions to include the new project
            const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser!, true);
            session.refreshPermissions(permissionSet);

            this.props.projectCreated(result);
            return true;
        });

        return false;
    }

    render() {
        const hasLifecycleViewPermissions = isAllowed({ permission: Permission.LifecycleView });
        const showAdvancedButton = this.state.lifecycles && this.state.lifecycles.length <= 1;

        if (!this.state.lifecycles) {
            return <SaveDialogLayout title={this.props.title} busy={true} errors={this.errors} onSaveClick={() => this.save()} />;
        }

        return (
            <SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} saveButtonDisabled={this.state.lifecycles.length === 0} onSaveClick={() => this.save()}>
                <PermissionCheck
                    permission={Permission.LifecycleView}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to create a project
                        </Callout>
                    }
                >
                    {this.state.lifecycles.length === 0 && (
                        <Callout type={CalloutType.Danger} title="No lifecycles Configured">
                            <InternalLink to={routeLinks.library.lifecycles}>Configure your lifecycles</InternalLink> before setting up a project.
                        </Callout>
                    )}
                    {!this.state.busy && Object.keys(this.state.environmentsById).length === 0 && (
                        <Callout type={CalloutType.Warning} title="No Environments Configured">
                            Please consider <InternalLink to={routeLinks.infrastructure.overview}>configuring your infrastructure</InternalLink> before setting up a project.
                        </Callout>
                    )}
                    <Text label="New project name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a project name")} autoFocus={true} />
                    {/* <div style={{ marginTop: "1rem" }} /> */}
                    {showAdvancedButton && !this.state.showAdvanced && (
                        <ActionButton
                            label={"Show Advanced"}
                            type={ActionButtonType.Ternary}
                            onClick={async () => {
                                // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
                                await this.doBusyTask(async () => {
                                    this.setState({ showAdvanced: !this.state.showAdvanced });
                                });
                            }}
                        />
                    )}
                    {(!showAdvancedButton || this.state.showAdvanced) && (
                        <React.Fragment>
                            <MarkdownEditor label="Project description" value={this.state.description} onChange={this.handleDescriptionChanged} />
                            <Select value={this.state.projectGroupId} onChange={(projectGroupId) => this.setState({ projectGroupId })} items={this.state.projectGroups.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Project group" />
                            {hasLifecycleViewPermissions && (
                                <>
                                    <Select
                                        value={this.state.selectedLifecycle ? this.state.selectedLifecycle.Id : ""}
                                        onChange={this.handleLifeCycleChange}
                                        items={this.state.lifecycles.map((l) => ({ value: l.Id, text: l.Name }))}
                                        label="Lifecycle"
                                    />
                                    <Note>
                                        Lifecycles determine which environments the project can be deployed to, and the promotion rules between those environments.{" "}
                                        <InternalLink to={routeLinks.library.lifecycles} openInSelf={false}>
                                            Create or modify lifecycles
                                        </InternalLink>
                                    </Note>
                                    {Object.keys(this.state.environmentsById).length > 0 && (
                                        <ActionButton
                                            type={ActionButtonType.Ternary}
                                            label={this.state.showLifecycleMap ? "Hide lifecycle" : "Show lifecycle"}
                                            onClick={async () => {
                                                // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
                                                await this.doBusyTask(async () => {
                                                    this.setState({ showLifecycleMap: !this.state.showLifecycleMap });
                                                });
                                            }}
                                        />
                                    )}
                                    {this.state.showLifecycleMap && (
                                        <div>
                                            {this.state.selectedLifecycle && (
                                                <div className={styles.lifecycleMap}>
                                                    <LifecycleMap environmentsById={this.state.environmentsById} lifecyclePreview={this.state.selectedLifecycle} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            {this.props.cloneId && [
                                <Select
                                    allowClear={true}
                                    allowFilter={true}
                                    value={this.state.selectedCloneId}
                                    onChange={(cloneId) => this.setState({ selectedCloneId: cloneId!.length > 0 ? cloneId! : null! })}
                                    items={this.state.otherProjects.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                                    label="Clone from"
                                />,
                                <Note>Select an existing project to clone. The deployment process and variables set in the other project will be copied into the new project when it is created.</Note>,
                            ]}
                        </React.Fragment>
                    )}
                </PermissionCheck>
            </SaveDialogLayout>
        );
    }

    private handleLifeCycleChange = async (value: any) => {
        // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
        await this.doBusyTask(async () => {
            const lifecycles = this.state.lifecycles!.filter((l) => l.Id === value);
            this.setState({ selectedLifecycle: lifecycles.length > 0 ? lifecycles[0]! : null! });
        });
    };

    private handleDescriptionChanged = async (description: string) => {
        // in dobusy so the dialog resizes if the markdown editor grows
        await this.doBusyTask(async () => {
            this.setState({ description });
        });
    };
}
