const provenanceData = require("build-provenance.json");
// note this is a 'virtual module' - it wont be available in all contexts (eg, `jest` tests)
export default class Provenance {
    static getGitCommitHash(): string {
        return provenanceData.commitHash;
    }

    static getGitCommitBranch(): string {
        return provenanceData.branch;
    }

    static getBuildDate(): string {
        return provenanceData.buildDate;
    }

    static getBuildMode(): string {
        return provenanceData.buildMode;
    }

    static getBuildVersion(): string {
        return provenanceData.version;
    }
}
