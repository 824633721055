/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
import React from "react";
import BaseComponent from "../BaseComponent";
import { OctopusError } from "client/resources";
import RefreshLoop from "utils/RefreshLoop/refresh-loop";
import { PromiseCancelledError } from "../../utils/PromiseCancelledError";
import { Errors, createErrorsFromOctopusError } from "./Errors";
import { DataBaseComponentContext } from "./DataBaseComponentContext";

export interface DataBaseComponentState {
    busy?: Promise<void>;
}

export type DoBusyTask = (action: () => Promise<any>, clearCurrentErrors?: boolean, onError?: (errors: Errors) => void, onSuccess?: () => void) => Promise<boolean>;
export type Refresh = () => Promise<void>;

export class DataBaseComponent<Props, State = {}> extends BaseComponent<Props, State & DataBaseComponentState> {
    private busies: Array<Promise<void>> = [];
    private stopRefreshLoop: (() => void) | undefined;

    get errors(): Errors | undefined {
        return this.getContext().errors;
    }

    static contextType = DataBaseComponentContext;
    context: React.ContextType<typeof DataBaseComponentContext>;

    constructor(props: Props) {
        super(props);

        this.provideErrorHandling(this.doBusyTaskInternal);
    }

    componentWillUnmount() {
        if (this.stopRefreshLoop !== undefined) {
            this.stopRefreshLoop();
        }
    }

    private getContext = () => {
        if (!this.context) {
            throw Error("DataBaseComponent context or the associated contexts it relies on has not been setup. Please add the associated ErrorContext and DataBaseComponentContexts above this component.");
        }
        return this.context;
    };

    public doBusyTask: DoBusyTask = async (action: () => Promise<any>, clearCurrentErrors: boolean = true, onError?: (errors: Errors) => void, onSuccess?: () => void): Promise<boolean> => {
        return this.doBusyTaskInternal(action, clearCurrentErrors, onError, onSuccess);
    };

    protected getFieldError = (fieldName: string) => {
        return this.getContext().getFieldError(fieldName) ?? "";
    };

    protected async startRefreshLoop<K extends keyof State>(getData: () => Promise<Pick<State, K> | null | State>, refreshInterval: number | ((hidden: boolean) => number), noBusyIndicator = false): Promise<Refresh> {
        if (this.stopRefreshLoop !== undefined) {
            throw new Error("Can't create more than one loop in a component");
        }

        const refreshIntervalFunc = typeof refreshInterval === "function" ? refreshInterval : (hidden: boolean) => (hidden ? refreshInterval * 12 : refreshInterval);

        const loop = new RefreshLoop(async (isLoopStillRunning) => {
            const refreshData = async () => {
                const innerData = await getData();
                if (isLoopStillRunning()) {
                    this.setState(innerData);
                }
            };

            if (noBusyIndicator) {
                await refreshData();
            } else {
                await this.doBusyTask(async () => refreshData());
            }
        }, refreshIntervalFunc);

        this.stopRefreshLoop = loop.stop;
        const data = await getData();
        if (this.unmounted) {
            throw new PromiseCancelledError("Component unmounted before loop could start");
        }
        this.setState(data);

        loop.start();

        return loop.refresh;
    }

    protected setValidationErrors = (message: string, fieldErrors: { [other: string]: string } = {}) => {
        this.getContext().actions.setValidationErrors(message, fieldErrors);
    };

    protected clearErrors = () => {
        this.getContext().actions.clearErrors();
    };

    protected mapToOctopusError(err: OctopusError) {
        // we override this in subclasses so don't remove
        return createErrorsFromOctopusError(err);
    }

    protected setStateAsync(state: State) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    private doBusyTaskInternal = async (action: () => Promise<void>, clearCurrentErrors: boolean, onError?: (errors: Errors) => void, onSuccess?: () => void): Promise<boolean> => {
        let busy: Promise<void>;
        try {
            // Sometimes child components will load some lookup data while a parent component
            // is displaying an error. The child uses the parent's doBusyTask so that the busy
            // indicator and errors display correctly. But we shouldn't clear existing errors
            // from that child load.
            if (clearCurrentErrors) {
                this.context!.actions.clearErrors();
            }

            busy = action();
            this.busies = [busy, ...this.busies];
            const singlePromise = Promise.all(this.busies).then((v) => {
                /* */
            }); //the .then gives us Promise<void> instead of Promise<void[]>
            this.setState({ busy: singlePromise });
            await busy;

            // There were no errors in this case.
            if (onSuccess) {
                onSuccess();
            }

            return true;
        } catch (e) {
            if (e instanceof OctopusError) {
                const errors = this.mapToOctopusError(e);
                this.getContext().actions.setErrors(e);
                if (onError) {
                    onError(errors);
                }
                return false;
            }
            if (e instanceof PromiseCancelledError) {
                // swallow it, no point bubbling this up any further since we intentionally cancelled the promise
                return false;
            }
            throw e;
        } finally {
            this.busies = this.busies.filter((b) => b !== busy);
            // we need to return null here when done
            // because some buttons etc just check for the
            // existance of a busy promise
            this.setState({
                busy:
                    this.busies.length > 0
                        ? Promise.all(this.busies).then((v) => {
                              /* */
                          })
                        : null!,
            });
        }
    };
}
