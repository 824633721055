import * as React from "react";
import UserMultiSelect from "components/MultiSelect/UserMultiSelect";
import { UserResource } from "client/resources/userResource";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";

interface AddMemberDialogButtonState extends DataBaseComponentState {
    userIds: string[];
}

interface AddMemberDialogButtonProps {
    users: UserResource[];

    onSelected(users: string[]): void;
}

class AddMember extends DataBaseComponent<AddMemberDialogButtonProps, AddMemberDialogButtonState> {
    constructor(props: AddMemberDialogButtonProps) {
        super(props);
        this.state = {
            userIds: [],
        };
    }

    render() {
        return (
            <OkDialogLayout title={this.state.userIds && this.state.userIds.length > 1 ? "Add Members" : "Add Member"} okButtonLabel="Add" busy={this.state.busy} errors={this.errors} onOkClick={() => this.props.onSelected(this.state.userIds)}>
                <UserMultiSelect items={this.props.users} onChange={(userIds) => this.setState({ userIds })} value={this.state.userIds ? this.state.userIds : []} />
            </OkDialogLayout>
        );
    }
}

export default AddMember;
