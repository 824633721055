/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import DatePickerMaterial from "material-ui/DatePicker";
import moment from "moment";
import FormFieldProps from "../FormFieldProps";
import { UniqueSequenceGenerator } from "../../../utils/UniqueSequenceGenerator/UniqueSequenceGenerator";
import { withTheme } from "components/Theme";

interface DatePickerProps extends FormFieldProps<Date> {
    disabled?: boolean;
    label?: string;
    error?: string;
    name?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    formatDate?(date: Date): string;
}

export default class DatePicker extends React.Component<DatePickerProps, {}> {
    static sequence: UniqueSequenceGenerator = new UniqueSequenceGenerator();

    private genericName: string;

    constructor(props: DatePickerProps) {
        super(props);

        this.genericName = "input" + DatePicker.sequence.next();
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.value !== this.props.value) {
            return true;
        }
    }

    handleChange(_: any, value: any) {
        if (this.props.validate) {
            const validateResult = this.props.validate(value);
            if (this.props.onValidate) {
                this.props.onValidate(validateResult);
            }
        }
        this.props.onChange!(value);
    }

    render() {
        const { value, label, validate, error, onChange, name, onValidate, ...otherProps } = this.props;
        const date = value ? moment(value).toDate() : undefined;
        return withTheme((theme) => (
            <DatePickerMaterial
                value={date}
                onChange={(_, val) => this.handleChange(_, val)}
                errorText={error}
                floatingLabelText={label}
                floatingLabelStyle={{ color: theme.secondaryText }}
                container="inline"
                name={name || this.genericName}
                mode="landscape"
                dialogContainerStyle={{ backgroundColor: theme.paper1 }}
                {...otherProps}
            />
        ));
    }
}
