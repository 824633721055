import { MachinesLayoutProps, MachinesLayoutState, BaseMachinesLayout } from "./BaseMachinesLayout";
import { WorkerPoolsSummaryResource, WorkerPoolResource, Permission, SummaryResource } from "client/resources";
import { repository } from "clientInstance";
import * as React from "react";
import { WorkerPoolMultiSelect, ShellNameMultiSelect } from "components/MultiSelect";
import { machineActions } from "../../reducers/machines";
import { connect } from "react-redux";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton, NavigationButtonType } from "components/Button";
import routeLinks from "routeLinks";
import { cloneDeep, isEqual } from "lodash";
import Section from "components/Section";
import WorkersOnboarding from "./WorkersOnboarding";
import WorkerMachinesSummarySection from "./WorkerMachinesSummarySection";
import { NoResults } from "components/NoResults/NoResults";
import { Dispatch, Action } from "redux";
import FormPage from "components/FormPage/FormPage";
import { RouteComponentProps } from "react-router";
import { createWorkerPoolsSummaryArgs, WorkerPoolsSummaryFilter, defaultWorkerPoolsSummaryFilter, workerPoolsFilterToQuery, workerPoolsQueryToFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { WorkerPoolSummaryMachineQuery } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolSummaryMachineQuery";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import URI from "urijs";
import InfrastructureLayout, { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

const { machineCleared } = machineActions;
interface WorkerMachinesLayoutProps extends MachinesLayoutProps<WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery>, RouteComponentProps {
    initialData: InitialData;
    defaultFilter: WorkerPoolsSummaryFilter;
    filterToQuery: (filter: WorkerPoolsSummaryFilter) => WorkerPoolSummaryMachineQuery;
    onClearMachine(): void;
}

interface WorkerMachinesLayoutState extends MachinesLayoutState<WorkerPoolsSummaryFilter> {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    filter: WorkerPoolsSummaryFilter;
}

interface InitialData {
    workerShellNames: string[];
    workerPools: WorkerPoolResource[];
    hasMachines: boolean;
    workerPoolsSummary: WorkerPoolsSummaryResource;
}

const Title = "Workers";
const WorkerMachinesLayoutFormPage = FormPage<InitialData>();
const WorkerMachinesLayout: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const query = URI(props.location.search).search(true);
    const filter = workerPoolsQueryToFilter(query);

    return (
        <WorkerMachinesLayoutFormPage
            title={Title}
            load={async () => {
                const defaultArgs = createWorkerPoolsSummaryArgs(filter);
                const workerPools = repository.WorkerPools.all();
                const workerShellNames = repository.WorkerShells.all();
                const hasMachines = (await repository.Workers.list({ take: 0 })).TotalResults > 0;
                const workerPoolsSummary = repository.WorkerPools.summary(defaultArgs);
                return {
                    workerPools: await workerPools,
                    workerShellNames: await workerShellNames,
                    workerPoolsSummary: await workerPoolsSummary,
                    hasMachines,
                };
            }}
            renderWhenLoaded={(data) => <WorkerMachinesConnected initialData={data} initialFilter={filter} defaultFilter={defaultWorkerPoolsSummaryFilter} filterToQuery={workerPoolsFilterToQuery} hasMachines={data.hasMachines} {...props} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class WorkerMachinesLayoutInternal extends BaseMachinesLayout<WorkerMachinesLayoutProps, WorkerMachinesLayoutState, WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery> {
    constructor(props: WorkerMachinesLayoutProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            filter: props.initialFilter,
            queryFilter: props.initialFilter,
            workerPoolsSummary: this.props.initialData.workerPoolsSummary,
        };
    }

    async componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }

    protected getNameFilter(searchHintText: string): JSX.Element[] {
        return [
            <FilterSearchBox
                placeholder={searchHintText}
                value={this.state.filter.machinePartialName}
                onChange={(x) => {
                    this.setFilterState({ machinePartialName: x }, this.onFilterChange);
                }}
                autoFocus={true}
            />,
        ];
    }

    protected async loadSummaries(): Promise<void> {
        const args = createWorkerPoolsSummaryArgs(this.state.filter);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.WorkerPools.summary(args), (response) => {
            this.setState({
                workerPoolsSummary: response,
            });
        });
    }

    protected getSummaries(): SummaryResource {
        return this.props.initialData.workerPools && this.state.workerPoolsSummary;
    }

    protected isFiltering() {
        return !isEqual(this.state.filter, this.props.defaultFilter);
    }

    protected extraFilters(): React.ReactNode[] {
        return [
            <WorkerPoolMultiSelect
                key="workerPoolIdsMultiSelect"
                items={this.props.initialData.workerPools}
                value={this.state.filter.workerPoolIds}
                onChange={(x) => {
                    this.setFilterState({ workerPoolIds: x }, this.onFilterChange);
                }}
            />,
            <ShellNameMultiSelect
                key="filterShellName"
                items={this.props.initialData.workerShellNames ? this.props.initialData.workerShellNames : []}
                value={this.state.filter.shellNames}
                onChange={(x) => {
                    this.setFilterState({ shellNames: x }, this.onFilterChange);
                }}
            />,
        ];
    }

    protected getActions(): JSX.Element[] {
        return [
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.workerMachines.new()} label="Add worker" type={NavigationButtonType.Primary} />
            </PermissionCheck>,
        ];
    }

    protected renderOnboarding(): JSX.Element {
        return <WorkersOnboarding />;
    }

    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const workerPoolsSummaries = this.props.initialData.workerPools && this.state.workerPoolsSummary;
        if (workerPoolsSummaries) {
            machinesExpander = <WorkerMachinesSummarySection key="allMachines" workerPoolsSummary={workerPoolsSummaries} filter={this.state.filter} workerPools={this.props.initialData.workerPools} />;
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            machinesExpander = (
                <Section>
                    <NoResults />
                </Section>
            );
        }
        return machinesExpander;
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        title: Title,
        itemDescriptions: "workers",
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineCleared());
        },
    };
};

const WorkerMachinesConnected = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(WorkerMachinesLayoutInternal);

export default WorkerMachinesLayout;
