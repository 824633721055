/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { noOp } from "utils/noOp";

export interface ActiveItemState<T> {
    activeItem?: T;
}

export interface ActiveItemRenderProps<T> {
    activeItem?: T;
    onSelect: (activeItem: T) => void;
}

export interface ActiveItemProps<T> {
    activeItem?: T;
    initial?: T;
    render: (renderProps: ActiveItemRenderProps<T>) => React.ReactNode;
    onActiveItemChanged?: (activeItem: T) => void;
    onSetActiveItem?: (item: T) => void;
}

export class ActiveItem<T> extends React.Component<ActiveItemProps<T>, ActiveItemState<T>> {
    constructor(props: ActiveItemProps<T>) {
        super(props);
        this.state = {
            activeItem: props.initial,
        };
    }

    setActiveItem(activeItem: T) {
        if (!this.props.activeItem) {
            //Uncontrolled
            this.setState(
                (prev: ActiveItemState<T>) => ({ ...prev, activeItem }),
                () => (this.props.onActiveItemChanged ? this.props.onActiveItemChanged(this.state.activeItem!) : noOp())
            );
        } else if (this.props.onSetActiveItem) {
            //Controlled
            this.props.onSetActiveItem(activeItem);
        }
    }

    render() {
        return this.props.render({
            activeItem: this.props.activeItem ? this.props.activeItem : this.state.activeItem,
            onSelect: (item) => this.setActiveItem(item),
        });
    }
}

export default ActiveItem;
