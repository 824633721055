import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

interface DataTableFooterRowProps {
    className?: string;
}

export class DataTableFooterRow extends React.Component<DataTableFooterRowProps> {
    render() {
        return <tr className={cn(this.props.className, styles.dataTableFooterRow)}>{this.props.children}</tr>;
    }
}
