/* eslint-disable @typescript-eslint/no-explicit-any */

import BasicRepository from "./basicRepository";
import Client from "../client";
import { VariableSetResource } from "../resources/variableSetResource";

class VariableRepository extends BasicRepository<VariableSetResource, VariableSetResource> {
    constructor(client: Client) {
        super("Variables", client);
    }

    // FIXME: cac-runbooks, need to be able to load variables for VCS runbooks too
    names(projectId: string, runbookId: string | undefined, projectEnvironmentsFilter?: any): Promise<string[]> {
        return this.client.get(this.client.getLink("VariableNames"), {
            project: projectId,
            runbook: runbookId,
            projectEnvironmentsFilter: projectEnvironmentsFilter ? projectEnvironmentsFilter.join(",") : projectEnvironmentsFilter,
        });
    }

    // FIXME: cac-runbooks, need to be able to load variables for VCS runbooks too
    preview(projectId: string, runbookId: string | undefined, actionId: string, environmentId: string, role: string, machineId: string, channelId: string, tenantId: string): Promise<VariableSetResource> {
        return this.client.get(this.client.getLink("VariablePreview"), {
            project: projectId,
            runbook: runbookId,
            environment: environmentId,
            channel: channelId,
            tenant: tenantId,
            action: actionId,
            role,
            machine: machineId,
        });
    }
}

export default VariableRepository;
