/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { groupBy } from "lodash";
import ActionTemplateCategory from "components/ActionTemplates/ActionTemplateCategory";
import ActionTemplateCard from "components/ActionTemplates/ActionTemplateCard";
import { ActionButton, ActionButtonType } from "components/Button/ActionButton";
import CommunityTemplateInstallation from "./CommunityTemplateInstallation";
import { ActionTemplateResource, ActionHandlerCategory } from "client/resources/actionTemplateResource";
import matchesFilter from "components/ActionTemplates/matchesFilter";
import { Callout, CalloutType } from "components/Callout/Callout";
import FeatureToggle, { Feature } from "components/FeatureToggle/FeatureToggle";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
const styles = require("./styles.less");
import ActionTemplateCardList from "./ActionTemplateCardList";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import Section from "components/Section";
import { Note } from "components/form";

require("animate.css");

interface CommunityActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    filter?: string;
    isFilterFromCategory?: boolean;
    onPostSelectionUrlRequested?: (template: { Type: string; Id: string }) => string;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => string;
    installationActionName: string;
}

interface CommunityActionTemplateListState {
    categories?: Array<{ name: string; templates: ActionTemplateSearchResource[] }>;
    categoryTemplates?: ActionTemplateSearchResource[];
    redirectTo?: string;
}

class CommunityActionTemplateList extends React.Component<CommunityActionTemplateListProps, CommunityActionTemplateListState> {
    private categoriesElement: HTMLOListElement | null = undefined!;
    private categoryTemplatesElement: HTMLElement | null = undefined!;

    constructor(props: CommunityActionTemplateListProps) {
        super(props);

        this.state = {
            categories: this.categoriseTemplates(props.templates),
            categoryTemplates: [],
        };
    }

    categoriseTemplates(templates: ActionTemplateSearchResource[]) {
        const categorised = groupBy(templates, (t) => t.Categories && t.Categories.length > 0 && t.Categories[0]); // Community steps have a single category (other than the "Community" parent category).
        return Object.keys(categorised)
            .sort((category1, category2) => category1.toLowerCase().localeCompare(category2.toLowerCase()))
            .map((categoryName) => ({ name: categoryName, templates: categorised[categoryName] }));
    }

    showCategoryTemplates(templates: any) {
        // We are setting min-hight here to avoid "screen jumping" when the user switches from categories to templates.
        // Ideally this would be handled by a CSS transform but it did not work.
        this.categoryTemplatesElement!.style.minHeight = this.categoriesElement!.clientHeight + "px";
        this.setState({ categoryTemplates: templates, categories: [] });
    }

    showCategories = () => {
        this.setState({ categoryTemplates: [], categories: this.categoriseTemplates(this.props.templates) });
    };

    showAllTemplates = () => {
        this.setState({ categoryTemplates: this.props.templates, categories: [] });
    };

    isInCategoryMode() {
        return this.state.categoryTemplates!.length === 0 && this.state.categories!.length > 0;
    }

    isInCategoryTemplatesMode() {
        return this.state.categoryTemplates!.length > 0 && this.state.categories!.length === 0;
    }

    componentWillReceiveProps(nextProps: CommunityActionTemplateListProps) {
        const nextState: Partial<CommunityActionTemplateListState> = { categories: this.categoriseTemplates(nextProps.templates) };
        if (!nextProps.filter) {
            nextState.categoryTemplates = [];
        }
        this.setState(nextState);
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }
        const isFiltering = !!this.props.filter;
        const filteredResults = this.props.templates && this.props.templates.filter((at) => matchesFilter(at, this.props.filter!));
        const numberOfTemplatesShown = isFiltering ? filteredResults.length : this.props.templates && this.props.templates.length;

        return (
            <div className={styles.container}>
                <Section sectionHeader={`Community Contributed Step Templates (${numberOfTemplatesShown})`} className={styles.sectionDivider}>
                    <FeatureToggle feature={Feature.CommunityActionTemplates} enabled={false}>
                        <Callout type={CalloutType.Information} title={"Community library is disabled"}>
                            If you want to take advantage of hundreds of great templates developed by the members of our community then please <InternalLink to={routeLinks.configuration.features}>enable the integration feature.</InternalLink>
                        </Callout>
                    </FeatureToggle>
                    <FeatureToggle feature={Feature.CommunityActionTemplates}>
                        {this.props.templates.length === 0 && !this.props.filter && (
                            <Callout type={CalloutType.Information} title={"No community step templates available"}>
                                Community library integration is enabled but there aren't any community step templates available. Review the community step templates <InternalLink to={routeLinks.configuration.features}>feature toggle</InternalLink>
                                &nbsp; for more information.
                            </Callout>
                        )}
                    </FeatureToggle>
                </Section>
                {!isFiltering ? (
                    <Section>
                        <div className={styles.actions}>
                            {this.isInCategoryMode() && <ActionButton label="Show all" type={ActionButtonType.Secondary} onClick={this.showAllTemplates} />}
                            {this.isInCategoryTemplatesMode() && <ActionButton label="Back" type={ActionButtonType.Secondary} onClick={this.showCategories} />}
                        </div>
                        <div className={styles.groupingContainer}>
                            {this.state.categories && this.state.categories.length > 0 && (
                                <ol className={styles.categories} ref={(element) => (this.categoriesElement = element)}>
                                    <div>
                                        {this.state.categories.map((category) => {
                                            return this.withAnimation(
                                                category.name,
                                                <ActionTemplateCategory
                                                    category={ActionHandlerCategory.CommunitySubCategory}
                                                    name={category.name}
                                                    active={false}
                                                    templates={category.templates}
                                                    onCategorySelected={() => this.showCategoryTemplates(category.templates)}
                                                />
                                            );
                                        })}
                                    </div>
                                </ol>
                            )}
                            <ol className={styles.categoryTemplates} ref={(element) => (this.categoryTemplatesElement = element)}>
                                <div>{this.state.categoryTemplates!.map((template) => this.renderSingle(template))}</div>
                            </ol>
                        </div>
                    </Section>
                ) : (
                    this.filteredTemplates(filteredResults)
                )}
            </div>
        );
    }

    private filteredTemplates(filteredResults: ActionTemplateSearchResource[]) {
        if ((!filteredResults || filteredResults.length === 0) && this.props.filter) {
            return (
                <Section>
                    <Note>There are no community step templates that match your filter criteria.</Note>
                </Section>
            );
        }

        return (
            <TransitionAnimation key="filteredSteps">
                {this.props.isFilterFromCategory && (
                    <Section>
                        <Note>The following steps were found that matched the category filter '{this.props.filter}'</Note>
                    </Section>
                )}
                <ActionTemplateCardList>{filteredResults.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
            </TransitionAnimation>
        );
    }

    private renderSingle(template: ActionTemplateSearchResource) {
        return this.withAnimation(
            template.Type + template.Id,
            <ActionTemplateCard
                template={template}
                primaryAction={this.installationDialog(template)}
                secondaryAction={
                    <InternalLink size={0.75} to={this.props.onDetailsUrlRequested!(template)}>
                        View details
                    </InternalLink>
                }
            />
        );
    }

    private withAnimation(key: string, content: React.ReactNode) {
        return (
            <div key={key} className="animated zoomIn">
                {content}
            </div>
        );
    }

    private installationDialog(template: ActionTemplateSearchResource) {
        return (
            <OpenDialogButton label={this.props.installationActionName} type={ActionButtonType.Primary}>
                <CommunityTemplateInstallation communityTemplateId={template.Id} title={this.props.installationActionName} detailsLink={this.props.onDetailsUrlRequested!(template)} saveDone={this.installationSucceeded} />
            </OpenDialogButton>
        );
    }

    private installationSucceeded = (actionTemplate: ActionTemplateResource) => {
        const url = this.props.onPostSelectionUrlRequested!({ Type: actionTemplate.ActionType, Id: actionTemplate.Id });
        this.setState({ redirectTo: url });
    };
}

export default CommunityActionTemplateList;
