/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import { RouteComponentProps } from "react-router";
import TaskLayout from "areas/tasks/TaskLayout";

type TasksLayoutProps = RouteComponentProps<any>;

const TasksLayout: React.FC<TasksLayoutProps> = (props) => {
    return (
        <TaskLayout>
            <Tasks {...props} />
        </TaskLayout>
    );
};

export default TasksLayout;
