/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { OverflowMenuButton } from "components/IconMenu";
import MaterialMenuItem from "material-ui/MenuItem";
import Divider from "components/Divider/Divider";
import OpenDeleteDialogMenuItem from "../Menu/OpenDeleteDialogMenuItem";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { flatten, compact } from "lodash";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import OpenDialogMenuItem from "components/Menu/OpenDialogMenuItem";
const styles = require("./style.less");
import OverflowMenuLink from "./OverflowMenuLink";
import { DoBusyTask } from "../DataBaseComponent/DataBaseComponent";
import OpenConfirmUpgradeDialogMenuItem from "./OpenConfirmUpgradeDialogMenuItem";
import { noOp } from "utils/noOp";
import { actions, selectors } from "./store";
import { useSequenceId } from "components/Dialog/withDialogIdentifier";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ToolTip, { ToolTipPosition } from "components/ToolTip";

enum OverflowMenuItemKind {
    Delete,
    Dialog,
    Generic,
    Disabled,
    Navigation,
    Download,
    Remove,
    ConfirmUpgrade,
}

interface ItemTooltip {
    text: string;
    position?: ToolTipPosition;
}
interface Item {
    kind: OverflowMenuItemKind;
    tooltip?: ItemTooltip;
}

export interface OverflowMenuDialogItem extends Item {
    text: string;
    child: JSX.Element;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDeleteItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean> | void;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    deleteButtonDisabled: boolean;
}

export interface OverflowMenuRemoveItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean> | void;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    removeButtonDisabled: boolean;
}

export interface OverflowMenuConfirmUpgradeItem extends Item {
    text: string;
    title: string;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    confirmButtonDisabled: boolean;
    onClick: () => Promise<boolean> | void;
}

export interface OverflowMenuNavLink extends Item {
    text: string;
    path: string;
    queryString?: string;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDownloadItem extends Item {
    text: string;
    link: string;
    filename: string;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDisabledItem extends Item {
    text: string;
    reason: string;
}

export interface OverflowMenuGenericItem extends Item {
    text: string;
    permission?: PermissionCheckProps;
    onClick: () => void;
}

function isGroup(item: MenuItem | MenuItem[] | null | undefined): item is MenuItem[] {
    return Array.isArray(item as MenuItem[]);
}

function isOverflowMenuDialogItem(item: MenuItem): item is OverflowMenuDialogItem {
    return (item as OverflowMenuDialogItem).kind === OverflowMenuItemKind.Dialog;
}

function isOverflowMenuDeleteItem(item: MenuItem): item is OverflowMenuDeleteItem {
    return (item as OverflowMenuDeleteItem).kind === OverflowMenuItemKind.Delete;
}

function isOverflowMenuRemoveItem(item: MenuItem): item is OverflowMenuRemoveItem {
    return (item as OverflowMenuRemoveItem).kind === OverflowMenuItemKind.Remove;
}

function isOverflowMenuConfirmUpgradeItem(item: MenuItem): item is OverflowMenuConfirmUpgradeItem {
    return (item as OverflowMenuConfirmUpgradeItem).kind === OverflowMenuItemKind.ConfirmUpgrade;
}

function isOverflowMenuDownloadItem(item: MenuItem): item is OverflowMenuDownloadItem {
    return (item as OverflowMenuDownloadItem).kind === OverflowMenuItemKind.Download;
}

function isOverflowMenuNavLink(item: MenuItem): item is OverflowMenuNavLink {
    return (item as OverflowMenuNavLink).kind === OverflowMenuItemKind.Navigation;
}

function isOverflowMenuDisabledItem(item: MenuItem): item is OverflowMenuDisabledItem {
    return (item as OverflowMenuDisabledItem).kind === OverflowMenuItemKind.Disabled;
}

function isOverflowMenuGenericItem(item: MenuItem): item is OverflowMenuGenericItem {
    return (item as OverflowMenuGenericItem).kind === OverflowMenuItemKind.Generic;
}

export type MenuItem = OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuNavLink | OverflowMenuDownloadItem | OverflowMenuGenericItem | OverflowMenuDisabledItem | OverflowMenuRemoveItem | OverflowMenuConfirmUpgradeItem;

interface ConversionResult {
    menuItem: React.ReactNode;
    dialog?: React.ReactNode;
}

interface OverflowMenuProps {
    menuItems: Array<MenuItem | MenuItem[] | undefined | null>;
    tabIndex?: number;
    menuKey?: string;
    colorOverride?: string;
    className?: string;
}

interface GlobalConnectedProps {
    open: boolean;
}

interface GlobalDispatchProps {
    onOpen: () => void;
    onClose: () => void;
}

const ClickTrap: React.FC = ({ children }) => {
    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {children}
        </div>
    );
};

export class OverflowMenuItems {
    static defaultConfirmUpgradeText = "Please note: This is a blocking task and will prevent deployments during the upgrade.";

    static dialogItem(text: string, child: JSX.Element, permission?: PermissionCheckProps): OverflowMenuDialogItem {
        return { text, child, kind: OverflowMenuItemKind.Dialog, permission };
    }

    static removeItem(text: string, title: string, onClick: () => Promise<boolean> | void, content: React.ReactNode, permission?: PermissionCheckProps, removeButtonDisabled: boolean = false): OverflowMenuRemoveItem {
        return { text, title, onClick, content, permission, kind: OverflowMenuItemKind.Remove, removeButtonDisabled };
    }

    static deleteItem(
        text: string,
        title: string,
        onClick: () => Promise<boolean> | void,
        content: ((doBusyTask: DoBusyTask) => React.ReactNode) | React.ReactNode,
        permission?: PermissionCheckProps,
        deleteButtonDisabled: boolean = false
    ): OverflowMenuDeleteItem {
        return { text, title, onClick, content, permission, kind: OverflowMenuItemKind.Delete, deleteButtonDisabled };
    }

    static deleteItemDefault(name: string, onClick: () => Promise<boolean> | void, permission?: PermissionCheckProps, customMessage?: string, customContent?: JSX.Element, deleteButtonDisabled: boolean = false): OverflowMenuDeleteItem {
        return {
            text: "Delete",
            title: `Are you sure you want to delete this ${name}?`,
            onClick,
            permission,
            kind: OverflowMenuItemKind.Delete,
            deleteButtonDisabled,
            content: (
                <div>
                    {customMessage && <p>{customMessage}</p>}
                    {customContent}
                    <p>Do you wish to continue?</p>
                </div>
            ),
        };
    }

    static confirmUpgrade(title: string, onClick: () => Promise<boolean> | void, permission: PermissionCheckProps, customMessage: React.ReactNode = OverflowMenuItems.defaultConfirmUpgradeText): OverflowMenuConfirmUpgradeItem {
        return {
            text: title,
            title,
            onClick,
            permission,
            kind: OverflowMenuItemKind.ConfirmUpgrade,
            confirmButtonDisabled: false,
            content: (
                <div>
                    {customMessage && <p>{customMessage}</p>}
                    <p>Are you sure?</p>
                </div>
            ),
        };
    }

    static navItem(text: string, path: string | undefined, queryString?: string, permission?: PermissionCheckProps): OverflowMenuNavLink {
        return { text, path: path!, queryString, permission, kind: OverflowMenuItemKind.Navigation };
    }

    static disabledItem(text: string, reason: string): OverflowMenuDisabledItem {
        return { text, reason, kind: OverflowMenuItemKind.Disabled };
    }

    static item(text: string, onClick: () => void, permission?: PermissionCheckProps, tooltip?: ItemTooltip): OverflowMenuGenericItem {
        return { text, onClick, permission, kind: OverflowMenuItemKind.Generic, tooltip };
    }

    static downloadItem(text: string, filename: string, link: string) {
        return { text, link, filename, kind: OverflowMenuItemKind.Download };
    }
}
class OverflowMenu extends BaseComponent<OverflowMenuProps & GlobalConnectedProps & GlobalDispatchProps, {}> {
    onIconButtonClick = (event: React.MouseEvent<Element>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onOpen();
    };

    onClickAway = (event: React.MouseEvent<Document>) => {
        this.props.onClose();
    };

    render() {
        // Permissions may cause null entries in our menuItems, so we do null checking before map.

        return (
            <OverflowMenuItemsRenderer menuItems={this.props.menuItems} menuKey={this.props.menuKey} onClose={this.props.onClose}>
                {({ menuItems, dialogs }) => (
                    <ClickAwayListener onClickAway={this.onClickAway}>
                        <div onMouseDown={this.onMouseDownOverride}>
                            <ClickTrap>{dialogs}</ClickTrap>
                            {menuItems && menuItems.length > 0 && (
                                <OverflowMenuButton open={this.props.open} onClick={this.onIconButtonClick} onClose={this.props.onClose}>
                                    {menuItems}
                                </OverflowMenuButton>
                            )}
                        </div>
                    </ClickAwayListener>
                )}
            </OverflowMenuItemsRenderer>
        );
    }

    private onMouseDownOverride(e: any) {
        e.stopPropagation(); //prevent this click bubbling up and making any of the parent show a touch ripple (eg when overflow is used on a List)
    }
}

export interface OverflowMenuItemsRendererProps {
    menuItems: Array<MenuItem | MenuItem[] | undefined | null>;
    menuKey?: string;
    onClose: () => void;
    children: (renderProps: { menuItems: React.ReactNode[]; dialogs: React.ReactNode }) => React.ReactElement | null;
}

export const OverflowMenuItemsRenderer: React.FC<OverflowMenuItemsRendererProps> = (props) => {
    const renderProps = OverflowConversions.convertAll(props.menuItems, props.onClose, props.menuKey);
    return props.children(renderProps);
};

class OverflowConversions {
    static convert(item: MenuItem | null | undefined, onClose: () => void, menuKey?: string): ConversionResult | null {
        if (!item) {
            return null;
        }

        if (isOverflowMenuDialogItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertDialogMenuItem(item, menuKey);
        }

        if (isOverflowMenuDeleteItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertDeleteMenuItem(item, menuKey);
        }

        if (isOverflowMenuRemoveItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertRemoveMenuItem(item, menuKey);
        }

        if (isOverflowMenuConfirmUpgradeItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertConfirmUpgradeMenuItem(item, menuKey);
        }

        if (isOverflowMenuDownloadItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return {
                menuItem: <MaterialMenuItem primaryText={item.text} key={`${menuKey}-${item.text}`} containerElement={<OverflowMenuLink resolve={false} downloadFileName={item.filename} to={item.link} />} />,
            };
        }

        if (isOverflowMenuNavLink(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return {
                menuItem: <MaterialMenuItem onClick={(e) => e.stopPropagation()} primaryText={item.text} key={`${menuKey}-${item.text}`} containerElement={<OverflowMenuLink to={{ pathname: item.path, search: item.queryString }} />} />,
            };
        }

        if (isOverflowMenuDisabledItem(item)) {
            return {
                menuItem: (
                    <MaterialMenuItem key={`${menuKey}-${item.text}`} disabled={true}>
                        <a href="#" onClick={(e) => e.preventDefault()} className={styles.disabledItem} title={item.reason}>
                            {item.text}
                        </a>
                    </MaterialMenuItem>
                ),
            };
        }

        if (isOverflowMenuGenericItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
        }

        const menuItem = (
            <MaterialMenuItem
                key={`${menuKey}-${item.text}`}
                primaryText={item.text}
                onClick={(e) => {
                    //Generic menu items could possibly keep focus so we explicitly close these.
                    onClose();
                    if (item.onClick) {
                        //If explicitlyhandling click, we really should prevent default behaviors such as links triggering
                        e.preventDefault();
                        item.onClick();
                    }
                }}
            />
        );

        return {
            menuItem: item.tooltip ? (
                <ToolTip key={`${menuKey}-${item.text}`} content={item.tooltip.text} position={item.tooltip.position}>
                    {menuItem}
                </ToolTip>
            ) : (
                menuItem
            ),
        };
    }

    static convertAll(sourceMenuItems: Array<MenuItem | MenuItem[] | undefined | null>, onClose: () => void, menuKey: string | undefined) {
        if (!sourceMenuItems) {
            return {
                dialogs: [],
                menuItems: [],
            };
        }

        const result =
            sourceMenuItems &&
            sourceMenuItems
                .filter((item) => !!item)
                .map((item, index) => {
                    if (isGroup(item)) {
                        const results = item.filter((subItem) => !!subItem).map((groupItem) => OverflowConversions.convert(groupItem, onClose, menuKey));
                        if (results.length === 0) {
                            return null;
                        }
                        // This should be smart enough to know if it needs to add a divider at the start or end of a grouping/array.
                        if (index > 0 && !isGroup(sourceMenuItems[index - 1])) {
                            // Show the divider at the start of this grouping.
                            // I.e. The last thing wasn't a group, so we're good to create one here to indicate the start of a grouping.
                            results.splice(0, 0, { menuItem: <Divider key={index} /> });
                        } else if (index < sourceMenuItems.length - 1) {
                            // Show the divider at the end of this grouping.
                            results.push({ menuItem: <Divider key={index} /> });
                        }
                        return results;
                    }
                    return [OverflowConversions.convert(item, onClose, menuKey)];
                });
        const dialogs: React.ReactNode[] = [];
        const menuItems = compact(flatten(result)).map((r) => {
            if (r.dialog) {
                dialogs.push(r.dialog);
            }
            return r.menuItem;
        });

        return { dialogs, menuItems };
    }

    static convertDeleteMenuItem(item: OverflowMenuDeleteItem, menuKey: string | undefined) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenDeleteDialogMenuItem
                acceptOnClick={(click) => (openDialog = click)}
                label={item.text}
                disabled={false}
                deleteButtonDisabled={item.deleteButtonDisabled}
                dialogTitle={item.title}
                key={`${menuKey}-${item.text}`}
                onDeleteClick={item.onClick}
                renderContent={(doBusyTask: DoBusyTask) => {
                    if (typeof item.content === "function") {
                        return item.content(doBusyTask);
                    }
                    return item.content;
                }}
            />
        );

        return {
            menuItem: (
                <ClickTrap key={`${menuKey}-${item.text}`}>
                    <MaterialMenuItem
                        primaryText={item.text}
                        onClick={() => {
                            openDialog ? openDialog() : noOp();
                        }}
                    />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    static convertRemoveMenuItem(item: OverflowMenuRemoveItem, menuKey: string | undefined) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenDeleteDialogMenuItem
                acceptOnClick={(click) => (openDialog = click)}
                label={item.text}
                disabled={false}
                deleteButtonDisabled={item.removeButtonDisabled}
                dialogTitle={item.title}
                deleteButtonLabel="Remove"
                deleteButtonBusyLabel="Removing"
                key={`${menuKey}-${item.text}`}
                onDeleteClick={item.onClick}
                renderContent={() => item.content}
            />
        );

        return {
            menuItem: (
                <ClickTrap key={`${menuKey}-${item.text}`}>
                    <MaterialMenuItem primaryText={item.text} onClick={() => (openDialog ? openDialog() : noOp())} />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    static convertConfirmUpgradeMenuItem(item: OverflowMenuConfirmUpgradeItem, menuKey: string | undefined) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenConfirmUpgradeDialogMenuItem
                acceptOnClick={(click) => (openDialog = click)}
                label={item.text}
                disabled={false}
                dialogTitle={item.title}
                confirmButtonDisabled={item.confirmButtonDisabled}
                confirmButtonLabel="Continue"
                confirmButtonBusyLabel="Continuing"
                key={`${menuKey}-${item.text}`}
                onConfirmUpgradeClick={() => item.onClick()}
                renderContent={() => item.content}
            />
        );

        return {
            menuItem: (
                <ClickTrap key={`${menuKey}-${item.text}`}>
                    <MaterialMenuItem primaryText={item.text} onClick={() => (openDialog ? openDialog() : noOp())} />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    static convertDialogMenuItem(item: OverflowMenuDialogItem, menuKey: string | undefined) {
        let openDialog: () => void;
        //TODO: Find an alternative to this callback to get the click handler which doesn't result in overflow menus remaining open etc.
        const dialogMenuItem = (
            <OpenDialogMenuItem
                acceptOnClick={(click) => {
                    openDialog = click;
                }}
                label={item.text}
                key={`${menuKey}-${item.text}`}
            >
                {item.child}
            </OpenDialogMenuItem>
        );

        return {
            menuItem: (
                <ClickTrap key={`${menuKey}-${item.text}`}>
                    <MaterialMenuItem primaryText={item.text} onClick={() => (openDialog ? openDialog() : noOp())} />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    static alternate(alternate: string) {
        return alternate ? { menuItem: <MaterialMenuItem key={alternate} primaryText={alternate} /> } : null;
    }
}

const mapGlobalStateToProps = (state: GlobalState, { sequence }: OverflowMenuProps & { sequence: string }) => {
    return {
        open: selectors.createMenuOpenSelector(sequence)(state),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>, { sequence }: OverflowMenuProps & { sequence: string }) => {
    return {
        onOpen: () => dispatch(actions.openOnly(sequence)),
        onClose: () => dispatch(actions.close(sequence)),
    };
};

const ConnectedOverflow = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(OverflowMenu);

const SequencedOverflow: React.FC<OverflowMenuProps & { sequence?: string }> = ({ sequence, ...rest }) => {
    const result = useSequenceId(sequence);
    return <ConnectedOverflow sequence={result} {...rest} />;
};

export default SequencedOverflow;
