/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { TaskStatusIcon } from "../../TaskStatusIcon/TaskStatusIcon";
import DateFormatter from "utils/DateFormatter";
const styles = require("./style.less");
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import { RunbooksDashboardItemResource, ProjectResource } from "client/resources";

interface RunbookTaskStatusDetailsProps {
    project: ProjectResource;
    item: RunbooksDashboardItemResource;
    style?: React.CSSProperties;
}

const RunbookTaskStatusDetails: React.StatelessComponent<RunbookTaskStatusDetailsProps> = (props) => {
    const task = props.item;
    const link = routeLinks
        .project(props.project ? props.project.Slug : task.ProjectId)
        .operations.runbook(task.RunbookId)
        .runbookSnapshot(task.RunbookSnapshotId)
        .runbookRuns.specific(task.Id);
    return (
        <InternalLink to={link} className={styles.taskLink} width="100%">
            <div className={styles.container} style={props.style}>
                <TaskStatusIcon item={task} smallIcon={false} />
                <div className={styles.details}>
                    <span title={DateFormatter.dateToLongFormat(task.StartTime || task.QueueTime)!} className={styles.date}>
                        {DateFormatter.dateToShortFormat(task.StartTime || task.QueueTime)}
                    </span>
                    <span className={styles.snapshotName}>{task.RunbookSnapshotName}</span>
                </div>
            </div>
        </InternalLink>
    );
};

RunbookTaskStatusDetails.displayName = "RunbookTaskStatusDetails";
export default RunbookTaskStatusDetails;
